import React from "react";
import drawermenuIcons from "./drawermenuicon/index";
import headericons from "./header-icon/index";
import generalicon from "./generalicon/index";
import HomeIcons from "./home-icon/index";
const getPath = (iconname, props, isOrg = false) => {
  const list = [drawermenuIcons, headericons, generalicon, HomeIcons];
  const icons = {};
  for (let i in list) {
    for (let iconname in list[i]) {
      if (!icons.hasOwnProperty(iconname)) {
        icons[iconname] = list[i][iconname];
      } else {
        throw new Error(iconname + " icon name is exist.");
      }
    }
  }
  if (!icons[iconname]) {
    return null;
  }
  const ComponentIcon = icons[iconname];

  if (isOrg) {
    return <ComponentIcon />;
  }
  return <ComponentIcon {...props} />;
}
const SVGIcon = ({
  iconname = "",
  isOrg = false,
  ...props
}) => (
  <>
    {getPath(iconname, { ...props })}
  </>
);

export default SVGIcon;