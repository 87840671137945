import React from "react";
// import comicbook from "./../../../assets/media/comicbook/comicbook.jpg";
import elonmusk from "./../../../assets/media/story/elon.png";
import tezlarobot from "./../../../assets/media/story/tezlarobot.png";
import comicbookbg from "../../../assets/media/story/comicbookbg.png";
import starship from "./../../../assets/media/story/starship.png";
import jeff from "./../../../assets/media/story/jef.png";
import legendry from "./../../../assets/media/story/legendry.png";
import videobg from "./../../../assets/media/story/videobg.png";
import playicon from "./../../../assets/media/icon/playicon.png";
import video2 from "./../../../assets/media/story/video2.png";
import triangle from "./../../../assets/media/story/triangle.png";
export default function Comicbook() {
  return (
    <div className="sp-comicbook">
      <div className="row">
        <div className="col-xl-9">
          <div className="sp-comicbook-content">
            <div className="sp-comicbook-content-bg">
              <img src={comicbookbg} alt="comic" className="comicImg"></img>
              <div className="sp-image-text">
                <div className="sp-story">
                  <div className="sp-story-title">Comic Book</div>
                  <p className="sp-story-title-story">The StarPunks saga where Cpt Musk the owner of Tezla Robots AI who wants to create a new type of world where his robots control Terra gets stopped by Professor Bezos and his students, the head of the Science laboratory, and former professor of Musk.</p>
                  <p className="sp-story-title-story">This comic book gets released after the NFT collection sells out, and can be claimed as an NFT for a given token price in punk$ or download for free.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex align-items-center">
                <div className="sp-comicbook-content-storyImg">
                  <img src={tezlarobot} alt="tezlarobot"></img>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex align-items-center">
                <div className="sp-storyImg-Content">
                  <div className="sp-storyImg-Content-title">TEZLA ROBOT</div>
                  <p className="sp-storyImg-Content-discription">When Jeff Besos decided to lead the rebelion against the powerful Elon and Tezla robots, he had no idea now unprepared he had been, and how foolish.</p>
                </div>
              </div>
            </div>
            <div className="row rowreverse">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex align-items-center">
                <div className="sp-storyImg-Content">
                  <div className="sp-storyImg-Content-title">ELON MUSK</div>
                  <p className="sp-storyImg-Content-discription">Entering the last university of electric engines campus, as a professor ready to take his last luggage and walk home in defeat angry and upset, his lead student came up to him shivering though the sun was up, shivering of excitement, and disapointment he put his hand on Jeffs forearm as he was head down going to his Blue horizon jet, and said, Please stop, please stop and listen to me,
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex align-items-center">

                <div className="sp-comicbook-content-storyImg">
                  <img src={elonmusk} alt="elonmusk"></img>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex align-items-center">
                <div className="sp-comicbook-content-storyImg sp-p30">
                  <img src={starship} alt="tezlarobot"></img>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex align-items-center">
                <div className="sp-storyImg-Content">
                  <div className="sp-storyImg-Content-title">STAR SHIP</div>
                  <p className="sp-storyImg-Content-discription">As they were talking, a loud blast happend and
                    the sky darkened for a second with a dark light
                    explosion, there was Elon on his battleship
                    having an excapade with a blonde cyber
                    assistant, and laughing, saying this world had to
                    be mine anyway in order to save it you have to
                    control it first, just like I did with dweeter.</p>
                </div>
              </div>
            </div>
            <div className="row rowreverse">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex align-items-center">
                <div className="sp-storyImg-Content">
                  <span>professor</span>
                  <div className="sp-storyImg-Content-title">JEFF BEZOS</div>
                  <p className="sp-storyImg-Content-discription">Jeff replied, I wasnt leaving Darius, I am just going to my carjet to get my projector, gather Jane, Mr Hopkins and Gregory, and meet me in the Projector room, download the nuclear battery demo, and take my briefcase, meet you there, we will speak about robotic muscles I have a plan!, said Jeff, having nothing but a vengence idea, yet nothing polished  or good enough to fight against Elon and Tesla robotics, but he was going to find a way, that was his hope.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex align-items-center">
                <div className="sp-comicbook-content-storyImg">
                  <img src={jeff} alt="elonmusk"></img>
                </div>
              </div>
            </div>
            <div className="sp-legendry">
              <div className="sp-legendry-img">
                <img src={legendry} alt="legendry"></img>
              </div>
              <div className="sp-storyImg-Content">
                <span>Be legendary</span>
                <div className="sp-storyImg-Content-title">DO COOL SHIT HAVE FUN</div>
                <p className="sp-storyImg-Content-discription">Then he opens the door to a class room, many students are there, and he asks , Elon is late again right? and the class replies yes he is, he must be working hard at his project.
                </p>
                <p className="sp-storyImg-Content-discription">
                  Then transition to a spinning electric wheel, zoom in and zoom out to Elon's room, where a maid tries to wake him up.</p>
                <p className="sp-storyImg-Content-discription"> Hey Elon wake up you will be late to the electric engines course of professor Jeffrey Besos
                  and he considers you his top student.</p>

              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3">
          <div className="sp-comic-video">
            <div className="sp-comic-video-content">
              <img src={videobg} alt="video"></img>
            </div>
            <div className="sp-previewtext">
              <button className="sp-playBtn">
                <span className="sp-playicon">
                  <img src={playicon} alt="playicon"></img>
                </span>
                Watch now
              </button>
            </div>
          </div>
          <div className="sp-comic-video">
            <div className="sp-comic-video-content">
              <img src={video2} alt="video"></img>
            </div>
            <button className="sp-playBtnRound">
              <img src={triangle} alt="playicon" className="triangle"></img>
            </button>
          </div>
        </div>
      </div>
    </div >
  );
}