
import ArrowIcon from "./arrow";
import CartIcon from "./carticon";
import LeftIcon from "./left-icon";
import NonftIcon from "./nonfts";
import RightIcon from "./right-icon";
import UpcomingGameIcon from "./upcominggame";
const icons = {
  lefticon: LeftIcon,
  righticon: RightIcon,
  upcominggamesicon: UpcomingGameIcon,
  carticon: CartIcon,
  nonfticon: NonftIcon,
  arrowicon: ArrowIcon,
}
export default icons;