import React from 'react';
const NonftIcon = ({
	style = {},
	fill = '#fff',
	fillOpacity = '0.1',
	fillNone = 'none',
	width = '140',
	className = '',
	viewBox = '0 0 140 140'
}) => (
	<>

		<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox={viewBox} >
			<rect fill="none" width="140" height="140" />
			<path fill={fill} d="M10,69.6c0-11,0-21.9,0-32.9c-0.1-1,0.4-2,1.4-2.5C30.5,23.4,49.5,12.4,68.6,1.5c0.9-0.6,2-0.6,2.9,0
	c18.9,10.9,37.8,21.8,56.8,32.6c1.1,0.5,1.8,1.7,1.7,2.9c0,21.9,0,43.7,0,65.6c0.1,1.1-0.6,2.2-1.6,2.7
	c-19,10.9-37.9,21.8-56.9,32.8c-0.9,0.7-2.2,0.6-3.1,0c-19-11-38-21.9-57-32.9c-1-0.5-1.6-1.5-1.4-2.6C10,91.5,10,80.6,10,69.6
	L10,69.6z M124.9,69.7L124.9,69.7c0-8.6,0-17.1,0-25.7c0-6.9,0.9-5.5-5.2-9C104,25.9,88.2,16.8,72.5,7.6c-1.4-1-3.2-1.1-4.6-0.2
	C51,17.3,34.2,27,17.3,36.8c-1.4,0.7-2.3,2.2-2.2,3.7c0,19.4,0,38.8,0,58.2c-0.1,1.6,0.7,3.1,2.2,3.8c16.8,9.6,33.6,19.3,50.4,29
	c1.4,1,3.3,1,4.7,0c16.7-9.7,33.5-19.3,50.2-29c1.5-0.7,2.4-2.2,2.3-3.9C124.8,89,124.9,79.4,124.9,69.7"  />
			<path fill={fill} d="M120,69.6c0,9.1,0,18.2,0,27.3c0.1,1-0.5,2-1.4,2.5c-15.8,9.1-31.5,18.1-47.3,27.2
	c-0.8,0.6-1.9,0.5-2.7,0c-15.6-9.1-31.3-18.1-47-27.1c-1.1-0.5-1.8-1.6-1.7-2.8c0.1-18,0.1-36,0-54c-0.1-1.1,0.5-2.2,1.5-2.7
	c15.7-9,31.4-18,47.1-27c0.9-0.6,2-0.6,2.9,0c15.7,9,31.4,18,47.1,27c1,0.5,1.6,1.5,1.5,2.6C120,51.6,120,60.6,120,69.6L120,69.6z
	 M107.4,48.9c-0.6-0.7-1.3-1.4-2-1.9c-11-6.4-22-12.8-33-19.2c-1.4-1-3.2-0.9-4.6,0c-11,6.4-22,12.8-33.1,19.1
	c-0.4,0.2-0.7,0.4-1,0.7c-1,0.7-1.2,2.1-0.5,3.1c0.6,1,1.8,1.4,2.8,1c0.5-0.2,0.9-0.4,1.4-0.7C47.7,45,58.1,39,68.5,33
	c0.9-0.7,2.2-0.6,3.1,0c6.9,4,13.8,8,20.7,11.9c3.7,2.1,7.4,4.3,11.1,6.4C105.3,52.4,107.4,51.5,107.4,48.9 M34.8,87.2
	c-0.7,0.5-1.4,1.1-1.9,1.8c-0.7,1.2-0.1,2.4,1.6,3.3c11.2,6.4,22.3,12.9,33.5,19.3c1.2,0.8,2.8,0.8,4.1,0
	c11.1-6.4,22.1-12.8,33.2-19.2c0.4-0.2,0.8-0.5,1.2-0.8c1-0.7,1.2-2.1,0.5-3.1c-0.6-1-1.8-1.4-2.9-0.9c-0.5,0.2-1,0.5-1.5,0.8
	c-10.3,5.9-20.6,11.9-30.9,17.8c-1,0.7-2.3,0.7-3.2,0c-10.4-6-20.8-12-31.2-18C36.6,87.9,35.8,87.6,34.8,87.2 M55.1,71.7l-0.4,0.1
	c-0.3-0.4-0.7-0.9-1-1.3c-3-3.9-5.9-7.9-8.9-11.8c-0.7-1-1.6-1.7-2.9-1.3c-1.2,0.4-1.9,1.6-1.8,2.8c0,6.3,0,12.6,0,18.8
	c0,1.8,1,3,2.5,3S45,80.8,45,79c0-3.3,0-6.6,0-10c0-0.5,0.1-1,0.1-1.8c0.6,0.7,0.9,1.2,1.3,1.6c3,4,6,8,9,11.9
	c0.6,1,1.7,1.5,2.8,1.1c1.1-0.4,1.9-1.5,1.8-2.6c0-0.3,0-0.6,0-0.9c0-5.8,0-11.5,0-17.3c0-0.3,0-0.6,0-0.9c-0.1-1.7-1.1-2.9-2.5-2.8
	s-2.4,1.1-2.4,2.9c0,1.3,0,2.6,0,3.9C55.1,66.6,55.1,69.1,55.1,71.7 M65.1,69.5c0,3.1,0,6.2,0,9.3c0,2,1,3.2,2.5,3.1
	s2.4-1.2,2.4-3.2c0-1.8,0.1-3.5,0-5.3c0-1.1,0.3-1.5,1.4-1.5c2.7,0.1,5.4,0,8.1,0c1.8,0,2.9-1,2.9-2.4c0-1.5-1.1-2.5-3-2.5
	c-2,0-3.9,0-5.9,0c-4.1-0.1-3.5,0.9-3.6-3.8c0-1,0.4-1.3,1.4-1.3c2.6,0,5.3,0,7.9,0c2,0,3.2-1,3.1-2.5c0-1.4-1.2-2.3-3.1-2.4
	c-3.7,0-7.4,0-11.1,0c-2.2,0-3.1,0.9-3.2,3.1C65,63.4,65.1,66.5,65.1,69.5 M92.5,71.3L92.5,71.3c0,2.8,0,5.5,0,8.3
	c0,1.3,1,2.5,2.4,2.5c0,0,0.1,0,0.1,0c1.3-0.1,2.3-1.2,2.4-2.5c0-0.5,0-0.9,0-1.4c0-4.8,0.1-9.6,0-14.3c0-1.4,0.4-1.9,1.8-1.7
	c1,0.1,2.1,0.1,3.1,0c1.4,0,2.5-1,2.6-2.4c0-1.3-1.1-2.4-2.5-2.4c0,0-0.1,0-0.1,0c-4.9,0-9.8,0-14.7,0c-1.3-0.1-2.5,1-2.6,2.3
	c0,0,0,0.1,0,0.1c0,1.4,1.2,2.5,2.5,2.4c1.2,0.1,2.4,0.1,3.6,0c1,0,1.4,0.4,1.3,1.4C92.5,66.1,92.5,68.7,92.5,71.3"/>
		</svg>
	</>
);
export default NonftIcon;