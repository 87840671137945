import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import Pages from './pages';
import './scss/main.scss';
import {RecoilRoot} from "recoil";

function App() {
    return (
        <RecoilRoot>
            <div className="App">
                <Router>
                    <Pages/>
                </Router>
            </div>
        </RecoilRoot>
    );
}

export default App;
