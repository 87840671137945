import {useEffect, useState} from "react";
import {StarPunksMethods} from "../../../../lib/StarPunksMethods";
import {toast} from "react-hot-toast";

export default function PunksAddress() {


    const [punksAddress, setPunksAddress] = useState('');

    useEffect(() => {
        if (typeof window.ethereum === 'undefined') {
            return;
        }
        StarPunksMethods.getPunksAddress().then(address => {
            setPunksAddress(address);
        }).catch(e => {
            toast.error(e.reason ?? e.message);
        });
    }, []);


    async function handleChange(ev) {
        setPunksAddress(ev.target.value);
    }

    async function update() {
        try {
            await StarPunksMethods.setPunksAddress(punksAddress);
            toast.success("Address updated");
        } catch (e) {
            toast.error(e.reason ?? e.message);
        }
    }

    return (
        <div className="sp-inputBox">
            <label className="sp-inputlabel">Punk$ Address</label>
            <div className="sp-label-intro">Update your punk adress</div>
            <div className="sp-inputlabel-inputBox">
                <input value={punksAddress} onChange={handleChange} type="text" placeholder=""
                       className="sp-admin-input"/>
                <button className="sp-admin-btn" onClick={update}>Update</button>
            </div>
        </div>
    );
}