import React, {useEffect, useState} from "react";
import {StarPunksMethods} from "../../../lib/StarPunksMethods";
import {ethers} from "ethers";
import {PunksMethods} from "../../../lib/PunksMethods";
import {toast} from "react-hot-toast";
// import Switch from "../../../components/switch";
// import Radio from "../../../components/radio";

export default function GenerateVouchersRadio({onSubmit}) {

    const [type, setType] = useState('StarPunks');
    const [priceType, setPriceType] = useState('Eth');
    const [count, setCount] = useState(1);
    const [free, setFree] = useState(false);


    const [price1, setPrice1] = useState('');
    const [price2, setPrice2] = useState('');
    const [price3, setPrice3] = useState('');


    useEffect(() => {
        if (typeof window.ethereum === 'undefined') {
            return;
        }
        StarPunksMethods.getEthPrice().then(price => {
            setPrice1(ethers.utils.formatUnits(price, 'ether') + '');
        });
        PunksMethods.getMintPrice().then(price => {
            setPrice2(ethers.utils.formatUnits(price, 18) + '');
        });
        PunksMethods.getPrice().then(price => {
            setPrice3(ethers.utils.formatUnits(price, 18) + '');
        });
    }, [])


    const onChange = (type, price_type, free = false) => {
        setType(type);
        setPriceType(price_type);
        setFree(free);
    };


    const submit = () => {
        if (typeof window.ethereum === 'undefined') {
            toast.error("connect your MetaMask wallet");
            return;
        }

        if (count > 5) {
            alert("Max 5 vouchers per request");
            return;
        }

        if (free === true) {
            onSubmit(count, type, priceType, "0");
            return;
        }

        if (type === "StarPunks" && priceType === 'Eth') {
            onSubmit(count, type, priceType, price1);
            return;
        }

        if (type === "StarPunks" && priceType === 'Punks') {
            onSubmit(count, type, priceType, price2);
            return;
        }

        if (type === "Punks" && priceType === 'Eth') {
            onSubmit(count, type, priceType, price3);
            return;
        }

    }

    return (
        <>
            <div className="sp-generateVoucher-box radio">
                <div className="sp-generateVoucher-box-title">Generate Vouchers</div>
                {/*<div className="sp-generateVoucher-box-select">*/}
                {/*    <div className="sp-form-group sp-toggel">*/}
                {/*        <input type="radio" id="punk$" name="white"/>*/}
                {/*        <label htmlFor="punk$">white label punks</label>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="sp-generateVoucher-box-select">*/}
                {/*    <div className="sp-form-group sp-toggel">*/}
                {/*        <input type="radio" id="nft" name="type" defaultChecked*/}
                {/*               onChange={() => onChange("StarPunks", 'Eth', true)}/>*/}
                {/*        <label htmlFor="nft">Free NFT</label>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="sp-generateVoucher-box-select">*/}
                {/*    <div className="sp-form-group sp-toggel">*/}
                {/*        <input type="radio" id="punks" name="type" />*/}
                {/*        <label htmlFor="punks">Free punks</label>*/}
                {/*    </div>*/}
                {/*    <input type="text" placeholder="100" className="sp-generateInput"></input>*/}
                {/*</div>*/}
                <div className="sp-generateVoucher-box-select">
                    <div className="sp-form-group sp-toggel">
                        <input type="radio" id="nfteth" name="type" onChange={() => onChange("StarPunks", 'Eth')}
                               defaultChecked/>
                        <label htmlFor="nfteth">StarPunks (NFT)</label>
                    </div>
                    <input type="text" placeholder="100" className="sp-generateInput" value={price1}
                           onChange={event => setPrice1(event.target.value)} disabled
                           style={{visibility: "hidden"}}></input>
                </div>
                {/*<div className="sp-generateVoucher-box-select">*/}
                {/*    <div className="sp-form-group sp-toggel">*/}
                {/*        <input type="radio" id="nftpunks" name="type"*/}
                {/*               onChange={() => onChange("StarPunks", 'Punks')}/>*/}
                {/*        <label htmlFor="nftpunks">Nft price in Punk$</label>*/}
                {/*    </div>*/}
                {/*    <input type="text" placeholder="100" className="sp-generateInput" value={price2}*/}
                {/*           onChange={event => setPrice2(event.target.value)} disabled></input>*/}
                {/*</div>*/}
                <div className="sp-generateVoucher-box-select">
                    <div className="sp-form-group sp-toggel">
                        <input type="radio" id="punketh" name="type" onChange={() => onChange("Punks", 'Eth')}/>
                        <label htmlFor="punketh">Punk$</label>
                    </div>
                    <input type="text" placeholder="100" className="sp-generateInput" value={price3}
                           onChange={event => setPrice3(event.target.value)} disabled
                           style={{visibility: "hidden"}}></input>
                </div>
                <div className="sp-generateVoucher-box-select">
                    <div className="sp-toggel">
                        <span>Number of Voucher</span>
                    </div>
                    <input type="text" placeholder="00" className="sp-generateInput" value={count}
                           onChange={event => setCount(parseInt(event.target.value))}></input>
                </div>
                <button className="sp-generateBtn" onClick={submit}>Generate</button>
            </div>
        </>
    );
}