import React from 'react';
const comikBookIcon = ({
  style = {},
  fill = '#f5c02f',
  fillOpacity = '1',
  fillNone = 'none',
  width = '35',
  className = '',
  viewBox = '0 0 35 35'
}) => (
  <>  
 
<svg xmlns="http://www.w3.org/2000/svg"  width={width} height={width} viewBox={viewBox}>
  <g id="Group_2217" data-name="Group 2217" transform="translate(-20 -323)">
    <rect id="Rectangle_118" data-name="Rectangle 118"width={width} height={width} transform="translate(20 323)" fill="rgba(0,0,0,0)" opacity="0"/>
    <path id="book-half" d="M12.75,3.583c.981-1.033,2.673-1.329,4.668-1.128A18.808,18.808,0,0,1,22.5,3.794V18.679a19.943,19.943,0,0,0-4.931-1.215,8.2,8.2,0,0,0-4.819.738ZM12,2.227C10.523.956,8.38.767,6.431.962A20.431,20.431,0,0,0,.44,2.62.75.75,0,0,0,0,3.3V19.8a.75.75,0,0,0,1.061.682,18.989,18.989,0,0,1,5.52-1.53c2.114-.213,3.885.13,4.834,1.315a.75.75,0,0,0,1.17,0c.95-1.185,2.721-1.528,4.833-1.315a18.973,18.973,0,0,1,5.521,1.53A.75.75,0,0,0,24,19.8V3.3a.75.75,0,0,0-.44-.682A20.431,20.431,0,0,0,17.569.962C15.62.766,13.477.956,12,2.227Z" transform="translate(26 330.104)" fill="#f5c02f"/>
  </g>
</svg>



  </>
);
export default comikBookIcon;