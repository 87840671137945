import React from 'react';
const punkbankIcon = ({
  style = {},
  fill = '#f5c02f',
  fillOpacity = '1',
  fillNone = 'none',
  width = '35',
  className = '',
  viewBox = '0 0 35 35'
}) => (
  <>   
<svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
  <g id="Group_2217" data-name="Group 2217" transform="translate(-20 -323)">
    <rect id="Rectangle_118" data-name="Rectangle 118" width={width} height={width} transform="translate(20 323)" fill="rgba(0,0,0,0)" opacity="0"/>
    <g id="safe2-fill" transform="translate(26 328)">
      <path id="Path_9911" data-name="Path 9911" d="M7.327,9.483H5.035a5.223,5.223,0,0,1,.993-2.394l1.62,1.62a2.981,2.981,0,0,0-.321.774ZM8.708,7.648l-1.62-1.62a5.223,5.223,0,0,1,2.394-.993V7.327A2.959,2.959,0,0,0,8.708,7.648Zm2.274-.321V5.035a5.223,5.223,0,0,1,2.394.993l-1.62,1.62a2.982,2.982,0,0,0-.774-.321Zm1.834,1.381,1.62-1.62a5.228,5.228,0,0,1,.993,2.394H13.138a2.984,2.984,0,0,0-.321-.774Zm.321,2.274H15.43a5.225,5.225,0,0,1-.993,2.394l-1.62-1.62A2.981,2.981,0,0,0,13.138,10.983Zm-1.381,1.834,1.62,1.62a5.224,5.224,0,0,1-2.394.993V13.138a2.959,2.959,0,0,0,.774-.321Zm-2.274.321V15.43a5.225,5.225,0,0,1-2.394-.993l1.62-1.62A2.981,2.981,0,0,0,9.483,13.138ZM7.648,11.757l-1.62,1.62a5.223,5.223,0,0,1-.993-2.394H7.327a2.959,2.959,0,0,0,.321.774Zm1.085-1.524a1.5,1.5,0,1,1,1.5,1.5A1.5,1.5,0,0,1,8.733,10.233Z" transform="translate(2.517 2.018)" fill="#f5c02f"/>
      <path id="Path_9912" data-name="Path 9912" d="M3.75,1A2.25,2.25,0,0,0,1.5,3.25V4H.75a.75.75,0,0,0,0,1.5H1.5v6H.75a.75.75,0,0,0,0,1.5H1.5v6H.75a.75.75,0,0,0,0,1.5H1.5v.75A2.25,2.25,0,0,0,3.75,23.5h18A2.25,2.25,0,0,0,24,21.25v-18A2.25,2.25,0,0,0,21.75,1Zm9,4.5A6.75,6.75,0,1,1,6,12.25,6.75,6.75,0,0,1,12.75,5.5Z" fill="#f5c02f"/>
    </g>
  </g>
</svg>

  </>
);
export default punkbankIcon;