import React from 'react';
const discordIcon = ({
  style = {},
  fill = '#f5c02f',
  fillOpacity = '1',
  fillNone = 'none',
  width = '35',
  className = '',
  viewBox = '0 0 35 35'
}) => (
  <>
    
    <svg xmlns="http://www.w3.org/2000/svg" width="16.8" height="12.806" viewBox="0 0 16.8 12.806">
  <path id="discord" d="M14.222,2.958A13.888,13.888,0,0,0,10.8,1.9a.053.053,0,0,0-.055.026,9.579,9.579,0,0,0-.426.875,12.8,12.8,0,0,0-3.841,0,8.67,8.67,0,0,0-.433-.875A.054.054,0,0,0,5.993,1.9a13.793,13.793,0,0,0-3.42,1.062.043.043,0,0,0-.022.019A14.18,14.18,0,0,0,.069,12.539a.059.059,0,0,0,.022.039A13.94,13.94,0,0,0,4.286,14.7a.052.052,0,0,0,.059-.02,9.994,9.994,0,0,0,.859-1.4.053.053,0,0,0-.011-.062.054.054,0,0,0-.019-.012,9.319,9.319,0,0,1-1.31-.625.053.053,0,0,1-.021-.069.054.054,0,0,1,.016-.02c.088-.066.176-.135.26-.2a.053.053,0,0,1,.054-.007,9.932,9.932,0,0,0,8.443,0,.055.055,0,0,1,.056.007c.084.069.172.139.26.2a.054.054,0,0,1,0,.089,8.667,8.667,0,0,1-1.311.624.052.052,0,0,0-.032.031.055.055,0,0,0,0,.043,11.166,11.166,0,0,0,.858,1.4.053.053,0,0,0,.059.02,13.9,13.9,0,0,0,4.2-2.121.051.051,0,0,0,.022-.039,14.08,14.08,0,0,0-2.484-9.561.036.036,0,0,0-.021-.02ZM5.614,10.63A1.614,1.614,0,0,1,4.1,8.937a1.606,1.606,0,0,1,1.51-1.694,1.6,1.6,0,0,1,1.51,1.694A1.606,1.606,0,0,1,5.614,10.63Zm5.582,0a1.615,1.615,0,0,1-1.51-1.693A1.606,1.606,0,0,1,11.2,7.244a1.6,1.6,0,0,1,1.51,1.694A1.6,1.6,0,0,1,11.2,10.63Z" transform="translate(0 -1.895)" fill="#fff"/>
</svg>

  </>
);
export default discordIcon;