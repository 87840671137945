import CoinIcon from "./coin";
import PlusIcon from "./plus";
import MinusIcon from "./minus";
import InfoIcon from "./info";
const icons = {
  coinicon: CoinIcon,
  plusicon: PlusIcon,
  minusicon: MinusIcon,
  infoicon: InfoIcon
}
export default icons;