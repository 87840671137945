import React from 'react';
const claimVoucherIcon = ({
                           style = {},
                           fill = '#f5c02f',
                           fillOpacity = '1',
                           fillNone = 'none',
                           width = '35',
                           className = '',
                           viewBox = '0 0 35 35'
                       }) => (
    <>
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
            <g id="Group_2217" data-name="Group 2217" transform="translate(-20 -323)">
                <rect id="Rectangle_118" data-name="Rectangle 118" width="34" height="34" transform="translate(20 323)" fill="rgba(0,0,0,0)" opacity="0"/>
                <path id="local_activity_FILL0_wght400_GRAD0_opsz48" d="M12.88,22.46,16,20.03l3.12,2.43-1.2-3.84,2.94-2.46h-3.6L16,12.5l-1.26,3.66h-3.6l2.94,2.46ZM5.8,27.2A1.744,1.744,0,0,1,4,25.4V20.81a3.223,3.223,0,0,0,1.845-1.125A3.162,3.162,0,0,0,6.58,17.6a3.221,3.221,0,0,0-.735-2.1A3.052,3.052,0,0,0,4,14.39V9.8A1.744,1.744,0,0,1,5.8,8H26.2A1.744,1.744,0,0,1,28,9.8v4.59a3.052,3.052,0,0,0-1.845,1.11,3.221,3.221,0,0,0-.735,2.1,3.162,3.162,0,0,0,.735,2.085A3.223,3.223,0,0,0,28,20.81V25.4a1.744,1.744,0,0,1-1.8,1.8Zm0-1.8H26.2V22.13a6.407,6.407,0,0,1-1.86-1.95,4.982,4.982,0,0,1,0-5.16,6.407,6.407,0,0,1,1.86-1.95V9.8H5.8v3.27a6.118,6.118,0,0,1,1.875,1.95,5.073,5.073,0,0,1,0,5.16A6.118,6.118,0,0,1,5.8,22.13ZM16,17.6Z" transform="translate(21 322)" fill="#f5c02f"/>
            </g>
        </svg>
    </>
);
export default claimVoucherIcon;