import React from 'react';
const SettingIcon = ({
  style = {},
  fill = '#f5c02f',
  fillOpacity = '0.3',
  fillNone = 'none',
  width = '35',
  className = '',
  viewBox = '0 0 35 35'
}) => (
  <>

    <svg id="settings" xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <rect id="Rectangle_118" data-name="Rectangle 118" width={width} height={width} fill={fillNone} />
      <g id="Group_1695" data-name="Group 1695" transform="translate(-21 -427)">
        <g id="Polygon_1" data-name="Polygon 1" transform="translate(25 433)" fill={fillNone}>
          <path d="M18.516,0a3,3,0,0,1,2.6,1.5l5.022,8.721a3,3,0,0,1,0,2.994l-5.022,8.721a3,3,0,0,1-2.6,1.5H8.484a3,3,0,0,1-2.6-1.5L.862,13.218a3,3,0,0,1,0-2.994L5.885,1.5A3,3,0,0,1,8.484,0Z" stroke="none" />
          <path d="M 8.48423957824707 2 C 8.127670288085938 2 7.795610427856445 2.191949844360352 7.617660522460938 2.500940322875977 L 2.59535026550293 11.22187042236328 C 2.418069839477539 11.52970027923584 2.418069839477539 11.91215991973877 2.595340728759766 12.21998977661133 L 7.617660522460938 20.94091033935547 C 7.795610427856445 21.24991035461426 8.127670288085938 21.44186019897461 8.48423957824707 21.44186019897461 L 18.51576042175293 21.44186019897461 C 18.87232971191406 21.44186019897461 19.20438003540039 21.24991035461426 19.3823299407959 20.94091033935547 L 24.40464973449707 12.21998977661133 C 24.58193016052246 11.91215991973877 24.58193016052246 11.52970027923584 24.40465927124023 11.22187995910645 L 19.38232040405273 2.500940322875977 C 19.20438003540039 2.191949844360352 18.87232971191406 2 18.51576042175293 2 L 8.48423957824707 2 M 8.48423957824707 0 L 18.51576042175293 0 C 19.58872985839844 0 20.58000946044922 0.5730400085449219 21.11547088623047 1.502840042114258 L 26.1378002166748 10.22377014160156 C 26.67151069641113 11.1505298614502 26.67151069641113 12.29133033752441 26.1378002166748 13.21809005737305 L 21.11547088623047 21.93902015686035 C 20.58000946044922 22.86882019042969 19.58872985839844 23.44186019897461 18.51576042175293 23.44186019897461 L 8.48423957824707 23.44186019897461 C 7.411270141601562 23.44186019897461 6.419990539550781 22.86882019042969 5.884519577026367 21.93902015686035 L 0.8621997833251953 13.21809005737305 C 0.3284893035888672 12.29133033752441 0.3284893035888672 11.1505298614502 0.8621997833251953 10.22377014160156 L 5.884519577026367 1.502840042114258 C 6.419990539550781 0.5730400085449219 7.411270141601562 0 8.48423957824707 0 Z" stroke={fillNone} fill={fill} />
        </g>
        <g id="Ellipse_37" data-name="Ellipse 37" transform="translate(34 440)" fill={fill} stroke={fill} strokeWidth="1.5" opacity={fillOpacity}>
          <circle cx="4.5" cy="4.5" r="4.5" stroke={fillNone} />
          <circle cx="4.5" cy="4.5" r="3.75" fill={fillNone} />
        </g>
      </g>
    </svg>
  </>
);
export default SettingIcon;