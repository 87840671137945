import {useEffect, useState} from "react";
import {PunksMethods} from "../../../../lib/PunksMethods";
import {toast} from "react-hot-toast";

export default function StarPunksAddress() {

    const [starPunksAddress, setStarPunksAddress] = useState('');

    useEffect(() => {
        if (typeof window.ethereum === 'undefined') {
            return;
        }
        PunksMethods.getStarPunksAddress().then(address => {
            setStarPunksAddress(address);
        }).catch(e => {
            toast.error(e.reason ?? e.message);
        });
    }, []);

    async function handleChange(ev) {
        setStarPunksAddress(ev.target.value);
    }

    async function update() {
        try {
            await PunksMethods.setStarPunksAddress(starPunksAddress);
            toast.success("Address updated");
        } catch (e) {
            toast.error(e.reason ?? e.message);
        }
    }


    return (
        <div className="sp-inputBox">
            <label className="sp-inputlabel">StarPunks Address</label>
            <div className="sp-label-intro">Update your punk adress</div>
            <div className="sp-inputlabel-inputBox">
                <input type="text" value={starPunksAddress} onChange={handleChange} className="sp-admin-input"/>
                <button className="sp-admin-btn" onClick={update}>Update</button>
            </div>
        </div>
    );
}