import SVGIcon from "../../../components/icons";


export default function NoNFTs(props) {

    return (<>
        <div className="sp-noNFTs">
            <div className="sp-noNFTs-icon">
                <SVGIcon iconname="nonfticon" />
            </div>
            <div className="sp-noNFTs-text">You don't have any NFTs</div>
        </div>
    </>
    );
}