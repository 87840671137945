import React, {useEffect, useState} from "react";
import GenerateVouchersRadio from "./GenerateVouchersRadio";
// import GenerateVouchersSwitch from "./GenerateVouchersSwitch";
// import Switch from "../../../components/switch";
import {Table} from "../../../components/table";
import axios from "axios";
import {Config} from "../../../config";
import {useSearchParams} from "react-router-dom";
import {toast, Toaster} from "react-hot-toast";
import DeleteButton from "./DeleteButton";

export default function ShareAffiliate() {
    const VoucherListColumns = [
        {
            Header: 'Voucher code', accessor: 'code'
        },
        {
            Header: 'Description', accessor: 'type', Cell: (props) => {
                const type = props.row.original.type;
                const price_type = props.row.original.price_type;
                const price = props.row.original.price;
                const description = type + " - " + price + price_type;
                return (<>{description}</>)
            }
        },
        {
            Header: 'Used / Unused', accessor: 'claimed', Cell: (props) => {
                return (<>{props.row.original.claimed === true ? 'Used' : 'Non used'}
                </>)
            }
        },
        {
            Header: 'Action', // accessor: 'code',
            Cell: (props) => <DeleteButton code={props.row.original.code} deleteVoucher={deleteVoucher}> </DeleteButton>
        }
    ];


    const [vouchers, setVouchers] = useState([]);
    const [key] = useState("Tokenash");
    const [aff, setAff] = useState(0);
    const [subAff, setSubAff] = useState(0);
    const [urlParams] = useSearchParams();


    const deleteVoucher = async (code) => {
        await deleteVoucherRequest(key, code, aff);
        const result = await getVouchersList(key, 0, aff);
        if (result.data.errors) {
            for (const err of result.data.errors) {
                toast.error(err.message);
            }
            return;
        }
        setVouchers(result.data.data.vouchers);
    };

    const generateVouchers = async (count, type, priceType, price) => {
        const createVouchersResult = await createVouchersRequest(key, count, type, priceType, price, aff, subAff);
        if (createVouchersResult.data.errors) {
            for (const err of createVouchersResult.data.errors) {
                toast.error(err.message);
            }
            return;
        }

        const voucherListResult = await getVouchersList(key, 0, aff);
        if (voucherListResult.data.errors) {
            for (const err of voucherListResult.data.errors) {
                toast.error(err.message);
            }
            return;
        } else {
            setVouchers(voucherListResult.data.data.vouchers);
        }
    };

    const deleteVoucherRequest = async (key, code, affiliate) => {
        return await axios.post(Config.API_URL, {
            query: `
                mutation DeleteVoucher($key:String!,$code:String!,$affiliate:Int){
                  voucher_delete(key:$key,code:$code,affiliate:$affiliate)
                }
            `, variables: {
                key,
                code,
                affiliate
            }
        });
    };

    const getVouchersList = async (key, page, affiliate) => {
        return await axios.post(Config.API_URL, {
            query: `
                query GetVouchers($key:String!,$page:Int!,$affiliate:Int){
                  vouchers(key:$key,page:$page,affiliate:$affiliate){
                    code
                    nonce
                    type
                    price   
                    price_type
                    add_date
                    settings
                    sharable
                    claimed
                  }
                }
            `, variables: {
                page, key, affiliate
            }
        });
    };

    const createVouchersRequest = async (key, number, type, price_type, price_value, affiliate, subaffiliate) => {
        const settings = {};
        if (subaffiliate) {
            settings["subaffiliate"] = subaffiliate;
        }
        return await axios.post(Config.API_URL, {
            query: `
                mutation CreateVouchers($key:String!,$number:Int!,$type:VoucherType!,$price_type:PriceType!,$price_value:String!,$affiliate:Int,$settings:JSONObject){
                  vouchers(key:$key,number:$number,type:$type,price_type:$price_type,price_value:$price_value,affiliate:$affiliate,settings:$settings){
                    code
                    nonce
                    price
                    price_type
                    add_date
                    settings
                  }
                }
            `, variables: {
                key,
                number,
                type,
                price_type,
                price_value,
                affiliate,
                settings
            }
        });
    };

    useEffect(() => {
        if (urlParams.get("aff")) {
            setAff(parseInt(urlParams.get("aff")));
        }
        if (urlParams.get("subaff")) {
            setSubAff(parseInt(urlParams.get("subaff")));
        }
    }, [urlParams])

    useEffect(() => {
        if (key !== "" && aff !== 0) {
            getVouchersList(key, 0, aff).then(result => {
                setVouchers(result.data.data.vouchers);
            }).catch(err => {
                const errors = err.response.data.errors;
                for (const err of errors) {
                    toast.error(err.message);
                }
            });
        }
    }, [key, aff]);

    const content = (key === "" || aff === 0) ? <div>No access allowed</div> : <>
        <Toaster toastOptions={{
            // Define default options
            className: '', duration: 5000, style: {
                background: '#363636', color: '#fff',
            }, // Default options for specific types
            success: {
                duration: 3000, theme: {
                    primary: 'forestgreen', secondary: 'white',
                },
            }, error: {
                duration: 3000, theme: {
                    primary: 'red', secondary: 'white',
                },
            }
        }}/>
        <div className="sp-generateVoucher">
            <div className="sp-card sp-p30">
                <div className="sp-card-header">
                    <div className="sp-card-header-wrap">
                        <h1 className="sp-card-header-title">Generate Vouchers</h1>
                        <p className="sp-card-header-desc">StarPunks generate vouchers</p>
                    </div>
                </div>
                <div className="sp-card-body">
                    <div className="row">
                        <div className="col-xxl-8 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="sp-voucher">
                                <div className="sp-voucher-title">Voucher list</div>
                                <div className="sp-table">
                                    <Table columns={VoucherListColumns}
                                           data={vouchers}
                                           showPagination={true}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                            {/* <GenerateVouchersSwitch /> */}
                            <GenerateVouchersRadio onSubmit={generateVouchers}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;

    return (<>
        {content}
    </>);
}