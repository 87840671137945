import React from 'react';
const SoundIcon = ({
	style = {},
	fill = '#fff',
	fillOpacity = '0.5',
	fillNone = 'none',
	width = '24',
	className = '',
	viewBox = '0 0 24 24'
}) => (
	<>
		<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={width} height={width} viewBox={viewBox}>
			<rect fill={fillNone} width="24" height="24" />
			<g>
				<path fill={fill} d="M24,12.6c-0.1,0.4-0.1,0.8-0.2,1.3c-0.4,2.4-1.5,4.6-3.3,6.4c-0.5,0.5-1,0.9-1.5,1.3 c-0.2,0.2-0.5,0.2-0.7,0c-0.2-0.1-0.3-0.4-0.2-0.6c0.1-0.1,0.2-0.3,0.3-0.4c1.1-0.8,2.1-1.9,2.8-3.1c1.2-2,1.8-4.4,1.5-6.7 c-0.3-3-1.8-5.7-4.3-7.6c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.2-0.2-0.6,0-0.8C18.5,2,18.7,2,18.9,2c0.1,0,0.1,0.1,0.2,0.1 c2.4,1.7,4,4.3,4.6,7.1c0.1,0.5,0.2,1.1,0.2,1.6c0,0.1,0,0.1,0,0.2L24,12.6z" />
				<path fill={fill} d="M10.9,11.8c0,3,0,6,0,9c0,0.1,0,0.2,0,0.2c0,0.3-0.2,0.5-0.5,0.5c-0.1,0-0.3-0.1-0.4-0.1 c-0.1-0.1-0.1-0.1-0.2-0.2c-1.8-1.7-3.5-3.3-5.3-5c-0.1-0.1-0.2-0.1-0.3-0.1c-1.2,0-2.3,0-3.5,0C0.2,16.2,0,16,0,15.5 c0-2.4,0-4.9,0-7.3c0-0.5,0.2-0.7,0.7-0.7c1.1,0,2.3,0,3.4,0c0.2,0,0.3-0.1,0.4-0.2c1.8-1.7,3.5-3.4,5.3-5c0.1-0.1,0.1-0.1,0.2-0.2 c0.2-0.2,0.6-0.1,0.7,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.3C10.9,5.8,10.9,8.8,10.9,11.8 M9.8,19.8v-16C9.7,3.9,9.6,4,9.6,4 c-1.5,1.4-3,2.8-4.4,4.2C4.9,8.4,4.7,8.6,4.4,8.5c-1,0-2,0-3,0H1.1v6.6h0.3c1,0,2,0,3,0c0.3,0,0.6,0.1,0.8,0.3 c0.7,0.7,1.4,1.3,2.1,2L9.8,19.8" />
				<path fill={fill} d="M19.6,11.9c0,2.5-1.3,4.9-3.4,6.2c-0.4,0.3-0.7,0.3-0.9,0c-0.2-0.3-0.1-0.6,0.3-0.9c3-2,3.8-6,1.8-9 c-0.5-0.7-1-1.3-1.7-1.7c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.2-0.3-0.5-0.1-0.8c0.2-0.2,0.5-0.3,0.7-0.1c0.3,0.2,0.5,0.4,0.8,0.6 C18.6,7.4,19.7,9.6,19.6,11.9" />
				<path fill={fill} d="M15.3,11.8c0,1-0.5,1.9-1.2,2.5c-0.2,0.2-0.6,0.2-0.8,0c0,0,0,0,0,0c-0.2-0.2-0.2-0.6,0.1-0.8 c0,0,0,0,0.1,0c0.5-0.4,0.8-0.9,0.8-1.5c0.1-0.7-0.2-1.4-0.8-1.9C13.2,10,13,9.7,13.1,9.5c0.1-0.3,0.4-0.5,0.7-0.4 c0.1,0,0.2,0.1,0.2,0.1C14.8,9.8,15.3,10.8,15.3,11.8" />
			</g>
		</svg>
	</>
);
export default SoundIcon;