import React from "react";
import moment from "moment";

export default function StarPunksItem(props) {
    const {item} = props;
    const {name, image, traits, claim} = item;

    let imageUrl = "";
    const imgRegexp = /^ipfs:\/\/(\S+)$/gm;
    if (image.startsWith("ipfs:")) {
        const matches = image.matchAll(imgRegexp);
        for (const match of matches) {
            if (match && match.length === 2) {
                imageUrl = "https://ipfs.io/ipfs/" + match[1];
            }
        }
    } else {
        imageUrl = image;
    }

    const now = moment()
    const daysAgo = now.diff(claim.date, "days")

    return <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
        <div className="sp-starpunk-card">
            <div className="sp-punkImage">
                <img src={imageUrl} alt="punk"/>
            </div>
            <div className="sp-total-cardpunks">{claim.tokens} Punk$</div>
            <div className="sp-total-cardpunks-title">{name}</div>
            <div className="sp-last-claim">{claim.count === 0 ? "Not claimed" : daysAgo + " days ago"}</div>
            {
                traits.map(({key, value}, index) => <div key={index} className="sp-punk-character">
                        <span className="sp-punk-title">{key}</span>
                        <span className="sp-punk-Name">{value}</span>
                    </div>
                )
            }
            {/*<div className="sp-btn-box">*/}
            {/*    <button className="sp-settingBtn">Settings</button>*/}
            {/*</div>*/}
        </div>
    </div>;
}