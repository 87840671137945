import {useEffect, useState} from "react";
import {StarPunksMethods} from "../../../../lib/StarPunksMethods";
import moment from "moment";
import {BigNumber} from "ethers";
import {toast} from "react-hot-toast";

export default function StarPunksMintSettings() {

    const [maxMints, setMaxMints] = useState(0);
    const [maxDate, setMaxDate] = useState('');


    useEffect(() => {
        if (typeof window.ethereum === 'undefined') {
            return;
        }
        StarPunksMethods.getMaxMints().then(maxMints => {
            setMaxMints(maxMints.toNumber());
        }).catch(e => {
            toast.error(e.reason ?? e.message);
        });
        StarPunksMethods.getMaxDate().then(maxDate => {
            const timestamp = BigNumber.from(maxDate).toNumber();
            const dateString = moment(timestamp * 1000).format('YYYY-MM-DD');
            setMaxDate(dateString);
        }).catch(e => {
            toast.error(e.reason ?? e.message);
        });
    }, [])


    function handleMaxMints(ev) {
        setMaxMints(ev.target.value);
    }

    function handleMaxDate(ev) {
        setMaxDate(ev.target.value);
    }

    async function updateSettings() {
        try {
            const timestamp = new Date(maxDate).getTime() / 1000;
            await StarPunksMethods.updateMintSettings(maxMints, timestamp);
            toast.success("Settings updated");
        } catch (e) {
            toast.error(e.reason ?? e.message);
        }
    }

    return (
        <div className="sp-mint-setting">
            <div className="sp-card-header">
                <div className="sp-card-header-wrap">
                    <h1 className="sp-card-header-title">Mint Settings</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-6">
                    <div className="row">
                        <div className="col-xl-6">
                            <label className="sp-mint-setting-label">Max Mints</label>
                            <div className="sp-label-intro">This number represents the number of NFTs that can be
                                minted. After every mint, this number decreases by 1
                            </div>
                            <input value={maxMints} onChange={handleMaxMints} type="number" placeholder="15"
                                   className="sp-mint-setting-input"/>
                        </div>
                        <div className="col-xl-6">
                            <label className="sp-mint-setting-label">Max Date</label>
                            <div className="sp-label-intro">Set this to a date in the future. Minting NFTs will be
                                allowed until that date. Set a date in past to disable minting.
                            </div>
                            <input value={maxDate} onChange={handleMaxDate} type="date"
                                   className="sp-mint-setting-input" required/>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 d-flex justify-content-end align-items-end">
                    <button className="sp-admin-btn" onClick={updateSettings}>Update</button>
                </div>
            </div>
        </div>
    );
}