import {useEffect, useState} from "react";
import {BigNumber, ethers} from "ethers";
import moment from "moment";
import {PunksMethods} from "../../../../lib/PunksMethods";
import {toast} from "react-hot-toast";

export default function PunksMintSettings() {
    const [maxDate, setMaxDate] = useState("");
    const [maxMints, setMaxMints] = useState("0");

    useEffect(() => {
        if (typeof window.ethereum === 'undefined') {
            return;
        }
        PunksMethods.getMaxMints().then(maxMints => {
            const n = ethers.utils.formatUnits(maxMints);
            setMaxMints(n);
        }).catch(e => {
            toast.error(e.reason ?? e.message);
        });

        PunksMethods.getMaxDate().then(maxDate => {
            const timestamp = BigNumber.from(maxDate).toNumber();
            const dateString = moment(timestamp * 1000).format('YYYY-MM-DD');
            setMaxDate(dateString);
        }).catch(e => {
            toast.error(e.reason ?? e.message);
        });
    }, []);

    function handleMaxDate(ev) {
        setMaxDate(ev.target.value);
    }

    function handleMaxMints(ev) {
        setMaxMints(ev.target.value);
    }

    async function updateSettings() {
        try {
            const limit = ethers.utils.parseUnits(maxMints);
            const timestamp = new Date(maxDate).getTime() / 1000;
            await PunksMethods.updateMintSettings(limit, timestamp);
            toast.success("Settings updated");
        } catch (e) {
            toast.error(e.reason ?? e.message);
        }
    }

    return (
        <div className="sp-mint-setting">
            <div className="sp-card-header">
                <div className="sp-card-header-wrap">
                    <h1 className="sp-card-header-title">Mint Settings</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-6">
                    <div className="row">
                        <div className="col-xl-6">
                            <label className="sp-mint-setting-label">Max Mints</label>
                            <div className="sp-label-intro">This number represents the number of Punk$ that can be
                                minted. Number decreases with every punk$ sale.
                            </div>
                            <input value={maxMints} onChange={handleMaxMints} type="number" placeholder="15"
                                   className="sp-mint-setting-input"/>
                        </div>
                        <div className="col-xl-6">
                            <label className="sp-mint-setting-label">Max Date</label>
                            <div className="sp-label-intro">Set this to a date in the future. Minting Punk$ will be
                                allowed until that date. Set a date in past to disable minting.
                            </div>
                            <input value={maxDate} onChange={handleMaxDate} type="date"
                                   className="sp-mint-setting-input" required/>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 d-flex justify-content-end align-items-end">
                    <button className="sp-admin-btn" onClick={updateSettings}>Update</button>
                </div>
            </div>
        </div>
    );
}