import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import SVGIcon from "../../../components/icons";
import {BigNumber, ethers} from "ethers";
import FlipCountdown from "@rumess/react-flip-countdown";
import {StarPunksMethods} from "../../../lib/StarPunksMethods";
import {PunksMethods} from "../../../lib/PunksMethods";
import axios from "axios";
import {Config} from "../../../config";
import {toast, Toaster} from "react-hot-toast";
import {useRecoilState} from "recoil";
import {punksState} from "../../../states/punks.atom";

export default function MintStartPunks() {

    const now = new Date().toISOString();
    const [maxDate, setMaxDate] = useState(now);
    const [maxMints, setMaxMints] = useState(0);
    const [ethPrice, setEthPrice] = useState(0);
    const [punksPrice, setPunksPrice] = useState(0);
    const [price, setPrice] = useState(0);
    const [currency, setCurrency] = useState("punk$");
    const [code, setCode] = useState("");
    const [voucher, setVoucher] = useState();

    const [myPunks, setMyPunks] = useRecoilState(punksState);


    useEffect(() => {
        StarPunksMethods.getMaxDate().then(date => {
            if (!date) {
                return;
            }
            const timestamp = BigNumber.from(date).toNumber();
            const newDate = new Date(timestamp * 1000).toISOString();
            setMaxDate(newDate);
        }).catch(err => {
        });
        StarPunksMethods.getMaxMints().then(mints => {
            if (!mints) {
                return;
            }
            const mintsNumber = BigNumber.from(mints).toNumber();
            setMaxMints(mintsNumber);
        }).catch(err => {
        });
        StarPunksMethods.getEthPrice().then(price => {
            if (!price) {
                return;
            }
            setEthPrice(ethers.utils.formatUnits(price, 'ether'));
        }).catch(err => {
        });
        PunksMethods.getMintPrice().then(price => {
            if (!price) {
                return;
            }
            setPunksPrice(ethers.utils.formatUnits(price, 18));
        }).catch(err => {
        });
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            StarPunksMethods.getMaxMints().then(mints => {
                if (!mints) {
                    return;
                }
                const mintsNumber = BigNumber.from(mints).toNumber();
                setMaxMints(mintsNumber);
            }).catch(err => {
            });
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (currency === "punk$") {
            setPrice(punksPrice);
        }
        if (currency === "eth") {
            setPrice(ethPrice);
        }
    }, [currency, punksPrice, ethPrice]);


    function handlePriceChange() {

    }

    function handleCurrencyChange(ev) {
        setCurrency(ev.target.value);
    }

    const fetchVoucher = async (address, code) => {
        return await axios.post(Config.API_URL, {
            query: `
                query GetVoucher($address:String!,$code:String!){
                  voucher(address:$address,code:$code){
                    code
                    nonce
                    type
                    signature(address:$address)
                    price
                    price_type
                    add_date
                  }
                }
            `,
            variables: {
                address,
                code
            }
        });
    };


    async function handleVoucher() {
        try {
            const [account] = await StarPunksMethods.requestAccount();
            if (account) {
                if (code && account) {
                    const result = await fetchVoucher(account, code);
                    const voucherData = result.data.data.voucher;
                    if (voucherData && voucherData.type === "StarPunks") {
                        setVoucher(voucherData);
                        if (voucherData.price_type === "Punks") {
                            setPunksPrice(voucherData.price);
                            setCurrency("punk$");
                        }
                        if (voucherData.price_type === "Eth") {
                            setEthPrice(voucherData.price);
                            setCurrency("eth");
                        }
                    } else {
                        toast.error("Voucher is not valid");
                    }
                } else {
                    toast.error("You need to enter a code first");
                }
            } else {
                toast.error("You need to login in your wallet first");
            }
        } catch (e) {
            toast.error("An error occurred. Make sure you're logged into your wallet.");
        }
    }

    function cancelVoucher() {
        setVoucher(null);
        StarPunksMethods.getEthPrice().then(price => {
            setEthPrice(ethers.utils.formatUnits(price, 'ether'));
        });
        PunksMethods.getMintPrice().then(price => {
            setPunksPrice(ethers.utils.formatUnits(price, 18));
        });
        setCurrency("punk$");
        setCode("");
    }

    function handleCode(ev) {
        setCode(ev.target.value);
    }

    async function mint() {
        if (typeof window.ethereum === 'undefined') {
            toast.error("You need to connect to your metamask wallet");
            return;
        }
        try {
            if (voucher) {
                if (voucher.price_type === 'Eth') {
                    await StarPunksMethods.mintWithEtherAndVoucher(voucher.price, voucher.nonce, voucher.signature);
                    setMaxMints(maxMints - 1);
                    toast.success("StarPunks mint successfully", {duration: 5000});
                }
                if (voucher.price_type === 'Punks') {
                    await PunksMethods.mintNFTVoucher(voucher.price, voucher.nonce, voucher.signature);
                    setMaxMints(maxMints - 1);
                    setMyPunks((parseFloat(myPunks) - parseFloat(punksPrice)).toFixed(2) + "");
                    toast.success("StarPunks mint successfully", {duration: 5000});
                }
            } else {
                if (currency === 'eth') {
                    await StarPunksMethods.mintWithEther(ethPrice);
                    setMaxMints(maxMints - 1);
                    toast.success("StarPunks mint successfully", {duration: 5000});
                }
                if (currency === 'punk$') {
                    await PunksMethods.mintNFT();
                    setMaxMints(maxMints - 1);
                    setMyPunks((parseFloat(myPunks) - parseFloat(punksPrice)).toFixed(2) + "");
                    toast.success("StarPunks mint successfully", {duration: 5000});
                }
            }
        } catch (e) {
            toast.error(e.reason ?? e.message);
        }
    }


    return (
        <>
            <Toaster toastOptions={{
                // Define default options
                className: '',
                duration: 5000,
                style: {
                    background: '#363636',
                    color: '#fff',
                },
                // Default options for specific types
                success: {
                    duration: 3000,
                    theme: {
                        primary: 'forestgreen',
                        secondary: 'white',
                    },
                },
                error: {
                    duration: 3000,
                    theme: {
                        primary: 'red',
                        secondary: 'white',
                    },
                }
            }}/>
            <div className="row">
                <div className="col-xxl-9 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="sp-card sp-p30">
                        <div className="sp-card-header">
                            <div className="sp-card-header-wrap">
                                <h1 className="sp-card-header-title">Buy today, Starpunks prices increases daily with 5%
                                    until sold out!</h1>
                            </div>
                        </div>
                        <div className="sp-card-body sp-mintNow">
                            <div className="row">
                                <div
                                    className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12  d-flex align-items-center ">
                                    <div className="sp-form-group">
                                        <input readOnly type='text' className="sp-form-control" value={price}
                                               onChange={handlePriceChange}></input>
                                    </div>
                                </div>
                                <div
                                    className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12  d-flex align-items-center ">
                                    <div className="sp-radio-buttons">
                                        <div className="sp-form-group">
                                            <input type="radio" id="punk$" value="punk$" name="currency"
                                                   checked={currency === 'punk$'} onChange={handleCurrencyChange}/>
                                            <label htmlFor="punk$">Punk$</label>
                                        </div>

                                        <div className="sp-form-group">
                                            <input type="radio" id="eth" value="eth" name="currency"
                                                   checked={currency === 'eth'} onChange={handleCurrencyChange}/>
                                            <label htmlFor="eth">ETH</label>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  d-flex align-items-center ">
                                    <div className="sp-form-group">
                                        <label className="sp-form-group-label">Voucher</label>
                                        <div className="sp-form-group-wrap">
                                            {!voucher ?
                                                <>
                                                    <input type='text' className="sp-form-control"
                                                           value={code} onChange={handleCode}></input>
                                                    <button type="button" className="sp-btn"
                                                            onClick={handleVoucher}>Apply
                                                    </button>
                                                </>
                                                : <>
                                                    <div className="sp-voucher-apply">
                                                        <div className="sp-voucher-apply-code sp-form-control">
                                                            <span>Code</span> {voucher.code}</div>
                                                        <div className="sp-voucher-apply-price sp-form-control">
                                                            <span>Price</span>{voucher.price} {voucher.price_type === "Eth" ? "ETH" : "Punk$"}
                                                        </div>
                                                        <div className="sp-voucher-apply-cancel"
                                                             onClick={cancelVoucher}>Cancel
                                                        </div>
                                                    </div>
                                                </>}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-start">
                                    <Link to="" className="sp-mint-btn" onClick={mint}><span
                                        className="sp-mint-btn-title">Mint Now</span>Price increases
                                        daily, hurry
                                        up!</Link>
                                </div>
                            </div>
                        </div>
                        <div className="sp-card-footer"></div>
                    </div>
                </div>
                <div className="col-xxl-3 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="sp-card sp-p30 sp-mintNow-item">
                                <div className="sp-card-header">
                                    <div className="sp-card-header-wrap">
                                        <h2 className="sp-card-header-title">{maxMints}</h2>
                                    </div>
                                </div>
                                <div className="sp-card-body">
                                    <h3 className="sp-mintNow-item-subtitle">Starpunks left for today!</h3>
                                    <p className="sp-mintNow-item-desc"><SVGIcon iconname="infoicon"></SVGIcon>1
                                        StarPunk is {ethPrice} ETH</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="sp-card sp-p30 sp-mintNow-item">
                                <div>
                                    <FlipCountdown
                                        size='small'
                                        hideYear
                                        hideMonth
                                        endAtZero
                                        titlePosition={'bottom'}
                                        endAt={maxDate}
                                    />
                                </div>
                                <div className="sp-card-header"></div>
                                <div className="sp-card-body">
                                    <h3 className="sp-mintNow-item-subtitle">Price available</h3>
                                    <p className="sp-mintNow-item-desc">Price increases daily, hurry up!</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h4 className="sp-mintNow-text mb-3">Starpunks metagame lauches when all 7800 starpunks are sold
                        out!</h4>
                    <h4 className="sp-mintNow-text">punk$ are used to upgrade your starpunk NFT's traits to increase
                        it's value and generate more daily punk$</h4>
                </div>
            </div>
        </>
    );
}