import React from 'react';
const RightIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.5  ',
  fillNone = 'none',
  width = '25',
  className = '',
  viewBox = '0 0 25 25.222'
}) => (
  <>

    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="notifiaction-icon" transform="translate(-10006 -1541.878)" opacity="0.5">
        <g id="Rectangle_95" data-name="Rectangle 95" transform="translate(10006 1542)" fill={fill} stroke={fill} strokeWidth="0.2" opacity="0">
          <rect width="25" height="25" stroke="none" />
          <rect x="0.1" y="0.1" width="24.8" height="24.8" fill={fillNone} />
        </g>
        <path id="bell" d="M11.937,25a3.125,3.125,0,0,0,3.125-3.125H8.812A3.125,3.125,0,0,0,11.937,25Zm0-22-1.245.252a6.253,6.253,0,0,0-5,6.126,33.906,33.906,0,0,1-.717,5.847,20.211,20.211,0,0,1-1.036,3.528H19.94A20.307,20.307,0,0,1,18.9,15.222a33.906,33.906,0,0,1-.717-5.847,6.253,6.253,0,0,0-5-6.125L11.937,3Zm9.718,15.752a3.975,3.975,0,0,0,1.219,1.562H1a3.975,3.975,0,0,0,1.219-1.562c1.406-2.812,1.906-8,1.906-9.374a7.815,7.815,0,0,1,6.257-7.657,1.562,1.562,0,1,1,3.109,0,7.815,7.815,0,0,1,6.257,7.657C19.749,10.75,20.249,15.937,21.655,18.749Z" transform="translate(10007 1542.001)" fill={fill} stroke={fill} strokeWidth="0.2" />
      </g>
    </svg>
  </>
);
export default RightIcon;