import {useEffect, useState} from "react";
import {PunksMethods} from "../../../../lib/PunksMethods";
import {ethers} from "ethers";
import {toast} from "react-hot-toast";

export default function PunksMintingNFTPrice() {

    const [price, setPrice] = useState(0);

    useEffect(() => {
        if (typeof window.ethereum === 'undefined') {
            return;
        }
        PunksMethods.getMintPrice().then(price => {
            setPrice(ethers.utils.formatUnits(price, 'ether'));
        }).catch(e => {
            toast.error(e.reason ?? e.message);
        });

    }, [])

    function handleChange(ev) {
        setPrice(ev.target.value);
    }

    async function updatePrice() {
        try {
            const bigPrice = ethers.utils.parseEther(price + '');
            await PunksMethods.setMintPrice(bigPrice);
            setPrice(price);
            toast.success("Price updated");
        } catch (e) {
            toast.error(e.reason ?? e.message);
        }
    }

    return (
        <div className="sp-inputBox">
            <label className="sp-inputlabel">Mint price in punk$</label>
            <div className="sp-label-intro">How much punk$ cost to mint an NFT (StarPunks)</div>
            <div className="sp-inputlabel-inputBox">
                <div className="sp-admin-inputBox">
                    <input type="text" value={price} onChange={handleChange} className="sp-admin-input"/>
                    <div className="sp-value-title">punk$</div>
                </div>
                <button onClick={updatePrice} className="sp-admin-btn">Update</button>
            </div>
        </div>
    );
}