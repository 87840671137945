import React from "react";
import gameicon from "../../../assets/media/icon/gamelogo.png";
import comingsoon from "../../../assets/media/upcoming/comingsoon.png";
import champ1 from "../../../assets/media/games/jeff.png";
import epic from "../../../assets/media/games/epic.png";
import alon from "../../../assets/media/games/alon.png";
import starpunk from "../../../assets/media/games/starpunk.png";
import SVGIcon from "../../../components/icons/index";
export default function Games() {
  return (
    <div className="sp-games">
      <div className="sp-card sp-p30">
        <div className="sp-card-header">
          <div className="sp-card-header-wrap">
            <h1 className="sp-card-header-title">Starpunk Game</h1>
            <p className="sp-card-header-desc">The Starpunks game</p>
          </div>
        </div>
        <div className="sp-card-body">
          <div className="sp-coming-soon">
            <div className="sp-coming-soon-textBox">
              <div className="sp-coming-soon-textBox-title">Coming Soon...</div>
              <div className="sp-coming-soon-textBox-subtitle">The Starpunks game is under development.</div>
              <div className="sp-coming-soon-review">
                <div className="sp-coming-soon-review-gameicon">
                  <img src={gameicon} alt="gameicon"></img>
                </div>
                <div className="sp-coming-soon-review-text">
                  <div className="sp-coming-soon-review-text-title">3.5cr</div>
                  <div className="sp-coming-soon-review-text-subtitle">Reviews</div>
                </div>
              </div>
            </div>
            <div className="sp-coming-soon-image">
              <img src={comingsoon} alt="" />
            </div>

          </div>
          <div className="sp-champ">
            <div className="sp-card-header">
              <div className="sp-card-header-wrap">
                <h1 className="sp-card-header-title">Starpunk Champions</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6">
                <div className="sp-champ-box">
                  <div className="sp-champ-box-image">
                    <img src={champ1} alt="champ1"></img>
                  </div>
                  <div className="sp-champ-box-text">
                    <div className="sp-champ-box-text-title">Jeff Besos </div>
                    <div className="sp-champ-box-text-subtitle">When Jeff Besos decided to lead the rebelion against the powerful Elon and Tezla robots</div>
                    <div className="sp-champ-box-text-footer">
                      <div className="sp-champ-box-gameicon">
                        <div className="sp-gameLogo">
                          <img src={gameicon} alt="gamelogo"></img>
                        </div>
                        <div className="sp-gameLogo-text">
                          <div className="sp-gameLogo-text-title">35</div>
                          <div className="sp-gameLogo-text-subtitle">Reviews</div>
                        </div>
                      </div>
                      <button className="sp-champ-card-viewBtn">
                        <SVGIcon iconname="arrowicon"></SVGIcon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6">
                <div className="sp-champ-box">
                  <div className="sp-champ-box-image">
                    <img src={epic} alt="champ1"></img>
                  </div>
                  <div className="sp-champ-box-text">
                    <div className="sp-champ-box-text-title">EPIC REAL</div>
                    <div className="sp-champ-box-text-subtitle">As they were talking, a loud blast happend and the sky darkened for a second with </div>
                    <div className="sp-champ-box-text-footer">
                      <div className="sp-champ-box-gameicon">
                        <div className="sp-gameLogo">
                          <img src={gameicon} alt="gamelogo"></img>
                        </div>
                        <div className="sp-gameLogo-text">
                          <div className="sp-gameLogo-text-title">3.5cr</div>
                          <div className="sp-gameLogo-text-subtitle">Reviews</div>
                        </div>
                      </div>
                      <button className="sp-champ-card-viewBtn">
                        <SVGIcon iconname="arrowicon"></SVGIcon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6">
                <div className="sp-champ-box">
                  <div className="sp-champ-box-image">
                    <img src={alon} alt="champ1"></img>
                  </div>
                  <div className="sp-champ-box-text">
                    <div className="sp-champ-box-text-title">Elon Musk</div>
                    <div className="sp-champ-box-text-subtitle">This comic book gets released after the NFT collection sells out, and can be claimed as an NFT for a given token price in punk$ or download for free.</div>
                    <div className="sp-champ-box-text-footer">
                      <div className="sp-champ-box-gameicon">
                        <div className="sp-gameLogo">
                          <img src={gameicon} alt="gamelogo"></img>
                        </div>
                        <div className="sp-gameLogo-text">
                          <div className="sp-gameLogo-text-title">35</div>
                          <div className="sp-gameLogo-text-subtitle">Reviews</div>
                        </div>
                      </div>
                      <button className="sp-champ-card-viewBtn">
                        <SVGIcon iconname="arrowicon"></SVGIcon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6">
                <div className="sp-champ-box">
                  <div className="sp-champ-box-image">
                    <img src={starpunk} alt="champ1"></img>
                  </div>
                  <div className="sp-champ-box-text">
                    <div className="sp-champ-box-text-title">Starpunk</div>
                    <div className="sp-champ-box-text-subtitle">The StarPunks saga where Cpt Musk the owner of Tezla Robots</div>
                    <div className="sp-champ-box-text-footer">
                      <div className="sp-champ-box-gameicon">
                        <div className="sp-gameLogo">
                          <img src={gameicon} alt="gamelogo"></img>
                        </div>
                        <div className="sp-gameLogo-text">
                          <div className="sp-gameLogo-text-title">35</div>
                          <div className="sp-gameLogo-text-subtitle">Reviews</div>
                        </div>
                      </div>
                      <button className="sp-champ-card-viewBtn">
                        <SVGIcon iconname="arrowicon"></SVGIcon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}