import React from "react";
import { Link } from "react-router-dom";

import SVGIcon from "./icons";
const Header = () => {
  const issidebarOpen = () => {
    document.body.classList.toggle('sp-drawermenu-open');
  }

  return (
    <>
      <div className="sp-header">
        <Link to="" className="sp-logo">STARPunks</Link>
        <div className="sp-iconbar">
          <Link to="#" className="sp-searchBox sp-icons">
            <SVGIcon iconname="soundicon"></SVGIcon>
          </Link>
          <Link to="#" className="sp-notification sp-icons">
            <SVGIcon iconname="notificationicon"></SVGIcon>
            <div className="sp-notification-number">1</div>
          </Link>
          <Link to="#" className="sp-menuicon sp-icons" onClick={() => issidebarOpen()}>
            <SVGIcon iconname="menuicon"></SVGIcon>
          </Link>
        </div>
      </div>
    </>
  );
}
export default Header;