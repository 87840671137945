import {PunksMethods} from "../../../lib/PunksMethods";
import {toast} from "react-hot-toast";

export default function ConnectWallet() {

    async function connectWallet() {
        if (typeof window.ethereum === 'undefined') {
            toast.error("You need to install MetaMask");
            return;
        }
        await PunksMethods.requestAccount();
    }

    return (
        <>
            <div className="sp-connect-wallet">
                <div className="sp-connect-wallet-text">You need to connect your wallet first.</div>
                <button className="sp-connectBtn " onClick={connectWallet}>Connect wallet</button>
            </div>

        </>
    );
}