import React, {useState} from "react";
import {useSearchParams} from "react-router-dom";

export default function Access() {

    const [key, setKey] = useState("");

    let [searchParams, setSearchParams] = useSearchParams();


    function handleChange(ev) {
        setKey(ev.target.value);
    }

    function updateKey() {
        setSearchParams({key});
    }


    return (
        <div className="sp-card sp-p30">
            <div className="sp-card-header">
                <div className="sp-card-header-wrap">
                    <h1 className="sp-card-header-title">No access</h1>
                </div>
            </div>
            <div className="sp-card-body">
                <div className="col-xl-12">
                    <div className="sp-inputBox">
                        <label className="sp-inputlabel">Key</label>
                        <div className="sp-inputlabel-inputBox">
                            <div className="sp-admin-inputBox">
                                <input value={key} onChange={handleChange} type="text" className="sp-admin-input"/>
                                <div className="sp-value-title"></div>
                            </div>
                            <button onClick={updateKey} className="sp-admin-btn">Proceed</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}