import React, {useEffect, useState} from "react";
import axios from "axios";
import {Config} from "../../../config";
import {toast, Toaster} from "react-hot-toast";
import Access from "./Access";
import {PunksMethods} from "../../../lib/PunksMethods";
import {Table} from "../../../components/table";

export default function NftVouchers() {

    const [nfts, setNfts] = useState([]);
    const [account, setAccount] = useState(null);

    const VoucherListColumns = [
        {
            Header: 'NFT', accessor: 'metadata', Cell: props => {
                const metadata = JSON.parse(props.row.original.metadata);
                const nftName = props.row.original.nft_name;
                const nftId = props.row.original.nft_id;
                if (metadata && metadata.image) {
                    const imageUrl = getImageUrl(metadata.image);
                    return (<div className="nft-collumn">
                        <div>
                            <img src={imageUrl} alt=""/>
                        </div>
                        <div className="separator"></div>
                        <div>
                            {nftName} - ID: {nftId}
                        </div>
                    </div>);
                }
            }
        },
        {
            Header: 'Voucher', accessor: 'voucher', Cell: (props) => {
                if (props.row.original.voucher) {
                    return props.row.original.voucher.code;
                } else {
                    const id = props.row.original.nft_id;
                    const contractAddress = props.row.original.nft_contract;
                    return <button className="generate-button"
                                   onClick={
                                       async () => {
                                           const {index} = props.row;
                                           const result = await generateVoucherRequest(id, contractAddress);
                                           if (result) {
                                               setNfts(nfts => {
                                                   nfts[index].voucher = result.data.data.generate_voucher_from_nft;
                                                   return [...nfts];
                                               });
                                           }
                                       }
                                   }>Generate</button>;
                }
            }
        },
        {
            Header: 'Description', accessor: 'type', Cell: (props) => {
                if (props.row.original.voucher) {
                    const {voucher} = props.row.original;
                    const type = voucher.type;
                    const price_type = voucher.price_type;
                    const price = voucher.price;
                    const description = type + " - " + price + price_type;
                    return (<>{description}</>)
                } else {
                    return "-";
                }
            }
        },
        {
            Header: 'Used / Unused', accessor: 'claimed', Cell: (props) => {
                return (<>{props.row.original.claimed === true ? 'Used' : 'Non used'}
                </>)
            }
        }
    ];

    function getImageUrl(image) {
        let imageUrl = "";
        const imgRegexp = /^ipfs:\/\/(\S+)$/gm;
        if (image.startsWith("ipfs:")) {
            const matches = image.matchAll(imgRegexp);
            for (const match of matches) {
                if (match && match.length === 2) {
                    imageUrl = "https://ipfs.io/ipfs/" + match[1];
                    // imageUrl = "https://gateway.pinata.cloud/ipfs/" + match[1];
                }
            }
        } else {
            imageUrl = image;
        }
        return imageUrl;
    }

    async function fetchNfts(address) {
        return await axios.post(Config.API_URL, {
            query: `
                query NFTList($filter:NFTFilter!){       
                    nft_list(filter:$filter){
                        id
                        name
                        symbol
                        contractAddress
                        ownerAddress
                        uri
                        metadata
                        claim{
                          date
                          count
                          tokens
                        }
                        voucher{
                          type
                          code
                          claimed
                          price
                          price_type
                        }
                  }
                }
            `,
            variables: {
                filter: {
                    address
                }
            }
        });
    }


    async function generateVoucherRequest(id, contract) {
        return await axios.post(Config.API_URL, {
            query: `
                mutation GenerateVoucher($id:String!,$contract:String!){       
                    generate_voucher_from_nft(id:$id,contract:$contract){
                          type
                          code
                          claimed
                          price
                          price_type
                          nonce
                  }
                }
            `,
            variables: {
                id,
                contract
            }
        });
    }


    useEffect(() => {

        PunksMethods.listAccounts().then(accounts => {
            if (accounts.length > 0) {
                setAccount(accounts[0]);
            } else {
                setAccount(null);
            }
        });
        if (typeof window.ethereum !== 'undefined') {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    setAccount(accounts[0]);
                } else {
                    setAccount(null);
                }
            });
        }
    }, []);


    useEffect(() => {
        if (account) {
            // replace this with account 0x7eb413211a9DE1cd2FE8b8Bb6055636c43F7d206
            fetchNfts('0x7eb413211a9DE1cd2FE8b8Bb6055636c43F7d206').then(result => {
                const nftsList = [];
                const nfts = result.data.data.nft_list;
                for (const nft of nfts) {
                    nftsList.push({
                        voucher: nft.voucher,
                        metadata: nft.metadata,
                        nft_name: nft.name,
                        nft_id: nft.id,
                        nft_contract: nft.contractAddress
                    });
                }
                setNfts(nftsList);
            }).catch(err => {
                toast.error(err.message);
            });
        }
    }, [account]);

    useEffect(() => {
        if (nfts.length > 0) {
            const {voucher, nft_id, nft_contract} = nfts[0];
            if (!voucher) {
                generateVoucherRequest(nft_id, nft_contract).then(result => {
                    if (result) {
                        setNfts(nfts => {
                            nfts[0].voucher = result.data.data.generate_voucher_from_nft;
                            return [...nfts];
                        });
                    }
                }).catch(err => {

                });
            }
        }
    }, [
        nfts
    ]);


    const content = account === null ? <Access></Access> : <>
        <Toaster toastOptions={{
            // Define default options
            className: '', duration: 5000, style: {
                background: '#363636', color: '#fff',
            }, // Default options for specific types
            success: {
                duration: 3000, theme: {
                    primary: 'forestgreen', secondary: 'white',
                },
            }, error: {
                duration: 3000, theme: {
                    primary: 'red', secondary: 'white',
                },
            }
        }}/>
        <div className="sp-nftVoucher">
            <div className="sp-card sp-p30">
                <div className="sp-card-header">
                    <div className="sp-card-header-wrap">
                        <h1 className="sp-card-header-title">Claim Invitations</h1>
                        <p className="sp-card-header-desc">If you have BoredApeYachtClub, CryptoPunks, or other popular
                            NFTs, you can claim one free invitation per each NFT you own, and mint with priority the
                            StarPunks collection</p>
                    </div>
                </div>
                <div className="sp-card-body">
                    <div className="row">
                        <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="sp-voucher">
                                <div className="sp-voucher-title">Voucher list</div>
                                <div className="sp-table">
                                    <Table columns={VoucherListColumns}
                                           data={nfts}
                                           showPagination={true}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;

    return (<>
        {content}
    </>);
}