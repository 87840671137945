import React from 'react';
const MarketIcon = ({
  style = {},
  fill = '#f5c02f',
  fillOpacity = '1',
  fillNone = 'none',
  width = '35',
  className = '',
  viewBox = '0 0 35 35'
}) => (
  <>

    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="Group_1872" data-name="Group 1872" transform="translate(-26 -209)">
        <rect id="Rectangle_159" data-name="Rectangle 159" width={width} height={width} transform="translate(26 209)" fill="rgba(0,0,0,0)" />
        <path id="bag-check-fill" d="M15.929,5.5a3.929,3.929,0,1,0-7.857,0v.786h7.857Zm1.571,0v.786H23V22a3.143,3.143,0,0,1-3.143,3.143H4.143A3.143,3.143,0,0,1,1,22V6.286H6.5V5.5a5.5,5.5,0,1,1,11,0Zm-1.015,8.413A.787.787,0,0,0,15.372,12.8l-4.158,4.16-1.8-1.8A.787.787,0,1,0,8.3,16.271l2.357,2.357a.786.786,0,0,0,1.113,0Z" transform="translate(32 214)" fill={fill} fillRule="evenodd" />
      </g>
    </svg>
  </>
);
export default MarketIcon;