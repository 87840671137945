import closeIcon from "./close";
import CreateIcon from "./crete-icon";
import HomeIcon from "./home-icon";
import MarketIcon from "./market-icon";
import MenuIcon from "./menutoggle-icon";
import SettingIcon from "./setting-icon";
import claimPunkIcon from "./claimpunk";
import comikBookIcon from "./comikbook";
import gameIcon from "./game";
import punkbankIcon from "./punkbank";
import tshirtIcon from "./tshirt";
import twitterIcon from "./twitter";
import discordIcon from "./discord";
import telegramIcon from "./telegram";
import ShareIcon from "./shareicon";
import claimVoucherIcon from "./claimvoucher";
const icons = {
  menuicon: MenuIcon,
  homeicon: HomeIcon,
  createicon: CreateIcon,
  marketicon: MarketIcon,
  settingicon: SettingIcon,
  closeicon: closeIcon,
  claimpunkicon: claimPunkIcon,
  claimvouchericon: claimVoucherIcon,
  comikbookicon: comikBookIcon,
  gameicon: gameIcon,
  punkbankicon: punkbankIcon,
  tshirticon: tshirtIcon,
  twittericon: twitterIcon,
  discordicon: discordIcon,
  telegramicon: telegramIcon,
  shareicon: ShareIcon,
}
export default icons;