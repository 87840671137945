import React from 'react';
const SearchIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.5',
  fillNone = 'none',
  width = '24',
  className = '',
  viewBox = '0 0 24 24'
}) => (
  <>

    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="Group_1717" data-name="Group 1717" transform="translate(-388 -50)" opacity={fillOpacity}>
        <g id="search" transform="translate(388 50)">
          <g id="Group_1582" data-name="Group 1582">
            <g id="Group_1581" data-name="Group 1581">
              <path id="Path_453" data-name="Path 453" d="M10.569,0A10.569,10.569,0,1,0,21.138,10.569,10.581,10.581,0,0,0,10.569,0Zm0,19.187a8.618,8.618,0,1,1,8.618-8.618A8.628,8.628,0,0,1,10.569,19.187Z" fill="#fff" />
            </g>
          </g>
          <g id="Group_1584" data-name="Group 1584" transform="translate(16.455 16.455)">
            <g id="Group_1583" data-name="Group 1583">
              <path id="Path_454" data-name="Path 454" d="M358.3,356.925l-5.594-5.593a.975.975,0,0,0-1.38,1.38l5.593,5.594a.976.976,0,1,0,1.38-1.38Z" transform="translate(-351.046 -351.046)" fill={fill} />
            </g>
          </g>
        </g>
      </g>
    </svg>

  </>
);
export default SearchIcon;