import React from 'react';
const ShareIcon = ({
  style = {},
  fill = '#f5c02f',
  fillOpacity = '1',
  fillNone = 'none',
  width = '34',
  className = '',
  viewBox = '0 0 34 34'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="Group_2217" data-name="Group 2217" transform="translate(-20 -323)">
        <rect id="Rectangle_118" data-name="Rectangle 118" width={width} height={width} transform="translate(20 323)" fill="rgba(0,0,0,0)" opacity="0" />
        <path id="share-fill" d="M15.125,3.438a3.437,3.437,0,1,1,.829,2.238L6.717,9.966a3.436,3.436,0,0,1,0,2.068l9.237,4.29a3.437,3.437,0,1,1-.671,1.2l-9.237-4.29a3.437,3.437,0,1,1,0-4.477l9.237-4.29a3.437,3.437,0,0,1-.158-1.034Z" transform="translate(26 329)" fill={fill} />
      </g>
    </svg>


  </>
);
export default ShareIcon;