import nft1 from "../../../assets/media/share/shreNFT1.png";
import nft2 from "../../../assets/media/share/shreNFT2.png";
import nft3 from "../../../assets/media/share/shreNFT3.png";
import giftBox from "../../../assets/media/icon/gifticon.svg";
import React, {useEffect, useState} from "react";
import VoucherItem from "./voucher_item";
import axios from "axios";
import {Config} from "../../../config";
import {toast, Toaster} from "react-hot-toast";
import {PunksMethods} from "../../../lib/PunksMethods";
import {useRecoilState} from "recoil";
import {punksState} from "../../../states/punks.atom";
import {ethers} from "ethers";

export default function ShareVouchers(props) {

    const [used, setUsed] = useState(0);
    const {vouchers, address} = props;

    const [myPunks, setMyPunks] = useRecoilState(punksState);


    const buildClaimRequest = async (address) => {
        return await axios.post(Config.API_URL, {
            query: `
                mutation BuildVoucherClaim($address:String!){
                  voucher_claim(address:$address){
                    status
                    total
                    signature
                    nonce
                  }
                }
            `,
            variables: {
                address
            }
        });
    };

    useEffect(() => {
        const total = vouchers.filter(v => v.claimed === true).length;
        setUsed(total);
    }, [vouchers]);


    const claimPrize = async () => {
        if (used < 3) {
            toast.error("You can claim the prize after all 3 vouchers are validated");
            return;
        }
        const claimRequest = await buildClaimRequest(address);
        const claimStatus = claimRequest.data.data.voucher_claim;
        const {status, nonce, total, signature} = claimStatus;
        if (status === false) {
            toast.error(signature);
            return;
        }
        try {
            if (status === true) {
                await PunksMethods.claim(total, nonce, signature);
                const n = ethers.utils.formatUnits(total);
                console.log(parseFloat(myPunks) + parseFloat(n));
                setMyPunks((parseFloat(myPunks) + parseFloat(n)).toFixed(2) + '');
                toast.success("Punk$ claimed successfully");
            }
        } catch (e) {
            toast.error(e.reason ?? e.message);
        }
    };


    return <>
        <Toaster toastOptions={{
            // Define default options
            className: '',
            duration: 5000,
            style: {
                background: '#363636',
                color: '#fff',
            },
            // Default options for specific types
            success: {
                duration: 3000,
                theme: {
                    primary: 'forestgreen',
                    secondary: 'white',
                },
            },
            error: {
                duration: 3000,
                theme: {
                    primary: 'red',
                    secondary: 'white',
                },
            }
        }}/>
        <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                <div className="sp-voucherBox sp-card sp-p30">
                    <div className="sp-voucherBox-title">Invite 3 friends to mint a StarPunk$ NFT and you
                        receive 100 punks.
                    </div>
                    <div className="row">
                        <div className="col-xxl-9 col-xl-9">
                            <div className="sp-voucherBox-subtitle">Vouchers</div>
                            {vouchers.map((value, index) => {
                                return <VoucherItem key={index} voucher={value}></VoucherItem>
                            })}

                            <div className="sp-voucherUsed"><span>Vouchers used : </span> {used} / 3</div>
                        </div>
                        <div className="col-xl-3 col-xxl-3"></div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                        <div className="sp-nft-card">
                            <div className="sp-nft-card-image">
                                <img src={nft1} alt="nft"></img>
                            </div>
                            <div className="sp-nft-card-title">Bill Gates Viking</div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                        <div className="sp-nft-card">
                            <div className="sp-nft-card-image">
                                <img src={nft2} alt="nft"></img>
                            </div>
                            <div className="sp-nft-card-title">Bill Gates Boxer</div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6">
                        <div className="sp-nft-card">
                            <div className="sp-nft-card-image">
                                <img src={nft3} alt="nft"></img>
                            </div>
                            <div className="sp-nft-card-title">Bill Gates Pride</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <div className="sp-giftBox sp-card sp-p30">
                    <div className="sp-giftBox-image">
                        <img src={giftBox} alt="gifticon"></img>
                    </div>
                    <div className="sp-giftBox-price">100 Punk$</div>
                    <div className="sp-giftBox-claimBtn" onClick={claimPrize}>Claim</div>
                </div>
            </div>
        </div>
    </>;
}