import React from 'react';
const PlusIcon = ({
	style = {},
	fill = '#fff',
	fillOpacity = '0.5',
	fillNone = 'none',
	width = '16',
	className = '',
	viewBox = '0 0 16 16'
}) => (
	<>
		<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={width} height={width} viewBox={viewBox}>
			<rect fill={fillNone} width="16" height="16" />
			<path fill={fill} d="M8,1.9c0.3,0,0.5,0.2,0.5,0.5v5h5c0.3,0,0.5,0.2,0.5,0.5 c0,0.3-0.2,0.5-0.5,0.5h-5v5c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5v-5h-5C2.2,8.5,1.9,8.3,1.9,8c0-0.3,0.2-0.5,0.5-0.5h5v-5 C7.5,2.2,7.7,1.9,8,1.9C8,1.9,8,1.9,8,1.9z" />
		</svg>
	</>
);
export default PlusIcon;