import React from "react";
import punk1 from "./../../../assets/media/punks/punk01.png";
import punk2 from "./../../../assets/media/punks/punk02.png";
import punk3 from "./../../../assets/media/punks/punk03.png";
import punk4 from "./../../../assets/media/punks/punk04.png";
import punk5 from "./../../../assets/media/punks/punk05.png";
import punk6 from "./../../../assets/media/punks/punk06.png";
import punk7 from "./../../../assets/media/punks/punk07.png";
import punk8 from "./../../../assets/media/punks/punk08.png";
import punk9 from "./../../../assets/media/punks/punk09.png";

export default function PunkBank() {
  return (
    <div className="sp-punkbank">
      <div className="sp-card sp-p30">
        <div className="sp-card-header">
          <div className="sp-card-header-wrap">
            <h1 className="sp-card-header-title">Punkbank</h1>
            <p className="sp-card-header-desc">PunkBank is the first NFT Bank in the world!</p>
          </div>
        </div>
        <div className="sp-card-body">
          <div className="sp-punks">
            <div className="sp-punks-image">
              <img src={punk1} alt="punk" />
            </div>
            <div className="sp-punks-image">
              <img src={punk2} alt="punk" />
            </div>
            <div className="sp-punks-image">
              <img src={punk3} alt="punk" />
            </div>
            <div className="sp-punks-image">
              <img src={punk4} alt="punk" />
            </div>
            <div className="sp-punks-image">
              <img src={punk5} alt="punk" />
            </div>
            <div className="sp-punks-image">
              <img src={punk6} alt="punk" />
            </div>
            <div className="sp-punks-image">
              <img src={punk7} alt="punk" />
            </div>
            <div className="sp-punks-image">
              <img src={punk8} alt="punk" />
            </div>
            <div className="sp-punks-image">
              <img src={punk9} alt="punk" />
            </div>
          </div>
        </div>
      </div></div>
  );
}