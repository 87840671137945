import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import SVGIcon from "../../../components/icons";
import Upcoming from "../../../assets/media/upcoming/upcoming.png";
import punk1 from "../../../assets/media/upcoming/punk1.png";
import punk2 from "../../../assets/media/upcoming/punk2.png";
import punk3 from "../../../assets/media/upcoming/punk3.png";
import punk4 from "../../../assets/media/upcoming/punk4.png";
import freepunk1 from "../../../assets/media/punks/punk01.png";
import freepunk2 from "../../../assets/media/punks/punk02.png";
import freepunk3 from "../../../assets/media/punks/punk03.png";
import freepunk4 from "../../../assets/media/punks/punk04.png";
import freepunk5 from "../../../assets/media/punks/punk05.png";
import freepunk6 from "../../../assets/media/punks/punk06.png";
import freepunk7 from "../../../assets/media/punks/punk07.png";
import freepunk8 from "../../../assets/media/punks/punk08.png";
import freepunk9 from "../../../assets/media/punks/punk09.png";
import freepunk10 from "../../../assets/media/punks/punk10.png";
import freepunk11 from "../../../assets/media/punks/punk11.png";
import freepunk12 from "../../../assets/media/punks/punk12.png";
import {Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {useState} from "react";
import {PunksMethods} from "../../../lib/PunksMethods";
import {BigNumber, ethers} from "ethers";
import {StarPunksMethods} from "../../../lib/StarPunksMethods";
import FlipCountdown from "@rumess/react-flip-countdown";
import {toast, Toaster} from "react-hot-toast";
import {useRecoilState} from "recoil";
import {punksState} from "../../../states/punks.atom";

export default function Home() {

    // StarPunks
    const now = new Date().toISOString();
    const [maxDate, setMaxDate] = useState(now);
    const [maxMints, setMaxMints] = useState(0);
    const [ethPrice, setEthPrice] = useState(0);
    const [punksMintPrice, setPunksMintPrice] = useState(0);
    const [price, setPrice] = useState(0);
    const [currency, setCurrency] = useState("punk$");

    // Punk$
    const [punksEthPrice, setPunksEthPrice] = useState('0');
    const [punks, setPunks] = useState(10);
    const [total, setTotal] = useState(0);


    const [myPunks, setMyPunks] = useRecoilState(punksState);

    useEffect(() => {

        StarPunksMethods.getMaxDate().then(date => {
            if (date) {
                const timestamp = BigNumber.from(date).toNumber();
                const newDate = new Date(timestamp * 1000).toISOString();
                setMaxDate(newDate);
            }
        }).catch(err => {
        });
        StarPunksMethods.getMaxMints().then(mints => {
            if (mints) {
                const mintsNumber = BigNumber.from(mints).toNumber();
                setMaxMints(mintsNumber);
            }
        }).catch(err => {
        });

        StarPunksMethods.getEthPrice().then(price => {
            if (!price) {
                return;
            }
            setEthPrice(ethers.utils.formatUnits(price, 'ether'));
        }).catch(err => {
        });
        PunksMethods.getMintPrice().then(price => {
            if (!price) {
                return;
            }
            setPunksMintPrice(ethers.utils.formatUnits(price, 18));
        }).catch(err => {
        });

        PunksMethods.getPrice().then(price => {
            if (!price) {
                return;
            }
            const eth = ethers.utils.formatEther(price);
            setPunksEthPrice(eth);
        }).catch(err => {
        });


    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            StarPunksMethods.getMaxMints().then(mints => {
                if (!mints) {
                    return;
                }
                const mintsNumber = BigNumber.from(mints).toNumber();
                setMaxMints(mintsNumber);
            }).catch(err => {
            });
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const tokens = parseInt(punks);
        const t = parseFloat(punksEthPrice) * tokens;
        setTotal(t.toFixed(3));
    }, [punksEthPrice, punks]);

    useEffect(() => {
        if (currency === "punk$") {
            setPrice(punksMintPrice);
        }
        if (currency === "eth") {
            setPrice(ethPrice);
        }
    }, [currency, punksMintPrice, ethPrice]);

    function increasePunks() {
        setPunks(punks => punks + 1);
    }

    function decreasePunks() {
        setPunks(punks => punks - 1 > 0 ? punks - 1 : 0);
    }

    function handlePunksChange(ev) {
        const tokens = parseInt(ev.target.value);
        if (tokens < 0) {
            setPunks(0);
            return;
        }
        setPunks(tokens);
    }

    async function buyPunks() {
        try {
            await PunksMethods.buy(punks, total + '');
            setMyPunks((parseFloat(myPunks) + punks).toFixed(2) + '');
            toast.success("Transaction sent successfully");
        } catch (e) {
            if (e.reason) {
                toast.error(e.reason);
            } else {
                toast.error('An error occurred.');
            }
        }
    }

    function handlePriceChange() {

    }

    function handleCurrencyChange(ev) {
        setCurrency(ev.target.value);
    }


    async function mintStarPunks() {
        try {
            if (currency === 'eth') {
                await StarPunksMethods.mintWithEther(ethPrice);
                setMaxMints(maxMints - 1);
                toast.success("Transaction sent successfully");
            }
            if (currency === 'punk$') {
                await PunksMethods.mintNFT();
                setMaxMints(maxMints - 1);
                setMyPunks((parseFloat(myPunks) - punksMintPrice).toFixed(2) + '');
                toast.success("Transaction sent successfully");
            }
        } catch (e) {
            if (e.reason) {
                toast.error(e.reason);
            } else {
                toast.error('An error occurred.');
            }
        }
    }

    return (
        <>
            <Toaster toastOptions={{
                // Define default options
                className: '',
                duration: 5000,
                style: {
                    background: '#363636',
                    color: '#fff',
                },
                // Default options for specific types
                success: {
                    duration: 3000,
                    theme: {
                        primary: 'forestgreen',
                        secondary: 'white',
                    },
                },
                error: {
                    duration: 3000,
                    theme: {
                        primary: 'red',
                        secondary: 'white',
                    },
                }
            }}/>
            <div className="sp-home">
                {/* <div className="sp-title">Let the battle begin!</div> */}
                <div className="row">
                    <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="sp-upcoming-image">
                            <img src={Upcoming} alt="upcoming"/>
                            <Link to="/" className="sp-buynow">Buy now</Link>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="sp-buyPunks">
                            <div className="sp-buyPunks-heading">
                                <h3 className="sp-buyPunks-heading-title">Buy punk$ tokens now!</h3>
                                <p className="sp-buyPunks-heading-titleDesc">Price increases daily! Cap 2500 per day</p>
                            </div>
                            <div className="sp-buyPunks-content">
                                <div className="sp-form-group">
                                    <label className="sp-form-group-label">Punk$ Coins</label>
                                    <div className="sp-form-group-wrap">
                                        <SVGIcon iconname="coinicon" className="sp-coinIcon"></SVGIcon>
                                        <input type='text' placeholder="0.0" className="sp-form-control"
                                               value={punks} onChange={handlePunksChange}></input>
                                        <div className="sp-id-btns">
                                            <button type="button" className="sp-id-btns-item" onClick={decreasePunks}>
                                                <SVGIcon iconname="minusicon"></SVGIcon>
                                            </button>
                                            <button type="button" className="sp-id-btns-item" onClick={increasePunks}>
                                                <SVGIcon iconname="plusicon"></SVGIcon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="sp-form-group">
                                    <div className="sp-form-group-wrap sp-pr">
                                        <input type='text' className="sp-form-control"
                                               placeholder="Total Price" readOnly value={total} onChange={() => {
                                        }}></input>
                                        <div className="sp-id-btns">1 ETH</div>
                                    </div>
                                </div>
                                <div className="sp-form-group-info">
                                    <p><SVGIcon iconname="infoicon"></SVGIcon>1 Punk$ coin price is {punksEthPrice} ETH.
                                    </p>
                                </div>
                            </div>
                            <Link to="" className="sp-btn w-100" onClick={buyPunks}>Buy Now</Link>
                        </div>

                    </div>
                    <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 ">
                        <div className="sp-mint">
                            <h2 className="sp-mint-title">Buy today, Starpunks prices increases daily with 5% until sold
                                out!</h2>
                            <div className="row">
                                <div
                                    className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12  d-flex align-items-center ">
                                    <div className="sp-form-group">
                                        <input type='text' className="sp-form-control" readOnly value={price}
                                               onChange={handlePriceChange}></input>
                                    </div>
                                </div>
                                <div
                                    className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12  d-flex align-items-center ">
                                    <div className="sp-radio-buttons">
                                        <div className="sp-form-group">
                                            <input type="radio" id="punk$" name="currency"
                                                   checked={currency === 'punk$'} value="punk$"
                                                   onChange={handleCurrencyChange}/>
                                            <label htmlFor="punk$">Punk$</label>
                                        </div>

                                        <div className="sp-form-group">
                                            <input type="radio" id="eth" name="currency" value="eth"
                                                   checked={currency === 'eth'}
                                                   onChange={handleCurrencyChange}/>
                                            <label htmlFor="eth">ETH</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-4 col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12 ">
                                    <Link to="" className="sp-mint-btn" onClick={mintStarPunks}>
                                        <span className="sp-mint-btn-title">Mint Now</span>
                                        Price increases daily, hurry up!
                                    </Link>
                                </div>
                                <div className="col-xxl-6 col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12 ">
                                    <div className="sp-mint-info">
                                        <div className="sp-mint-info-content">
                                            <h3 className="sp-mint-info-content-title">Starpunks left for today!</h3>
                                            <div className="sp-form-group-info">
                                                <p className="sp-mint-info-content-desc"><SVGIcon
                                                    iconname="infoicon"></SVGIcon>starpunk price
                                                    is {price} {currency}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="sp-mint-info-pn">{maxMints}</div>
                                    </div>
                                </div>
                                <div className="col-xxl-6 col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12 ">
                                    <div className="sp-mint-info">
                                        <div className="sp-mint-info-content">
                                            <h3 className="sp-mint-info-content-title">Price available</h3>
                                        </div>
                                        <div className="sp-mint-info-pn">
                                            <FlipCountdown
                                                size='small'
                                                hideYear
                                                hideMonth
                                                endAtZero
                                                titlePosition={'bottom'}
                                                endAt={maxDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="sp-minttext">
                            <p>Starpunks metagame lauches when all 7800 starpunks are sold out!</p>
                            <p>punk$ are used to upgrade your starpunk NFT's traits to increase it's value and generate
                                more daily punk$</p>
                        </div>
                    </div>
                </div>
                <div className="sp-upcoming">
                    <div className="sp-upcoming-titlebox">
                        <div className="sp-title">Starpunks on opensea</div>
                        <div className="sp-slider-control">
                            <div className="sp-pre">
                                <SVGIcon iconname="lefticon"></SVGIcon>
                            </div>
                            <div className="sp-next">
                                <SVGIcon iconname="lefticon"></SVGIcon>
                            </div>
                        </div>
                    </div>
                    <Swiper
                        slidesPerView={4}
                        spaceBetween={20}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Navigation]}
                        className="mySwiper"
                        loop={true}
                        navigation={{
                            prevEl: '.sp-pre', nextEl: '.sp-next',
                        }}
                        breakpoints={{
                            768: {
                                slidesPerView: 4,
                            },
                            320: {
                                slidesPerView: 2,
                            },
                        }}

                    >
                        <SwiperSlide>
                            <div className="sp-upcoming-content">
                                <div className="sp-imageBox">
                                    <img src={punk1} alt="punk"></img>
                                    <Link to="" className="sp-buynow">Buy Now</Link>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="sp-upcoming-content">
                                <div className="sp-imageBox"><img src={punk2} alt="punk"></img><Link to=""
                                                                                                     className="sp-buynow">Buy
                                    Now</Link></div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="sp-upcoming-content">
                                <div className="sp-imageBox"><img src={punk3} alt="punk"></img><Link to=""
                                                                                                     className="sp-buynow">Buy
                                    Now</Link></div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="sp-upcoming-content">
                                <div className="sp-imageBox"><img src={punk4} alt="punk"></img><Link to=""
                                                                                                     className="sp-buynow">Buy
                                    Now</Link></div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="sp-upcoming-content">
                                <div className="sp-imageBox"><img src={punk1} alt="punk"></img><Link to=""
                                                                                                     className="sp-buynow">Buy
                                    Now</Link></div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className="sp-collection">
                    <div className="sp-collection-head">
                        <div className="sp-collection-text">
                            <div className="sp-collection-title">Claim Free punk$</div>
                            <div className="sp-collection-subtitle">You can claim 10free pink$ proving ownership of a
                                cryptopunk and 1 free punk$ for each cryptopunk you own! per day
                            </div>
                        </div>
                        <button className="sp-claimpunks">Claim free punk$</button>
                    </div>
                    <div className="row mt-1">
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="sp-item"><img src={freepunk1} alt="upcoming"></img></div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="sp-item"><img src={freepunk2} alt="upcoming"></img></div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="sp-item"><img src={freepunk3} alt="upcoming"></img></div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="sp-item"><img src={freepunk4} alt="upcoming"></img></div>
                        </div>
                    </div>
                </div>
                <div className="sp-collection">
                    <div className="sp-collection-head">
                        <div className="sp-collection-text">
                            <div className="sp-collection-title">You can claim 10 free punk$ and 1 per day for owning
                                any Bored Ape Yacht Club NFT
                            </div>

                        </div>
                        <button className="sp-claimpunks">Claim free punk$</button>
                    </div>
                    <div className="row mt-1">
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="sp-item"><img src={freepunk5} alt="upcoming"></img></div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="sp-item"><img src={freepunk6} alt="upcoming"></img></div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="sp-item"><img src={freepunk7} alt="upcoming"></img></div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="sp-item"><img src={freepunk8} alt="upcoming"></img></div>
                        </div>
                    </div>
                </div>
                <div className="sp-collection">
                    <div className="sp-collection-head">
                        <div className="sp-collection-text">
                            <div className="sp-collection-title">Claim 10 free punk$ for each cyber kong you own, plus 1
                                per day since star!
                            </div>

                        </div>
                        <button className="sp-claimpunks">Claim free punk$</button>
                    </div>
                    <div className="row mt-1">
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="sp-item"><img src={freepunk9} alt="upcoming"></img></div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="sp-item"><img src={freepunk10} alt="upcoming"></img></div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="sp-item"><img src={freepunk11} alt="upcoming"></img></div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                            <div className="sp-item"><img src={freepunk12} alt="upcoming"></img></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
