import React from 'react';
const ArrowIcon = ({
  style = {},
  fill = '#131217',
  fillOpacity = '0.5  ',
  fillNone = 'none',
  width = '15.429',
  className = '',
  viewBox = '0 0 15.429 9.919'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height="9.919" viewBox={viewBox}>
      <path id="arrow-down" d="M4.96,0a.551.551,0,0,1,.551.551v13l3.467-3.468a.552.552,0,0,1,.78.78L5.35,15.267a.551.551,0,0,1-.78,0L.162,10.859a.552.552,0,0,1,.78-.78l3.467,3.468v-13A.551.551,0,0,1,4.96,0Z" transform="translate(0 9.919) rotate(-90)" fill-rule="evenodd" />
    </svg>
  </>
);
export default ArrowIcon;