import {ethers} from "ethers";
import {Config} from "../config";
import StarPunks from "../artifacts/contracts/StarPunks.sol/StarPunks.json";
import {toast} from "react-hot-toast";

export class StarPunksMethods {

    static async requestAccount() {
        if (typeof window.ethereum !== 'undefined') {
            return await window.ethereum.request({method: 'eth_requestAccounts'});
        }
    }

    static async getPaused() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(Config.StarPunksContract, StarPunks.abi, provider);
            return await contract.paused();
        }
    }

    static async getOwner() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(Config.StarPunksContract, StarPunks.abi, provider);
            return await contract.owner();
        }
    }

    static async getEtherBalance() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(Config.StarPunksContract, StarPunks.abi, provider);
            return await contract.getEtherBalance();
        }
    }


    static async withdrawEtherAmount(amount) {
        if (typeof window.ethereum !== 'undefined') {
            const [account] = await StarPunksMethods.requestAccount();
            if (account) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(Config.StarPunksContract, StarPunks.abi, signer);
                const tx = await contract.withdrawEtherAmount(ethers.utils.parseEther(amount + ''));
                const id = toast.loading("processing...");
                await tx.wait();
                toast.dismiss(id);
            }
        }
    }

    static async updatePaused(value) {
        if (typeof window.ethereum !== 'undefined') {
            const [account] = await StarPunksMethods.requestAccount();
            if (account) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(Config.StarPunksContract, StarPunks.abi, signer);
                if (value === true) {
                    const tx = await contract.pause();
                    const id = toast.loading("processing...");
                    await tx.wait();
                    toast.dismiss(id);
                }
                if (value === false) {
                    const tx = await contract.unpause();
                    const id = toast.loading("processing...");
                    await tx.wait();
                    toast.dismiss(id);
                }
            }
        }
    }


    static async getMintOnlyWithVoucher() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(Config.StarPunksContract, StarPunks.abi, provider);
            return await contract.getMintOnlyWithVoucher();
        }
    }

    static async setMintOnlyWithVoucher(value) {
        if (typeof window.ethereum !== 'undefined') {
            const [account] = await StarPunksMethods.requestAccount();
            if (account) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(Config.StarPunksContract, StarPunks.abi, signer);
                const tx = await contract.setMintOnlyWithVoucher(value);
                const id = toast.loading("processing...");
                await tx.wait();
                toast.dismiss(id);
            }
        }
    }

    static async getPunksAddress() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(Config.StarPunksContract, StarPunks.abi, provider);
            return await contract.getPunksAddress();
        }
    }

    static async setPunksAddress(value) {
        if (typeof window.ethereum !== 'undefined') {
            const [account] = await StarPunksMethods.requestAccount();
            if (account) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(Config.StarPunksContract, StarPunks.abi, signer);
                const tx = await contract.setPunksAddress(value);
                const id = toast.loading("processing...");
                await tx.wait();
                toast.dismiss(id);
            }
        }
    }


    static async getMaxMints() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(Config.StarPunksContract, StarPunks.abi, provider);
            return await contract.getMaxMints();
        }
    }


    static async getMaxDate() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(Config.StarPunksContract, StarPunks.abi, provider);
            return await contract.getMaxDate();
        }
    }


    static async updateMintSettings(maxMints, maxDate) {
        if (typeof window.ethereum !== 'undefined') {
            const [account] = await StarPunksMethods.requestAccount();
            if (account) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(Config.StarPunksContract, StarPunks.abi, signer);
                const tx = await contract.updateMintSettings(maxMints, maxDate);
                const id = toast.loading("processing...");
                await tx.wait();
                toast.dismiss(id);
            }
        }
    }


    static async getEthPrice() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(Config.StarPunksContract, StarPunks.abi, provider);
            return await contract.getEthPrice();
        }
    }

    static async updateEthPrice(price) {
        if (typeof window.ethereum !== 'undefined') {
            const [account] = await StarPunksMethods.requestAccount();
            if (account) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(Config.StarPunksContract, StarPunks.abi, signer);
                const tx = await contract.setEthPrice(price);
                const id = toast.loading("processing...");
                await tx.wait();
                toast.dismiss(id);
            }
        }
    }

    static async mintWithEther(price) {
        if (typeof window.ethereum !== 'undefined') {
            const [account] = await StarPunksMethods.requestAccount();
            if (account) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(Config.StarPunksContract, StarPunks.abi, signer);
                const tx = await contract.mintWithEther({
                    value: ethers.utils.parseEther(price)
                });
                const id = toast.loading("processing...");
                await tx.wait();
                toast.dismiss(id);
            }
        }
    }

    static async mintWithEtherAndVoucher(price, nonce, signature) {
        if (typeof window.ethereum !== 'undefined') {
            const [account] = await StarPunksMethods.requestAccount();
            if (account) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(Config.StarPunksContract, StarPunks.abi, signer);
                const bigPrice = ethers.utils.parseEther(price);
                const tx = await contract.mintWithEtherAndVoucher(
                    bigPrice, nonce, signature,
                    {
                        value: bigPrice
                    }
                );
                const id = toast.loading("processing...");
                await tx.wait();
                toast.dismiss(id);
            }
        }
    }

}