import React, {useEffect, useState} from "react";
import ConnectWallet from "./connect_wallet";
import {PunksMethods} from "../../../lib/PunksMethods";
import ShareVouchers from "./share_vouchers";
import axios from "axios";
import {Config} from "../../../config";
import {Toaster} from "react-hot-toast";

export default function Share() {

    const getSharableVouchers = async (address, abortController) => {
        return await axios.post(Config.API_URL, {
            query: `
                query GetSharableVouchers($address:String!){
                  vouchers_to_share(address:$address){
                    code
                    nonce
                    price
                    price_type
                    add_date
                    settings
                    sharable
                    claimed
                  }
                }
            `,
            variables: {
                address
            }
        }, {
            signal: abortController.signal
        });
    };

    const [connectWallet, setConnectWallet] = useState(true);
    const [vouchers, setVouchers] = useState([]);
    const [address, setAddress] = useState();

    useEffect(() => {
        const abortController = new AbortController();

        PunksMethods.listAccounts().then(async accounts => {
            if (accounts.length > 0) {
                setConnectWallet(false);
                const [account] = accounts;
                setAddress(account);
                // list vouchers here
                const result = await getSharableVouchers(account, abortController);
                setVouchers(result.data.data.vouchers_to_share);

            } else {
                setConnectWallet(true);
            }
        });

        if (typeof window.ethereum !== 'undefined') {
            window.ethereum.on("accountsChanged", async (accounts) => {
                if (accounts.length > 0) {
                    setConnectWallet(false);
                    const [account] = accounts;
                    setAddress(account);

                    // list vouchers here
                    const result = await getSharableVouchers(account, abortController);
                    setVouchers(result.data.data.vouchers_to_share);

                } else {
                    setConnectWallet(true);
                }
            });
        }

        return () => abortController.abort();

    }, []);


    const connectWalletElem = connectWallet ? <ConnectWallet></ConnectWallet> :
        <ShareVouchers vouchers={vouchers} address={address}></ShareVouchers>;

    return (
        <>
            <Toaster toastOptions={{
                // Define default options
                className: '',
                duration: 5000,
                style: {
                    background: '#363636',
                    color: '#fff',
                },
                // Default options for specific types
                success: {
                    duration: 3000,
                    theme: {
                        primary: 'forestgreen',
                        secondary: 'white',
                    },
                },
                error: {
                    duration: 3000,
                    theme: {
                        primary: 'red',
                        secondary: 'white',
                    },
                }
            }}/>
            <div className="sp-share">
                <div className="sp-card sp-p30">
                    <div className="sp-card-header">
                        <div className="sp-card-header-wrap">
                            <h1 className="sp-card-header-title">Share</h1>
                            <p className="sp-card-header-desc">Share this vouchers with your friends</p>
                        </div>
                    </div>
                    <div className="sp-card-body">
                        {connectWalletElem}
                    </div>
                </div>
            </div>
        </>
    );
}