import React from 'react';
const UpcomingGameIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.5  ',
  fillNone = 'none',
  width = '397.013',
  className = '',
  viewBox = '0 0 397.013 298.306'
}) => (
  <>


    <svg xmlns="http://www.w3.org/2000/svg" width={width} height="298.306" viewBox={viewBox}>
      <g id="controller" transform="translate(-0.378 -2.112)" opacity="0.1">
        <path id="Path_9913" data-name="Path 9913" d="M211.87,18.55A13.023,13.023,0,1,1,198.847,5.527,13.023,13.023,0,0,1,211.87,18.55ZM172.8,57.62A13.023,13.023,0,1,0,159.778,44.6,13.023,13.023,0,0,0,172.8,57.62ZM237.917,44.6a13.023,13.023,0,1,1-13.023-13.023A13.023,13.023,0,0,1,237.917,44.6ZM198.847,83.666a13.023,13.023,0,1,0-13.023-13.023A13.023,13.023,0,0,0,198.847,83.666ZM29.546,5.527H55.593V31.573H81.639V57.62H55.593V83.666H29.546V57.62H3.5V31.573H29.546Z" transform="translate(78.186 85.532)" fill={fill} />
        <path id="Path_9914" data-name="Path 9914" d="M69.992,32.012a13.023,13.023,0,0,1,9.22-15.966L129.533,2.554a13.023,13.023,0,0,1,16.149,10.158,448.936,448.936,0,0,1,53.213-3.047,448.378,448.378,0,0,1,55.218,3.282,13.023,13.023,0,0,1,16.2-10.392l50.321,13.492a13.023,13.023,0,0,1,7.97,18.988,58.433,58.433,0,0,1,9.715,7.736c10.627,10.627,20.316,27.349,28.521,46.154a383.692,383.692,0,0,1,20.967,64.23c5.366,22.79,8.856,46.362,9.481,67.877.625,21.254-1.537,41.726-8.543,57.562a36.986,36.986,0,0,1-37.637,21.618c-16.565-1.745-29.042-10.262-39.408-20.134-6.381-6.043-12.919-13.7-19.248-21.045-3.282-3.855-6.512-7.605-9.585-11.018-18.962-20.941-41.6-39.773-83.973-39.773s-65.012,18.831-83.973,39.773c-3.1,3.412-6.3,7.163-9.585,11.018-6.329,7.345-12.867,14.977-19.248,21.045-10.366,9.9-22.843,18.389-39.408,20.134A36.986,36.986,0,0,1,9.043,278.592C2.011,262.756-.125,242.258.474,221.03c.625-21.514,4.167-45.06,9.507-67.877a386.085,386.085,0,0,1,20.967-64.23c8.2-18.805,17.894-35.527,28.495-46.154a60.948,60.948,0,0,1,11.278-8.726,13.127,13.127,0,0,1-.729-2.058Zm53.03,10.731C100.179,47.562,84.76,54.282,77.884,61.184c-7.189,7.189-15.289,20.394-23.051,38.158A358.081,358.081,0,0,0,35.35,159.118a321.7,321.7,0,0,0-8.83,62.667c-.573,19.665,1.615,35.631,6.329,46.258a10.94,10.94,0,0,0,11.1,6.251c8.517-.886,15.888-5.183,24.2-13.075a234.5,234.5,0,0,0,16.018-17.555c3.464-4.063,7.189-8.413,11.46-13.127,21.514-23.78,50.608-48.316,103.274-48.316s81.759,24.536,103.273,48.316c4.272,4.714,8,9.064,11.46,13.127a229.03,229.03,0,0,0,16.018,17.555c8.283,7.892,15.654,12.19,24.2,13.1a10.939,10.939,0,0,0,11.1-6.277c4.688-10.627,6.9-26.567,6.329-46.258a321.769,321.769,0,0,0-8.83-62.667,358.216,358.216,0,0,0-19.483-59.776c-7.762-17.764-15.888-31-23.051-38.158-6.876-6.9-22.3-13.622-45.138-18.441-22.139-4.662-48.889-7.032-75.873-7.032s-53.733,2.37-75.873,7.032Z" transform="translate(0 0)" fill={fill} />
      </g>
    </svg>
  </>
);
export default UpcomingGameIcon;