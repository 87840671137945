import {useEffect, useState} from "react";
import {StarPunksMethods} from "../../../../lib/StarPunksMethods";
import {ethers} from "ethers";
import {toast} from "react-hot-toast";

export default function StarPunksPriceEth() {

    const [price, setPrice] = useState(0);

    useEffect(() => {
        if (typeof window.ethereum === 'undefined') {
            return;
        }
        StarPunksMethods.getEthPrice().then((price) => {
            setPrice(ethers.utils.formatUnits(price, 'ether'));
        }).catch(e => {
            toast.error(e.reason ?? e.message);
        });
    }, []);


    function handleChange(ev) {
        setPrice(ev.target.value);
    }

    async function updatePrice() {
        try {
            const bigPrice = ethers.utils.parseEther(price + '');
            await StarPunksMethods.updateEthPrice(bigPrice);
            toast.success("Price updated");
        } catch (e) {
            toast.error(e.reason ?? e.message);
        }

    }


    return (
        <div className="sp-inputBox">
            <label className="sp-inputlabel">Price in ETH</label>
            <div className="sp-label-intro">Price in Ether for 1 punk$. eg. 1 Punk$ = 0.001 eth</div>
            <div className="sp-inputlabel-inputBox">
                <div className="sp-admin-inputBox">
                    <input value={price} onChange={handleChange} type="text" className="sp-admin-input"/>
                    <div className="sp-value-title">ether</div>
                </div>
                <button onClick={updatePrice} className="sp-admin-btn">Update</button>
            </div>
        </div>
    );
}