import React from 'react';
const MinusIcon = ({
	style = {},
	fill = '#fff',
	fillOpacity = '0.5',
	fillNone = 'none',
	width = '24',
	className = '',
	viewBox = '0 0 24 24'
}) => (
	<>
		<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={width} height={width} viewBox={viewBox}>
			<rect fill={fillNone} width={width} height={width} />
			<g transform="translate(0 -0.999)">
				<path fill={fill} d="M11.9,4C12,4,12,4,12.1,4c0,0,0.1,0,0.1,0.1l10.3,17.5c0.1,0.1,0.1,0.2,0,0.3c0,0,0,0.1-0.1,0.1 c0,0-0.1,0-0.1,0H1.7c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.2,0-0.3L11.9,4C11.8,4.1,11.9,4,11.9,4L11.9,4z M13.5,3.4C13,2.5,12,2.3,11.2,2.7c-0.3,0.1-0.5,0.4-0.6,0.6L0.2,20.8c-0.5,0.8-0.2,1.9,0.6,2.4c0.3,0.2,0.6,0.2,0.9,0.3h20.6 c1,0,1.7-0.8,1.7-1.8c0-0.3-0.1-0.6-0.2-0.9L13.5,3.4z" />
				<path fill={fill} d="M10.5,19c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5 C11.2,20.5,10.5,19.8,10.5,19z M10.6,10c-0.1-0.7,0.5-1.4,1.2-1.5c0.7-0.1,1.4,0.5,1.5,1.2c0,0.1,0,0.2,0,0.3l-0.5,5.3 c0,0.5-0.4,0.8-0.9,0.8c-0.4,0-0.7-0.4-0.8-0.8L10.6,10z" />
			</g>
		</svg>
	</>
);
export default MinusIcon;