import React from "react";
// import Auth from "./auth";
import Main from "./main";
const Pages = () => {
  return (
    <React.Fragment>
      {/* <Auth /> */}
      <Main />
    </React.Fragment>
  );
}
export default Pages;