import {useEffect, useState} from "react";
import Switch from "react-switch";
import {StarPunksMethods} from "../../../../lib/StarPunksMethods";
import {toast} from "react-hot-toast";

export default function StarPunksPaused() {

    const [paused, setPaused] = useState(false);

    useEffect(() => {
        if (typeof window.ethereum === 'undefined') {
            return;
        }
        StarPunksMethods.getPaused().then(paused => {
            if (typeof paused === "boolean") {
                setPaused(paused);
            }
        }).catch(e => {
            toast.error(e.reason ?? e.message);
        });

    }, []);

    async function handleChange(value) {
        try {
            await StarPunksMethods.updatePaused(value);
            setPaused(await StarPunksMethods.getPaused());
            toast.success("StarPunks paused: " + value);
        } catch (e) {
            toast.error(e.reason ?? e.message);
        }
    }

    return (
        <div className="sp-switch">
            <div className="sp-switch-title">Paused:</div>
            <Switch onColor={'#f5c02f'} onChange={handleChange} checked={paused}/>
        </div>
    );
}