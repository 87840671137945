import {useEffect, useState} from "react";
import {ethers} from "ethers";
import {toast} from "react-hot-toast";
import {PunksMethods} from "../../../../lib/PunksMethods";

export default function PunksBalance() {

    const [balance, setBalance] = useState(0);
    const [value, setValue] = useState(0);

    useEffect(() => {
        getBalance();
    }, []);

    function getBalance() {
        PunksMethods.getEtherBalance().then(balance => {
            const n = ethers.utils.formatUnits(balance);
            setBalance(parseFloat(n));
        }).catch(err => {

        });
    }

    function handleChange(ev) {
        setValue(ev.target.value);
    }


    async function withdrawEther() {
        try {
            await PunksMethods.withdrawEtherAmount(value);
            toast.success("Transaction processed");
            getBalance();
        } catch (e) {
            toast.error(e.reason ?? e.message);
        }
    }

    return (
        <div className="sp-inputBox">
            <label className="sp-inputlabel">Balance</label>
            <div className="sp-label-intro">Total amount: {balance} Ether</div>
            <div className="sp-inputlabel-inputBox">
                <div className="sp-admin-inputBox">
                    <input value={value} onChange={handleChange} type="text" className="sp-admin-input"/>
                    <div className="sp-value-title">ether</div>
                </div>
                <button onClick={withdrawEther} className="sp-admin-btn">Withdraw</button>
            </div>
        </div>
    );
}