import React, {useEffect, useState} from "react";
import {Link, NavLink, useNavigate} from "react-router-dom";
import SVGIcon from "./icons";
import User from "../assets/media/user/user.png";
import {PunksMethods} from "../lib/PunksMethods";
import {ethers} from "ethers";
import {useRecoilState} from "recoil";
import {punksState} from "../states/punks.atom";

const Drawermenu = () => {

    let navigate = useNavigate();

    const issidebarOpen = () => {
        document.body.classList.remove('sp-drawermenu-open');
    }

    const [address, setAddress] = useState("");


    const [punks, setPunks] = useRecoilState(punksState);


    useEffect(() => {

        PunksMethods.listAccounts().then(accounts => {
            if (accounts.length > 0) {
                const [account] = accounts;
                setAddress(account);
            } else {
                setAddress("");
            }
        });

        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    const [account] = accounts;
                    setAddress(account);
                } else {
                    setAddress("");
                }
            });
        }

    }, []);

    useEffect(() => {
        if (address !== "") {
            PunksMethods.getBalanceOf(address).then(balance => {
                setPunks(ethers.utils.formatEther(balance));
            }).catch(err => {

            });
        }
    }, [address]);


    function buyPunks() {
        navigate("/buy-punks");
    }

    async function connectWallet() {
        await PunksMethods.requestAccount();
    }

    return (
        <>

            <div className="sp-drawerMenu">
                <div className="sp-menuBox">
                    <div className="sp-sidebar-left">
                        <Link to="/" className="sp-sidebar-left-logo">STARPunks</Link>
                        <div className="sp-sidebar-left-toggel" onClick={() => issidebarOpen()}>
                            <SVGIcon iconname="closeicon"></SVGIcon>
                        </div>
                    </div>
                    <ul className="sp-sidebar-menu">
                        <li className="sp-sidebar-menu-item">
                            <NavLink to="/" exact="true" title="Dashboard" activeclassname="active">
                                <div className="sp-menu-icon">
                                    <SVGIcon iconname="homeicon"></SVGIcon>
                                </div>
                                <span className="sp-sidebar-menu-item-link-text">Home</span>
                            </NavLink>
                        </li>
                        <li className="sp-sidebar-menu-item">
                            <NavLink to="/mint-starpunks" title="Creator" activeclassname="active">
                                <div className="sp-menu-icon">
                                    <SVGIcon iconname="createicon"></SVGIcon>
                                </div>
                                <span className="sp-sidebar-menu-item-link-text">Mint Starpunks</span>
                            </NavLink>
                        </li>
                        <li className="sp-sidebar-menu-item">
                            <NavLink to="/buy-punks" title="Stats" activeclassname="active">
                                <div className="sp-menu-icon">
                                    <SVGIcon iconname="marketicon"></SVGIcon>
                                </div>
                                <span className="sp-sidebar-menu-item-link-text">Buy Punk$</span>
                            </NavLink>
                        </li>
                        <li className="sp-sidebar-menu-item">
                            <NavLink to="/claim-punks" title="Approve" activeclassname="active">
                                <div className="sp-menu-icon">
                                    <SVGIcon iconname="claimpunkicon"></SVGIcon>
                                </div>
                                <span className="sp-sidebar-menu-item-link-text">Claim Punk$</span>
                            </NavLink>
                        </li>
                        <li className="sp-sidebar-menu-item">
                            <NavLink to="/nft-vouchers" title="Approve" activeclassname="active">
                                <div className="sp-menu-icon">
                                    <SVGIcon iconname="claimvouchericon"></SVGIcon>
                                </div>
                                <span className="sp-sidebar-menu-item-link-text">Claim Invitations</span>
                            </NavLink>
                        </li>
                        <li className="sp-sidebar-menu-item">
                            <NavLink to="/roadmap" title="Approve" activeclassname="active">
                                <div className="sp-menu-icon">
                                    <SVGIcon iconname="settingicon"></SVGIcon>
                                </div>
                                <span className="sp-sidebar-menu-item-link-text">Roadmap</span>
                            </NavLink>
                        </li>
                        <li className="sp-sidebar-menu-item">
                            <NavLink to="/comicbook" title="Approve" activeclassname="active">
                                <div className="sp-menu-icon">
                                    <SVGIcon iconname="comikbookicon"></SVGIcon>
                                </div>
                                <span className="sp-sidebar-menu-item-link-text">Comic Book</span>
                            </NavLink>
                        </li>
                        <li className="sp-sidebar-menu-item">
                            <NavLink to="/game" title="Approve" activeclassname="active">
                                <div className="sp-menu-icon">
                                    <SVGIcon iconname="gameicon"></SVGIcon>
                                </div>
                                <span className="sp-sidebar-menu-item-link-text">Game</span>
                            </NavLink>
                        </li>
                        <li className="sp-sidebar-menu-item">
                            <NavLink to="/punkbank" title="Approve" activeclassname="active">
                                <div className="sp-menu-icon">
                                    <SVGIcon iconname="punkbankicon"></SVGIcon>
                                </div>
                                <span className="sp-sidebar-menu-item-link-text">PunkBank</span>
                            </NavLink>
                        </li>
                        <li className="sp-sidebar-menu-item">
                            <NavLink to="/gettshirt" title="Approve" activeclassname="active">
                                <div className="sp-menu-icon">
                                    <SVGIcon iconname="tshirticon"></SVGIcon>
                                </div>
                                <span className="sp-sidebar-menu-item-link-text">Get Tshirts</span>
                            </NavLink>
                        </li>
                        <li className="sp-sidebar-menu-item">
                            <NavLink to="/share" title="share" activeclassname="active">
                                <div className="sp-menu-icon">
                                    <SVGIcon iconname="shareicon"></SVGIcon>
                                </div>
                                <span className="sp-sidebar-menu-item-link-text">Share</span>
                            </NavLink>
                        </li>
                    </ul>
                    <div className="sp-userProfile">
                        {address !== "" && <Link to="/" className="sp-userLink">
                            <div className="sp-userIcon">
                                <img src={User} alt="media"></img>
                            </div>
                            <div className="sp-user">
                                <div className="sp-user-name">Hi, King</div>
                            </div>
                        </Link>}
                        {address !== "" && <div className="userkey">{address}</div>}
                        {address !== "" &&
                            <div className="usercoin-box"><span className="userHavecoin">You have</span> {punks} punk$
                            </div>}
                        {address === "" &&
                            <button className="sp-connect-wallet" onClick={connectWallet}>Connect Wallet</button>}
                        <button className="buymorebtn" onClick={buyPunks}>Buy more punk$</button>
                        <div className="sp-social">
                            <ul className="sp-social-icon">
                                <li className="sp-social-icon-li">
                                    <Link to="" className="sp-social-icon-li-a" title="Twitter"> <SVGIcon
                                        iconname="twittericon"></SVGIcon></Link>
                                </li>
                                <li className="sp-social-icon-li">
                                    <Link to="" className="sp-social-icon-li-a" title="Discord"> <SVGIcon
                                        iconname="discordicon"></SVGIcon></Link>
                                </li>
                                <li className="sp-social-icon-li">
                                    <Link to="" className="sp-social-icon-li-a" title="Telegram"> <SVGIcon
                                        iconname="telegramicon"></SVGIcon></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
}
export default Drawermenu;
