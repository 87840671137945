import React from "react";
import tshirt1 from "./../../../assets/media/tshirt/tshirt1.jpg";
import tshirt2 from "./../../../assets/media/tshirt/tshirt2.jpg";
import tshirt3 from "./../../../assets/media/tshirt/tshirt3.jpg";
import tshirt4 from "./../../../assets/media/tshirt/tshirt4.jpg";
import tshirt7 from "./../../../assets/media/tshirt/tshirt7.jpg";
import tshirt5 from "./../../../assets/media/tshirt/tshirt5.jpg";
import tshirt6 from "./../../../assets/media/tshirt/tshirt6.jpg";
import tshirt8 from "./../../../assets/media/tshirt/tshirt8.jpg";
import SVGIcon from "./../../../components/icons/index";
export default function GetTshirt() {
  return (
    <div className="sp-getTshirt">
      <div className="sp-card sp-p30">
        <div className="sp-card-header">
          <div className="sp-card-header-wrap">
            <h1 className="sp-card-header-title">Get Tshirts</h1>
            <p className="sp-card-header-desc">Hello citizens of StarPunks!</p>
          </div>
        </div>
        <div className="sp-card-body">
          <div className="row">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="sp-card-tshirt">
                <div className="sp-product-image">
                  <img src={tshirt1} alt="tshirt" />
                </div>
                <div className="sp-product-detail">
                  <span className="sp-price">Price</span>
                  <div className="sp-product-price">70 Punk$</div>
                  <div className="sp-sizeBox">
                    <ul className="sp-sizeBox-ul">
                      <li className="sp-selected sp-unselect">S</li>
                      <li className="sp-unselect">M</li>
                      <li className="sp-unselect">L</li>
                    </ul>
                  </div>
                </div>
                <button className="sp-addtocart">
                  <SVGIcon iconname="carticon" />
                  Add to cart</button>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="sp-card-tshirt">
                <div className="sp-product-image">
                  <img src={tshirt2} alt="tshirt" />
                </div>
                <div className="sp-product-detail">
                  <span className="sp-price">Price</span>
                  <div className="sp-product-price">70 Punk$</div>
                  <div className="sp-sizeBox">
                    <ul className="sp-sizeBox-ul">
                      <li className="sp-unselect">S</li>
                      <li className="sp-unselect">M</li>
                      <li className="sp-unselect">L</li>
                    </ul>
                  </div>
                </div>
                <button className="sp-addtocart">
                  <SVGIcon iconname="carticon" />
                  Add to cart</button>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="sp-card-tshirt">
                <div className="sp-product-image">
                  <img src={tshirt3} alt="tshirt" />
                </div>
                <div className="sp-product-detail">
                  <span className="sp-price">Price</span>
                  <div className="sp-product-price">70 Punk$</div>
                  <div className="sp-sizeBox">
                    <ul className="sp-sizeBox-ul">
                      <li className="sp-unselect">S</li>
                      <li className="sp-unselect">M</li>
                      <li className="sp-unselect">L</li>
                    </ul>
                  </div>
                </div>
                <button className="sp-addtocart">
                  <SVGIcon iconname="carticon" />
                  Add to cart</button>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="sp-card-tshirt">
                <div className="sp-product-image">
                  <img src={tshirt7} alt="tshirt" />
                </div>
                <div className="sp-product-detail">
                  <span className="sp-price">Price</span>
                  <div className="sp-product-price">70 Punk$</div>
                  <div className="sp-sizeBox">
                    <ul className="sp-sizeBox-ul">
                      <li className="sp-unselect">S</li>
                      <li className="sp-unselect">M</li>
                      <li className="sp-unselect">L</li>
                    </ul>
                  </div>
                </div>
                <button className="sp-addtocart">
                  <SVGIcon iconname="carticon" />
                  Add to cart</button>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="sp-card-tshirt">
                <div className="sp-product-image">
                  <img src={tshirt4} alt="tshirt" />
                </div>
                <div className="sp-product-detail">
                  <span className="sp-price">Price</span>
                  <div className="sp-product-price">70 Punk$</div>
                  <div className="sp-sizeBox">
                    <ul className="sp-sizeBox-ul">
                      <li className="sp-unselect">S</li>
                      <li className="sp-unselect">M</li>
                      <li className="sp-unselect">L</li>
                    </ul>
                  </div>
                </div>
                <button className="sp-addtocart">
                  <SVGIcon iconname="carticon" />
                  Add to cart</button>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="sp-card-tshirt">
                <div className="sp-product-image">
                  <img src={tshirt5} alt="tshirt" />
                </div>
                <div className="sp-product-detail">
                  <span className="sp-price">Price</span>
                  <div className="sp-product-price">70 Punk$</div>
                  <div className="sp-sizeBox">
                    <ul className="sp-sizeBox-ul">
                      <li className="sp-unselect">S</li>
                      <li className="sp-unselect">M</li>
                      <li className="sp-unselect">L</li>
                    </ul>
                  </div>
                </div>
                <button className="sp-addtocart">
                  <SVGIcon iconname="carticon" />
                  Add to cart</button>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="sp-card-tshirt">
                <div className="sp-product-image">
                  <img src={tshirt6} alt="tshirt" />
                </div>
                <div className="sp-product-detail">
                  <span className="sp-price">Price</span>
                  <div className="sp-product-price">70 Punk$</div>
                  <div className="sp-sizeBox">
                    <ul className="sp-sizeBox-ul">
                      <li className="sp-unselect">S</li>
                      <li className="sp-unselect">M</li>
                      <li className="sp-unselect">L</li>
                    </ul>
                  </div>
                </div>
                <button className="sp-addtocart">
                  <SVGIcon iconname="carticon" />
                  Add to cart</button>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="sp-card-tshirt">
                <div className="sp-product-image">
                  <img src={tshirt8} alt="tshirt" />
                </div>
                <div className="sp-product-detail">
                  <span className="sp-price">Price</span>
                  <div className="sp-product-price">70 Punk$</div>
                  <div className="sp-sizeBox">
                    <ul className="sp-sizeBox-ul">
                      <li className="sp-unselect">S</li>
                      <li className="sp-unselect">M</li>
                      <li className="sp-unselect">L</li>
                    </ul>
                  </div>
                </div>
                <button className="sp-addtocart">
                  <SVGIcon iconname="carticon" />
                  Add to cart</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}