import React from "react";

export default function VoucherItem(props) {
    const {voucher} = props;

    // console.log("Voucher: ", voucher);

    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(voucher.code)
    };

    return <div className="sp-voucherBox-list">
        <div className="sp-voucherBox-code">
            <div className="sp-voucherBox-code-text">{voucher.code}</div>
            <div className="sp-voucherBox-code-checkBox">
                <input type="checkbox" id="html1" disabled defaultChecked={voucher.claimed}></input>
                <label htmlFor="html1"></label>
            </div>
        </div>
        <button className="sp-invitefrd" onClick={copyToClipboard}>Copy</button>
    </div>;
};