import React from 'react';
const closeIcon = ({
  style = {},
  fill = '#f5c02f',
  fillOpacity = '1',
  fillNone = 'none',
  width = '30',
  className = '',
  viewBox = '0 0 30 30'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="Group_2213" data-name="Group 2213" transform="translate(-734 12)">
        <rect id="Rectangle_983" data-name="Rectangle 983" width="30" height="30" transform="translate(734.001 -12)" fill="rgba(255,255,255,0)" opacity="0.3" />
        <g id="Group_2212" data-name="Group 2212" transform="translate(237.026 -566.958) rotate(45)">
          <rect id="Rectangle_981" data-name="Rectangle 981" width="3.429" height="24" rx="1.714" transform="translate(763.286 29)" fill="#fff" />
          <rect id="Rectangle_982" data-name="Rectangle 982" width="3.429" height="24" rx="1.714" transform="translate(777 39.286) rotate(90)" fill="#fff" />
        </g>
      </g>
    </svg>

  </>
);
export default closeIcon;