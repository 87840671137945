import React from 'react';
const CreateIcon = ({
  style = {},
  fill = '#f5c02f',
  fillOpacity = '1',
  fillNone = 'none',
  width = '35',
  className = '',
  viewBox = '0 0 35 35'
}) => (
  <>

    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <g id="Group_1870" data-name="Group 1870" transform="translate(-20 -143)">
        <rect id="Rectangle_115" data-name="Rectangle 115" width={width} height={width} transform="translate(20 143)" fill="rgba(0,0,0,0)" />
        <path id="lightbulb-fill" d="M2,9a9,9,0,1,1,15.26,6.465,2.92,2.92,0,0,0-.679.928l-1.143,2.653a.75.75,0,0,1-.688.453H7.25a.75.75,0,0,1-.69-.453L5.418,16.392a2.946,2.946,0,0,0-.679-.927A8.976,8.976,0,0,1,2,9ZM6.5,21.749A.75.75,0,0,1,7.25,21h7.5a.75.75,0,0,1,0,1.5l-.336.67A1.5,1.5,0,0,1,13.072,24H8.927a1.5,1.5,0,0,1-1.341-.829L7.25,22.5A.75.75,0,0,1,6.5,21.749Z" transform="translate(27 149.001)" fill={fill} />
      </g>
    </svg>
  </>
);
export default CreateIcon;