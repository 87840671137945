import React from "react";
import { Link } from "react-router-dom";
export default function Footer(){
    return(
        <footer className="sp-footer">
        <ul className="sp-footer-ul">
            <li className="sp-footer-ul-li">
                <Link to="" className="sp-footer-ul-li-a">Terms</Link>
            </li>
            <li className="sp-footer-ul-li">
                <Link to="" className="sp-footer-ul-li-a">Privacy</Link>
            </li>
            <li className="sp-footer-ul-li">
                <Link to="" className="sp-footer-ul-li-a">Twitter</Link>
            </li>
            <li className="sp-footer-ul-li">
                <Link to="" className="sp-footer-ul-li-a">Discord</Link>
            </li>
            <li className="sp-footer-ul-li">
                <Link to="" className="sp-footer-ul-li-a">Telegram</Link>
            </li>
            <li className="sp-footer-ul-li">
                <Link to="" className="sp-footer-ul-li-a">About us</Link>
            </li>
            <li className="sp-footer-ul-li">
                <Link to="" className="sp-footer-ul-li-a">Contact</Link>
            </li>
        </ul>
        </footer>
        );
}