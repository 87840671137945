import React from 'react';
const twitterIcon = ({
  style = {},
  fill = '#f5c02f',
  fillOpacity = '1',
  fillNone = 'none',
  width = '35',
  className = '',
  viewBox = '0 0 35 35'
}) => (
  <>   
    <svg id="twitter" xmlns="http://www.w3.org/2000/svg" width="16.8" height="13.651" viewBox="0 0 16.8 13.651">
  <path id="twitter-2" data-name="twitter" d="M5.277,15.65a9.74,9.74,0,0,0,9.808-9.8c0-.147,0-.3-.006-.443A7.019,7.019,0,0,0,16.8,3.619a6.991,6.991,0,0,1-1.983.544,3.466,3.466,0,0,0,1.519-1.908,6.86,6.86,0,0,1-2.191.833A3.45,3.45,0,0,0,8.269,6.232a9.791,9.791,0,0,1-7.105-3.6,3.453,3.453,0,0,0,1.069,4.6A3.489,3.489,0,0,1,.672,6.8v.047A3.452,3.452,0,0,0,3.436,10.23a3.363,3.363,0,0,1-.908.121,3.391,3.391,0,0,1-.645-.06A3.447,3.447,0,0,0,5.1,12.682,6.917,6.917,0,0,1,.819,14.159,6.636,6.636,0,0,1,0,14.112,9.811,9.811,0,0,0,5.277,15.65Z" transform="translate(0 -1.999)" fill="#fff"/>
</svg>

  </>
);
export default twitterIcon;