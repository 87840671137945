import PunksAddress from "./startpunks/PunksAddress";
import StarPunksPriceEth from "./startpunks/StarPunksPriceEth";
import StarPunksMintSettings from "./startpunks/StarPunksMintSettings";
import StarPunksAddress from "./punks/StarPunksAddress";
import PunksPriceEth from "./punks/PunksPriceEth";
import PunksMintingNFTPrice from "./punks/PunksMintingNFTPrice";
import PunksPaused from "./punks/PunksPaused";
import StarPunksPaused from "./startpunks/StarPunksPaused";
import PunksMintSettings from "./punks/PunksMintSettings";
import {Toaster} from "react-hot-toast";
import React, {useEffect, useState} from "react";
import StarPunksMintOnlyVoucher from "./startpunks/StarPunksMintOnlyVoucher";
import {PunksMethods} from "../../../lib/PunksMethods";
import {StarPunksMethods} from "../../../lib/StarPunksMethods";
import StarPunksBalance from "./startpunks/StarPunksBalance";
import PunksBalance from "./punks/PunksBalance";

export default function Admin() {

    const [on, setOn] = useState(false);

    useEffect(() => {
        PunksMethods.listAccounts().then(accounts => {
            if (accounts.length > 0) {
                const [account] = accounts;
                StarPunksMethods.getOwner()
                    .then(owner => {
                        if (account.toLowerCase() === owner.toLowerCase()) {
                            setOn(true);
                        } else {
                            setOn(false);
                        }
                    })
                    .catch(err => {
                        setOn(false);
                    });
            } else {
                setOn(false);
            }
        });

        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    const [account] = accounts;
                    StarPunksMethods.getOwner()
                        .then(owner => {
                            if (account.toLowerCase() === owner.toLowerCase()) {
                                setOn(true);
                            } else {
                                setOn(false);
                            }
                        })
                        .catch(err => {
                            setOn(false);
                        });
                } else {
                    setOn(false);
                }
            });
        }
    }, [])


    return (
        <>
            <Toaster toastOptions={{
                // Define default options
                className: '',
                duration: 5000,
                style: {
                    background: '#363636',
                    color: '#fff',
                },
                // Default options for specific types
                success: {
                    duration: 3000,
                    theme: {
                        primary: 'forestgreen',
                        secondary: 'white',
                    },
                },
                error: {
                    duration: 3000,
                    theme: {
                        primary: 'red',
                        secondary: 'white',
                    },
                }
            }}/>
            {on === true &&
                <div className="sp-admin">
                    <div className="sp-card sp-p30">
                        <a style={{color:"#f5c02f"}} href={"admin-vouchers"}>Vouchers admin</a>
                    </div>
                    <div className="sp-card sp-p30">
                        <div className="sp-card-header">
                            <div className="sp-card-header-wrap">
                                <h1 className="sp-card-header-title">Starpunks</h1>
                            </div>
                        </div>
                        <div className="sp-card-body">
                            <div className="row">
                                <div className="col-xl-6">
                                    <StarPunksMintOnlyVoucher></StarPunksMintOnlyVoucher>
                                </div>
                                <div className="col-xl-6">
                                    <StarPunksPaused></StarPunksPaused>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <StarPunksBalance></StarPunksBalance>
                                </div>
                                <div className="col-xl-6">
                                    <PunksAddress></PunksAddress>
                                </div>
                                <div className="col-xl-6">
                                    <StarPunksPriceEth></StarPunksPriceEth>
                                </div>
                                <div className="col-xl-12">
                                    <StarPunksMintSettings></StarPunksMintSettings>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sp-card sp-p30">
                        <div className="sp-card-header">
                            <div className="sp-card-header-wrap">
                                <h1 className="sp-card-header-title">Punk$</h1>
                            </div>
                        </div>
                        <div className="sp-card-body">
                            <div className="row">
                                <PunksPaused></PunksPaused>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <PunksBalance></PunksBalance>
                                </div>
                                <div className="col-xl-6">
                                    <StarPunksAddress></StarPunksAddress>
                                </div>
                                <div className="col-xl-6">
                                    <PunksPriceEth></PunksPriceEth>
                                </div>
                                <div className="col-xl-6">
                                    <PunksMintingNFTPrice></PunksMintingNFTPrice>
                                </div>
                                <div className="col-xl-12">
                                    <PunksMintSettings></PunksMintSettings>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}