import React from 'react'
import { useTable, usePagination } from 'react-table'
import FirstArrow from '../assets/media/icon/firstArrow.png';

import PrevArrow from '../assets/media/icon/prevArrow.png'
import LastArrow from '../assets/media/icon/lastArrow.png'
import NextArrow from '../assets/media/icon/nextArrow.png'
// import PerfectScrollbar from 'perfect-scrollbar'
export function Table({ columns, data, showPagination = false }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination
  )
  // Render the UI for your table
  return (
    <>
      <div className="sp-blankScroll">

        <div className="sp-tableRow">
          <table {...getTableProps()} className="sp-table display nowrap sp-table-striped sp-dataTable" cellSpacing="0" cellPadding="0">
            <thead className="sp-table-thead">
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} className="sp-table-tr">
                  {headerGroup.headers.map(column => (
                    <th style={column.style} {...column.getHeaderProps()} className="sp-table-th sp-text-dark-50">{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="sp-table-tbody">
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()} className="sp-table-tr">
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()} className="sp-table-td">{cell.render('Cell')}</td>
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

      </div>
      {showPagination &&
        (
          <div className="sp-tablePagination">
            <div className="row sp-dataTableBox">
              <div className="col-xxl-7 col-lg-7 col-md-7 col-sm-12 col-12 sp-dataTablesPager">
                <ul className="sp-pagination">
                  <li className="sp-paginate_button sp-page-item first">
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="page-link"><img src={FirstArrow} alt='' /></button>
                  </li>{' '}
                  <li className="sp-paginate_button sp-page-item previous">
                    <button onClick={() => previousPage()} disabled={!canPreviousPage} className="page-link"><img src={PrevArrow} alt='' /></button>
                  </li>{' '}
                  {pageOptions[pageIndex - 1] !== undefined && (
                    <li className="sp-paginate_button sp-page-item">
                      <button onClick={() => gotoPage(pageIndex - 1)} className="page-link">{pageIndex}</button>
                    </li>
                  )}
                  <li className="sp-paginate_button sp-page-item active">
                    <button onClick={() => gotoPage(pageIndex)} selected className="page-link">{pageIndex + 1}</button>
                  </li>
                  {pageOptions[pageIndex + 1] && (
                    <li className="sp-paginate_button sp-page-item">
                      <button onClick={() => gotoPage(pageIndex + 1)} className="page-link">{pageIndex + 2}</button>
                    </li>
                  )}
                  <li className="sp-paginate_button sp-page-item next">
                    <button onClick={() => nextPage()} disabled={!canNextPage} className="page-link"><img src={NextArrow} alt='' /></button>
                  </li>{' '}
                  <li className="sp-paginate_button sp-page-item last">
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="page-link"><img src={LastArrow} alt='' /></button>
                  </li>{' '}
                </ul>
              </div>
              <div className="col-xxl-5 col-lg-5 col-md-5 col-sm-12 col-12 sp-dataTableInfo">
                <div className="sp-dataTables_length d-flex align-items-center">
                  <label>
                    <select className="sp-custom-select sp-custom-select-sm sp-form-control sp-form-control-sm" value={pageSize} onChange={e => { setPageSize(Number(e.target.value)) }}>
                      {[10, 25, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </select>
                  </label>
                  <span className="sp-dataTables_info">Showing {pageSize * pageIndex + 1} - {pageOptions.length === pageIndex + 1 ? `${data.length}` : `${pageSize * (pageIndex + 1)}`} of {data.length}</span>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}
