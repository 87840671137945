import React from "react";
import moment from "moment";

export default function OtherNFTItem(props) {
    const {item} = props;

    const {name, claim} = item;
    const metadata = JSON.parse(item.metadata);

    const image = metadata.image;
    const traits = metadata.traits ?? metadata.attributes;

    let imageUrl = "";
    const imgRegexp = /^ipfs:\/\/(\S+)$/gm;
    if (image.startsWith("ipfs:")) {
        const matches = image.matchAll(imgRegexp);
        for (const match of matches) {
            if (match && match.length === 2) {
                imageUrl = "https://ipfs.io/ipfs/" + match[1];
            }
        }
    } else {
        imageUrl = image;
    }

    const now = moment()
    const daysAgo = now.diff(claim.date, "days")

    return <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
        <div className="sp-starpunk-card">
            <div className="sp-punkImage">
                <img src={imageUrl} alt="punk"/>
            </div>
            <div className="sp-total-cardpunks">{claim.tokens} Punk$</div>
            <div className="sp-total-cardpunks-title">{name}</div>
            <div className="sp-last-claim">{claim.count === 0 ? "Not claimed" : daysAgo + " days ago"}</div>
            {
                traits && traits.map(({trait_type, key, value}, index) => <div key={index}
                                                                               className="sp-punk-character">
                        <span className="sp-punk-title">{trait_type ?? key}</span>
                        <span className="sp-punk-Name">{value}</span>
                    </div>
                )
            }
        </div>
    </div>;
}