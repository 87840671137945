import React, {useEffect, useState} from "react";
import StarPunksItem from "./StarPunksItem";
import OtherNFTItem from "./OtherNFTItem";
import axios from "axios";
import {Config} from "../../../config";
import NoNFTs from "./NoNFTs";
import {PunksMethods} from "../../../lib/PunksMethods";
import ConnectWallet from "./ConnectWallet";
import {toast, Toaster} from "react-hot-toast";
import {useRecoilState} from "recoil";
import {punksState} from "../../../states/punks.atom";

export default function ClaimPunks() {

    const [starPunks, setStarPunks] = useState([]);
    const [otherNFTs, setOtherNFTs] = useState([]);
    const [connectWallet, setConnectWallet] = useState(true);

    const [total, setTotal] = useState({
        starPunks: 0,
        others: 0
    });


    const [punks, setPunks] = useRecoilState(punksState);

    async function claimTokens() {
        const [account] = await PunksMethods.requestAccount();
        const unclaimedPunks = total.starPunks + total.others;
        if (unclaimedPunks <= 0) {
            toast.error("No Punk$ to claim at the moment");
            return;
        }
        if (account) {
            try {
                const result = await buildClaim(account);
                const {status, nonce, total, signature} = result.data.data.claim;
                if (status === true) {
                    await PunksMethods.claim(total, nonce, signature);
                    toast.success("Claim successfully", {duration: 5000});
                    setPunks((parseFloat(punks) + unclaimedPunks).toFixed(2) + "");
                    setTotal({
                        starPunks: 0,
                        others: 0
                    });
                }
                if (status === false) {
                    toast.error(signature, {duration: 5000});
                }
            } catch (e) {
                toast.error(e.reason ?? e.message, {duration: 5000});
            }
        }
    }


    useEffect(() => {

        function fetchNFTs(address, address1) {
            getNFTS(address, address1).then(result => {
                if (result.status === 200) {
                    setStarPunks(result.data.data.starpunks_list)
                    setOtherNFTs(result.data.data.nft_list);

                    let totalStarPunks = 0;
                    for (const nft of result.data.data.starpunks_list) {
                        totalStarPunks += nft.claim.tokens;
                    }

                    let totalOther = 0;
                    for (const nft of result.data.data.nft_list) {
                        totalOther += nft.claim.tokens;
                    }
                    setTotal(t => {
                        t.starPunks = totalStarPunks;
                        t.others = totalOther;
                        return {
                            starPunks: totalStarPunks,
                            others: totalOther
                        };
                    });
                }
            });
        }

        PunksMethods.listAccounts().then(accounts => {
            if (accounts.length > 0) {
                setConnectWallet(false);
                const [account] = accounts;
                // "0xe6d50Cb6e7423c742f409DB65f19518018B45968"  -- this is just for test( second account)
                // we give punks for 3rd party nfts stored on mainet
                // but on testing we don't use mainet
                fetchNFTs(account, account);
            } else {
                setConnectWallet(true);
            }
        });

        if (typeof window.ethereum !== 'undefined') {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    setConnectWallet(false);
                    const [account] = accounts;
                    // "0xe6d50Cb6e7423c742f409DB65f19518018B45968"  -- this is just for test( second account)
                    // we give punks for 3rd party nfts stored on mainet
                    // but on testing we don't use mainet
                    fetchNFTs(account, account);
                } else {
                    setConnectWallet(true);
                }
            });
        }


    }, []);


    const getNFTS = async (address, address1) => {
        return await axios.post(Config.API_URL, {
            query: `
                query NFTList($filter:NFTFilter!,$address:String!){
                    starpunks_list(address:$address){
                        id
                        name
                        image
                        traits{
                          key
                          value
                        }             
                        claim{
                            date
                            count
                            tokens
                        }
                    }                
                    nft_list(filter:$filter){
                        id
                        name
                        symbol
                        contractAddress
                        ownerAddress
                        uri
                        metadata
                        claim{
                          date
                          count
                          tokens
                        }
                  }
                }
            `,
            variables: {
                address,
                filter: {
                    address: address1
                }
            }
        });
    };

    const buildClaim = async (address) => {
        return await axios.post(Config.API_URL, {
            query: `
                mutation BuildClaim($address:String!){
                  claim(address:$address){
                    status
                    total
                    signature
                    nonce
                  }
                }
            `,
            variables: {
                address
            }
        });
    };


    const myNFTsElem = starPunks.length > 0 ?
        <div className="row">
            {
                starPunks.map((item, index) => <StarPunksItem key={item.id} item={item}></StarPunksItem>)
            }
        </div>
        : <NoNFTs/>;

    const othersNFTsElem = otherNFTs.length > 0 ?
        <div className="sp-other-nft">
            <h1 className="sp-card-header-title">Other NFT's</h1>
            <div className="row">
                {
                    otherNFTs.map((item, index) => <OtherNFTItem key={item.id}
                                                                 item={item}></OtherNFTItem>)
                }
            </div>
        </div>
        : <div></div>;

    const connectWalletElem = connectWallet ? <ConnectWallet></ConnectWallet> : <>
        <div>{myNFTsElem}</div>
        <div style={{height: '30px'}}></div>
        <div>{othersNFTsElem}</div>
    </>;

    return (
        <>
            <Toaster toastOptions={{
                // Define default options
                className: '',
                duration: 5000,
                style: {
                    background: '#363636',
                    color: '#fff',
                },
                // Default options for specific types
                success: {
                    duration: 3000,
                    theme: {
                        primary: 'forestgreen',
                        secondary: 'white',
                    },
                },
                error: {
                    duration: 3000,
                    theme: {
                        primary: 'red',
                        secondary: 'white',
                    },
                }
            }}/>
            <div className="sp-claimpunks">
                <div className="row">
                    <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="sp-card sp-p30">
                            <div className="sp-card-header">
                                <div className="sp-card-header-wrap">
                                    <h1 className="sp-card-header-title">My Starpunk$</h1>
                                </div>
                            </div>
                            <div className="sp-card-body">
                                {connectWalletElem}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="sp-number-box">
                            <div className="sp-starpunks">
                                <div className="sp-starpunks-title">
                                    <span className="sp-total">StarPunks</span>
                                    <span>{starPunks.length}</span>
                                </div>
                                <div className="sp-starpunks-title">
                                    <span className="sp-total">Total Tokens</span>
                                    <span>{total.starPunks}</span>
                                </div>
                            </div>
                            <div className="sp-starpunks">
                                <div className="sp-starpunks-title">
                                    <span className="sp-total">Other NFTs</span>
                                    <span>{otherNFTs.length}</span>
                                </div>
                                <div className="sp-starpunks-title">
                                    <span className="sp-total">Total Tokens</span>
                                    <span>{total.others}</span>
                                </div>
                            </div>
                            <div className="sp-sumofpunks">
                                <span>Total Punk$</span>
                                <span>{total.starPunks + total.others} Punk$</span>
                            </div>
                            {total.starPunks + total.others > 0 &&
                                <div className="sp-btnBox">
                                    <button onClick={claimTokens} className="sp-cliam-btn">Claim</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}