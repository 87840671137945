// export const Config = {
//     API_URL: 'https://punkbank.com/api',
//     PunksContract: '0x9aDac0DCD9c8da20E3a7423c8DC17C9B91856cB7',
//     StarPunksContract: '0x6DebB867aF8E162DD5A80B893371BCABD9C07eA4'
// };

// Localhost
// export const Config = {
//     API_URL: 'http://localhost:4000/api',
//     PunksContract: '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0',
//     StarPunksContract: '0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9'
// };

export const Config = {
    API_URL: process.env.REACT_APP_BACKEND_API_URL,
    PunksContract: process.env.REACT_APP_PUNKS_CONTRACT,
    StarPunksContract: process.env.REACT_APP_STAR_PUNKS_CONTRACT,
};