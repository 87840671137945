import {PunksMethods} from "../../../lib/PunksMethods";
import {toast} from "react-hot-toast";

export default function ConnectWallet() {

    async function connectWallet() {
        if (typeof window.ethereum === 'undefined') {
            toast.error("You need to install MetaMask");
            return;
        }
        await PunksMethods.requestAccount();
    }
    return (
        <div className="row">
            <div className="col-xl-12">
                <div className="sp-share-connect-wallet sp-card sp-p30">
                    <div className="sp-share-connect-wallet-text">You need to connect wallet first.</div>
                    <button className="sp-connectBtn" onClick={connectWallet}>Connect wallet</button>
                </div>
            </div>
        </div>
    );
}