import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import SVGIcon from "../../../components/icons";
import FlipCountdown from '@rumess/react-flip-countdown';
import {BigNumber, ethers} from "ethers";
import StarPunks from "../../../artifacts/contracts/StarPunks.sol/StarPunks.json";
import {Config} from "../../../config";
import {PunksMethods} from "../../../lib/PunksMethods";
import {toast, Toaster} from "react-hot-toast";
import axios from "axios";
import {useRecoilState} from "recoil";
import {punksState} from "../../../states/punks.atom";

export default function BuyPunks() {

    const now = new Date().toISOString();
    const [maxMints, setMaxMints] = useState(0);
    const [maxDate, setMaxDate] = useState(now);
    const [price, setPrice] = useState('0');
    const [punks, setPunks] = useState(10);
    const [total, setTotal] = useState(0);

    const [code, setCode] = useState("");
    const [voucher, setVoucher] = useState();


    const [myPunks, setMyPunks] = useRecoilState(punksState);

    async function getCountDown() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(Config.PunksContract, StarPunks.abi, provider);
            return await contract.getMaxDate();
        }
        return 0;
    }


    useEffect(() => {
        if (typeof window.ethereum === 'undefined') {
            return;
        }

        getCountDown().then(date => {
            if (!date) {
                return;
            }
            const timestamp = BigNumber.from(date).toNumber();
            const newDate = new Date(timestamp * 1000).toISOString();
            setMaxDate(newDate);
        }).catch(err => {
        });
        PunksMethods.getPrice().then(price => {
            if (!price) {
                return;
            }
            const eth = ethers.utils.formatEther(price);
            setPrice(eth);
        }).catch(err => {
        });

        PunksMethods.getMaxMints().then(maxMints => {
            if (!maxMints) {
                return;
            }
            const n = ethers.utils.formatUnits(maxMints);
            setMaxMints(n);
        }).catch(err => {
        });
    }, []);

    useEffect(() => {
        const tokens = parseInt(punks);
        const t = parseFloat(price) * tokens;
        setTotal(t.toFixed(3));
    }, [price, punks]);

    function increasePunks() {
        setPunks(punks => punks + 1);
    }

    function decreasePunks() {
        setPunks(punks => punks - 1 > 0 ? punks - 1 : 0);
    }

    async function buyPunks() {
        if (typeof window.ethereum == 'undefined') {
            toast.error("You need to connect your wallet");
        }
        try {
            if (!voucher) {
                await PunksMethods.buy(punks, total + '');
                setMyPunks((parseFloat(myPunks) + punks).toFixed(2) + '');
                setMaxMints(maxMints - punks);
                toast.success("Transaction successfully");
            } else {
                // console.log("buy with voucher: ", punks, voucher.price, voucher.nonce, voucher.signature, total + '');
                await PunksMethods.buyWithVoucher(punks, voucher.price, voucher.nonce, voucher.signature, total + '');
                setMyPunks((parseFloat(myPunks) + punks).toFixed(2) + '');
                setMaxMints(maxMints - punks);
                toast.success("Transaction successfully", {duration: 5000});
            }
        } catch (e) {
            toast.error(e.reason ?? e.message);
        }
    }

    function handlePunksChange(ev) {
        const tokens = parseInt(ev.target.value);
        if (tokens < 0) {
            setPunks(0);
            return;
        }
        setPunks(tokens);
    }

    function handleTotal(ev) {
        setTotal(ev.target.valueOf)
    }


    const fetchVoucher = async (address, code) => {
        return await axios.post(Config.API_URL, {
            query: `
                query GetVoucherForPunks($address:String!,$code:String!){
                  voucher(address:$address,code:$code){
                    code
                    nonce
                    signature(address:$address)
                    type
                    price
                    price_type
                    add_date
                    settings
                    sharable
                    claimed
                  }
                }
            `,
            variables: {
                address,
                code
            }
        });
    };

    async function handleVoucher() {
        try {
            const [account] = await PunksMethods.requestAccount();
            if (account) {
                if (code && account) {
                    const result = await fetchVoucher(account, code);
                    const voucherData = result.data.data.voucher;
                    if (voucherData && voucherData.type === "Punks" && voucherData.claimed === false) {
                        setVoucher(voucherData);
                        setPrice(voucherData.price);
                    } else {
                        toast.error("Voucher is not valid");
                    }
                } else {
                    toast.error("You need to enter a code first");
                }
            } else {
                toast.error("You need to login in your wallet first");
            }
        } catch (e) {
            toast.error("An error occurred. Make sure you're logged into your wallet.");
        }
    }

    function cancelVoucher() {
        setVoucher(null);
        PunksMethods.getPrice().then(price => {
            setPrice(ethers.utils.formatUnits(price, 18));
        });
        setCode("");
    }

    function handleCode(ev) {
        setCode(ev.target.value);
    }

    return (
        <>
            <Toaster toastOptions={{
                // Define default options
                className: '',
                duration: 5000,
                style: {
                    background: '#363636',
                    color: '#fff',
                },
                // Default options for specific types
                success: {
                    duration: 3000,
                    theme: {
                        primary: 'forestgreen',
                        secondary: 'white',
                    },
                },
                error: {
                    duration: 3000,
                    theme: {
                        primary: 'red',
                        secondary: 'white',
                    },
                }
            }}/>
            <div className="row">
                <div className="col-xl-6 col-xxl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="sp-buyPunks">
                        <div className="sp-buyPunks-heading">
                            <h3 className="sp-buyPunks-heading-title">Buy punk$ tokens now!</h3>
                            <p className="sp-buyPunks-heading-titleDesc">Price increases daily! Cap {maxMints} per
                                day</p>
                        </div>
                        <div className="sp-buyPunks-content">
                            <div className="sp-form-group">
                                <label className="sp-form-group-label">Punk$ Coins</label>
                                <div className="sp-form-group-wrap">
                                    <SVGIcon iconname="coinicon" className="sp-coinIcon"></SVGIcon>
                                    <input type='text' placeholder="0.0" className="sp-form-control"
                                           value={punks} onChange={handlePunksChange}></input>
                                    <div className="sp-id-btns">
                                        <button type="button" className="sp-id-btns-item" onClick={decreasePunks}>
                                            <SVGIcon iconname="minusicon"></SVGIcon>
                                        </button>
                                        <button type="button" className="sp-id-btns-item" onClick={increasePunks}>
                                            <SVGIcon iconname="plusicon"></SVGIcon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="sp-form-group">
                                <div className="sp-form-group-wrap sp-pr">
                                    <input type='text' className="sp-form-control" value={total} onChange={handleTotal}
                                           readOnly placeholder="Total Price"></input>
                                    <div className="sp-id-btns">ETH</div>
                                </div>
                            </div>
                            <div className="sp-form-group-info">
                                <p><SVGIcon iconname="infoicon"></SVGIcon>1 Punk$ coin price is {price} ETH.</p>
                            </div>
                        </div>
                        <div
                            className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  d-flex align-items-center ">
                            <div className="sp-form-group">
                                <label className="sp-form-group-label">Voucher</label>
                                <div className="sp-form-group-wrap">
                                    {!voucher ?
                                        <>
                                            <input type='text' className="sp-form-control"
                                                   value={code} onChange={handleCode}></input>
                                            <button type="button" className="sp-btn"
                                                    onClick={handleVoucher}>Apply
                                            </button>
                                        </>
                                        : <>
                                            <div className="sp-voucher-apply">
                                                <div className="sp-voucher-apply-code sp-form-control">
                                                    <span>Code:</span> {voucher.code}</div>
                                                <div className="sp-voucher-apply-price sp-form-control">
                                                    <span>Price:</span> {voucher.price} {voucher.price_type === "Eth" ? "ETH" : "Punk$"} /
                                                    punk$
                                                </div>
                                                <div className="sp-voucher-apply-cancel"
                                                     onClick={cancelVoucher}>Cancel
                                                </div>
                                            </div>
                                        </>}
                                </div>
                            </div>
                        </div>
                        <Link to="" className="sp-btn" onClick={buyPunks}>Buy Now</Link>
                    </div>
                </div>
                <div className="col-xl-6 col-xxl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="sp-card sp-p30 sp-buyPunks">
                        <div className="sp-buyPunks-text">
                            <p>Starpunks metagame lauches when all 7800 starpunks are sold out!</p>
                            <p>punk$ are used to upgrade your starpunk NFT's traits to increase it's value and generate
                                more daily punk$</p>
                        </div>
                        <div className="sp-buyPunks-price">
                            <p className="sp-buyPunks-price-label">Price available for:</p>
                            <FlipCountdown
                                size='medium'
                                hideYear
                                hideMonth
                                endAtZero
                                titlePosition={'bottom'}
                                endAt={maxDate}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}