import React from "react";
import Hand from "../../../assets/media/icon/hand.svg";
import eth from "../../../assets/media/icon/eth.svg";
import cryptopunk1 from "../../../assets/media/punks/criptopunks1.png";
import cryptopunk2 from "../../../assets/media/punks/criptopunks2.png";
import cryptopunk3 from "../../../assets/media/punks/criptopunks3.png";
import cryptopunk4 from "../../../assets/media/punks/criptopunks4.png";
import bored1 from "../../../assets/media/punks/bored1.png";
import bored2 from "../../../assets/media/punks/bored2.png";
import bored3 from "../../../assets/media/punks/bored3.png";
import bored4 from "../../../assets/media/punks/bored4.png";
import firstNFT from "../../../assets/media/roadmap/firstnft.png";
import NFT from "../../../assets/media/roadmap/NFT.png";
import verified from "../../../assets/media/roadmap/verified.svg";
import verified2 from "../../../assets/media/roadmap/verified2.svg";
import nft2 from "../../../assets/media/roadmap/nft2.png";
import tokenomicsIcon from "../../../assets/media/roadmap/tokenomics-icon.png";
import TokenIcon from "../../../assets/media/roadmap/token-icon.png";
import Vault from "../../../assets/media/roadmap/Vault.png";
import VaultImage from "../../../assets/media/roadmap/Vault-image.png";
import Shield from "../../../assets/media/roadmap/shield.png";
import safe from "../../../assets/media/roadmap/safe.png";
export default function Roadmap() {
  return (
    <>
      <div className="sp-roadmap">
        <div className="sp-card sp-p30">
          <div className="sp-card-header">
            <div className="sp-card-header-wrap">
              <h1 className="sp-card-header-title">Hello world <span><img src={Hand} alt="hand"></img></span></h1>
              <p className="sp-card-header-desc">PunkBank is the first NFT bank in the world,</p>
            </div>
          </div>
          <div className="sp-card-body">
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                <div className="sp-roadmap-section-icon">
                  <img src={firstNFT} alt="icons" />
                </div>
              </div>
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-12">
                <div className="sp-section-text-box">
                  <div className="sp-section-content">
                    <div className="sp-section-content-title">PunkBank is the first NFT bank in the world,</div>
                    <p className="sp-section-content-dis">The StarPunks saga where Cpt Musk the owner of Tezla Robots AI who wants to create a new type of world where his robots control Terra gets stopped by Professor Bezos and his students, the head of the Science laboratory, and former professor of Musk.</p>
                    <p className="sp-section-content-dis">All cyberpunks owners will get 300 PunkCoins per NFT, Bored Ape Yacht owners can claim 100 PunkCoins per NFT</p>
                    <p className="sp-section-content-dis">Cyberpunk and Bored Ape Yacht Club NFTs can claim 1 PNK Token per day</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="sp-syberpunks">
              <div className="sp-card-header">
                <div className="sp-card-header-wrap">
                  <h1 className="sp-card-header-title">Cyberpunk</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="sp-nftBox">
                    <div className="sp-nftBox-image">
                      <img src={cryptopunk1} alt="punks"></img>
                    </div>
                    <div className="sp-nftBox-content">
                      <div className="sp-nftBox-types">
                        <div className="sp-nftBox-types-title">CryptoPunks</div>
                        <div className="sp-nftBox-types-subtitle">CryptoPunk #9986</div>
                      </div>
                      <div className="sp-nftBox-price">
                        <div className="sp-nftBox-price-title">Price</div>
                        <div className="sp-nftBox-price-subtitle"><span className="sp-icon"><img src={eth} alt="eth"></img></span>20</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="sp-nftBox">
                    <div className="sp-nftBox-image">
                      <img src={cryptopunk2} alt="punks"></img>
                    </div>
                    <div className="sp-nftBox-content">
                      <div className="sp-nftBox-types">
                        <div className="sp-nftBox-types-title">CryptoPunks</div>
                        <div className="sp-nftBox-types-subtitle">CryptoPunk #8818</div>
                      </div>
                      <div className="sp-nftBox-price">
                        <div className="sp-nftBox-price-title">Price</div>
                        <div className="sp-nftBox-price-subtitle"><span className="sp-icon"><img src={eth} alt="eth"></img></span>35</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="sp-nftBox">
                    <div className="sp-nftBox-image">
                      <img src={cryptopunk3} alt="punks"></img>
                    </div>
                    <div className="sp-nftBox-content">
                      <div className="sp-nftBox-types">
                        <div className="sp-nftBox-types-title">CryptoPunks</div>
                        <div className="sp-nftBox-types-subtitle">253</div>
                      </div>
                      <div className="sp-nftBox-price">
                        <div className="sp-nftBox-price-title">Price</div>
                        <div className="sp-nftBox-price-subtitle"><span className="sp-icon"><img src={eth} alt="eth"></img></span>15</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="sp-nftBox">
                    <div className="sp-nftBox-image">
                      <img src={cryptopunk4} alt="punks"></img>
                    </div>
                    <div className="sp-nftBox-content">
                      <div className="sp-nftBox-types">
                        <div className="sp-nftBox-types-title">CryptoPunks</div>
                        <div className="sp-nftBox-types-subtitle">1452</div>
                      </div>
                      <div className="sp-nftBox-price">
                        <div className="sp-nftBox-price-title">Price</div>
                        <div className="sp-nftBox-price-subtitle"><span className="sp-icon"><img src={eth} alt="eth"></img></span>11</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sp-boredape">
              <div className="sp-card-header">
                <div className="sp-card-header-wrap">
                  <h1 className="sp-card-header-title">Bored Ape Yacht Club</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="sp-nftBox">
                    <div className="sp-nftBox-image">
                      <img src={bored1} alt="punks"></img>
                    </div>
                    <div className="sp-nftBox-content">
                      <div className="sp-nftBox-types">
                        <div className="sp-nftBox-types-title">Bored Ape Yacht Club</div>
                        <div className="sp-nftBox-types-subtitle">0123</div>
                      </div>
                      <div className="sp-nftBox-price">
                        <div className="sp-nftBox-price-title">Price</div>
                        <div className="sp-nftBox-price-subtitle"><span className="sp-icon"><img src={eth} alt="eth"></img></span>20</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="sp-nftBox">
                    <div className="sp-nftBox-image">
                      <img src={bored2} alt="punks"></img>
                    </div>
                    <div className="sp-nftBox-content">
                      <div className="sp-nftBox-types">
                        <div className="sp-nftBox-types-title">Bored Ape Yacht Club</div>
                        <div className="sp-nftBox-types-subtitle">4525</div>
                      </div>
                      <div className="sp-nftBox-price">
                        <div className="sp-nftBox-price-title">Price</div>
                        <div className="sp-nftBox-price-subtitle"><span className="sp-icon"><img src={eth} alt="eth"></img></span>35</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="sp-nftBox">
                    <div className="sp-nftBox-image">
                      <img src={bored3} alt="punks"></img>
                    </div>
                    <div className="sp-nftBox-content">
                      <div className="sp-nftBox-types">
                        <div className="sp-nftBox-types-title">Bored Ape Yacht Club</div>
                        <div className="sp-nftBox-types-subtitle">253</div>
                      </div>
                      <div className="sp-nftBox-price">
                        <div className="sp-nftBox-price-title">Price</div>
                        <div className="sp-nftBox-price-subtitle"><span className="sp-icon"><img src={eth} alt="eth"></img></span>15</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="sp-nftBox">
                    <div className="sp-nftBox-image">
                      <img src={bored4} alt="punks"></img>
                    </div>
                    <div className="sp-nftBox-content">
                      <div className="sp-nftBox-types">
                        <div className="sp-nftBox-types-title">Bored Ape Yacht Club</div>
                        <div className="sp-nftBox-types-subtitle">1452</div>
                      </div>
                      <div className="sp-nftBox-price">
                        <div className="sp-nftBox-price-title">Price</div>
                        <div className="sp-nftBox-price-subtitle"><span className="sp-icon"><img src={eth} alt="eth"></img></span>11</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row sp-reverse">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-12">
                <div className="sp-section-text-box">
                  <div className="sp-section-content">
                    <div className="sp-section-content-title"><span><img src={verified} alt="verified" /></span>What are the verified NFT collections supported by
                      PUNKBANK ?</div>
                    <p className="sp-section-content-dis">The punk coins, in short, punks, will cost 1 dollar at launchin stage 1, letting room for the early community to earn while the project takes off.</p>
                    <p className="sp-section-content-dis">The price will increase 5% per day for 30 days, selling a decreasing number of tokens each day until halt.</p>

                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                <div className="sp-roadmap-section-icon">
                  <img src={NFT} alt="icons" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                <div className="sp-roadmap-section-icon">
                  <img src={nft2} alt="icons" />
                </div>
              </div>
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-12">
                <div className="sp-section-text-box">
                  <div className="sp-section-content">
                    <div className="sp-section-content-title"><span><img src={verified2} alt="verified" /></span>What are the verified NFT collections supported by PUNKBANK ?</div>
                    <p className="sp-section-content-dis"> The tokens will be used to mint StarPunks NFTs 10024 total availability</p>
                    <p className="sp-section-content-dis">The tokens burn at StarPunks.com NFT purchase.</p>
                    <p className="sp-section-content-dis">StarPunks NFTs each earn 1 PunkCoin per day.</p>
                    <p className="sp-section-content-dis">StarPunks the mobile game will be launched when all StarPunks NFTs were sold</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row sp-reverse">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-12">
                <div className="sp-section-text-box">
                  <div className="sp-section-content">
                    <div className="sp-section-content-title"><span><img src={tokenomicsIcon} alt="verified" /></span>Tokenomics</div>
                    <div className="sp-card-subtitle">Why will the tokens <span> 5x </span> or more?</div>
                    <p className="sp-section-content-dis">The tokens are built for the NFT community, the tokenomic architecture is deflationary, in order to make the Punk coin value grow, and protect it's users.</p>
                    <p className="sp-section-content-dis">The tokens availability will decrease with each selling stage no matter if there are more buyers wanting them, so the price will increase.</p>
                    <p className="sp-section-content-dis">Each time you spend the tokens in the StarPunks ecosystem or other NFT partners the tokens burn, so availability decreases, and prices increases.</p>
                    <p className="sp-section-content-dis">Predictive slow release of the coins, in order for a decreasing 50% of the coins to be minted on Punkbank, and the other increasing need of coins of over 50% to happend when buyers buy form actual holders, to stimulate a stable value increase for the PNK token, to make sure it increases in value for it's holders.</p>
                    <p className="sp-section-content-dis">Pending concept - Metaverse integration</p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                <div className="sp-roadmap-section-icon">
                  <img src={TokenIcon} alt="icons" />
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                <div className="sp-roadmap-section-icon">
                  <img src={VaultImage} alt="icons" />
                </div>
              </div>
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-12">
                <div className="sp-section-text-box">
                  <div className="sp-section-content">
                    <div className="sp-section-content-title"><span><img src={Vault} alt="verified" /></span>PunkBank Vault</div>
                    <p className="sp-section-content-dis">you can swap any verified collection NFT with PunkCoins at any given time making all verified ethereum NFT collections in the world, instantly liquid limited by the liquidity pool.</p>
                    <p className="sp-section-content-dis">You can Stake any verified collection NFT, and earn punkCoins. Like CryptoPunks, Bored Ape Yacht Club, and many more.</p>
                    <p className="sp-section-content-dis">Pending concept - All Swaped NFTs will be available to buy in a DEFI marketplace using punkCoins PNK.</p>
                    <p className="sp-section-content-dis">We call it PNK mining, by simply holding the PNK tokens in your wallet and not sell it, you will be rewarded with extra PNK tokens using an algorithm that takes into consideration the time you held the tokens without selling, and the extra tokens you will be able to purchase.</p>

                  </div>
                </div>
              </div>
            </div>
            <div className="row sp-reverse">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-12">
                <div className="sp-section-text-box">
                  <div className="sp-section-content">
                    <p className="sp-section-content-dis">So the tokens will work for you, and your earnings will accelerate the more you are able to purchase from other users, and the more you hold on to them without selling, considering the token supply available to purchase in Punkbank, will go to zero and the only sources you will be able to get the tokens will be by swaping ETH with other users.</p>
                    <p className="sp-section-content-dis">At the moment PNK tokens reach a market cap of 100,000,000,000 USD A new coin will be released and all holders will have access to it.</p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                <div className="sp-roadmap-section-icon">
                  <img src={Shield} alt="icons" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                <div className="sp-roadmap-section-icon">
                  <img src={safe} alt="icons" />
                </div>
              </div>
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-12">
                <div className="sp-section-text-box">
                  <div className="sp-section-content">
                    <p className="sp-section-content-dis">The new coin will be called FXC Foxcoin and will be listed on Foxcoin.com it will run on Proof of stake, and have a stable economic value. Most importantly the architecture of the blockchain will allow ultra fast transactions with close to 1 cent in gas fees per transaction, and offer API for ecommerce platforms around the world.</p>
                    <p className="sp-section-content-dis">We believe cryptocurrency will be the next world currency, we also believe that Bitcoin will not be the main currency, due to its economic instability, lack of speed in transactions, and huge gas fees. Of course not to mention the mining system is not eco friendly using too much electricity and GPU power to process the hashrate, that power could be used elsewhere to provide more good for the community. The architecture of bitcoin even if decentralised, still has a single point of failure, the mining pools have the ability to bruteforce transactions on the blockchain if they unite their powers, and this problem should be addressed and fixed in the crypto currency that will become domninant in the future, we might know how.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}