import React from 'react';
const tshirtIcon = ({
  style = {},
  fill = '#f5c02f',
  fillOpacity = '1',
  fillNone = 'none',
  width = '35',
  className = '',
  viewBox = '0 0 35 35'
}) => (
  <>

    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_998" data-name="Rectangle 998" width="24" height="20.878" fill={fill} />
        </clipPath>
      </defs>
      <g id="Group_2217" data-name="Group 2217" transform="translate(-20 -323)">
        <rect id="Rectangle_118" data-name="Rectangle 118" width="35" height="35" transform="translate(20 323)" fill="rgba(0,0,0,0)" opacity="0" />
        <g id="Group_2314" data-name="Group 2314" transform="translate(26 330)">
          <g id="Group_2313" data-name="Group 2313" clipPath="url(#clip-path)">
            <path id="Path_9907" data-name="Path 9907" d="M24,3.257v.234c-.038.084-.074.17-.116.252q-.936,1.848-1.873,3.7a.582.582,0,0,1-.821.352c-.6-.176-1.2-.356-1.794-.534-.085-.025-.172-.044-.284-.073V7.52q0,6.283,0,12.566a1.979,1.979,0,0,1-.029.349.476.476,0,0,1-.416.413,1.923,1.923,0,0,1-.325.028q-6.342,0-12.683,0a1.831,1.831,0,0,1-.348-.031.47.47,0,0,1-.4-.4,1.977,1.977,0,0,1-.03-.348q0-6.295,0-12.589V7.185c-.114.029-.2.047-.278.071l-1.772.526c-.454.134-.646.055-.856-.359Q1.046,5.6.122,3.767c-.232-.46-.141-.723.327-.939,1.012-.469,2.039-.909,3.035-1.411A16.494,16.494,0,0,1,7.669.032c.5-.1.716.026.85.509a3.58,3.58,0,0,0,3.46,2.679,3.584,3.584,0,0,0,3.5-2.692c.13-.46.347-.594.814-.5.069.014.138.025.206.044a27.066,27.066,0,0,1,2.8.816c1.423.576,2.8,1.272,4.2,1.912A.927.927,0,0,1,24,3.257" transform="translate(0 0.001)" fill="#f5c02f" />
            <path id="Path_9908" data-name="Path 9908" d="M95.932,3.766a9.858,9.858,0,0,0,4.454,0,2.285,2.285,0,0,1-2.107,1.61,2.328,2.328,0,0,1-2.347-1.613" transform="translate(-86.171 -3.383)" fill="#f5c02f" />
          </g>
        </g>
      </g>
    </svg>


  </>
);
export default tshirtIcon;