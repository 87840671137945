import React from 'react';
const gameIcon = ({
  style = {},
  fill = '#f5c02f',
  fillOpacity = '1',
  fillNone = 'none',
  width = '35',
  className = '',
  viewBox = '0 0 35 35'
}) => (
  <>   
<svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
  <g id="Group_2217" data-name="Group 2217" transform="translate(-20 -323)">
    <rect id="Rectangle_118" data-name="Rectangle 118" width="35" height="35" transform="translate(20 323)" fill="rgba(0,0,0,0)" opacity="0"/>
    <g id="controller" transform="translate(25.622 328.888)">
      <path id="Path_9905" data-name="Path 9905" d="M16.1,6.314a.787.787,0,1,1-.787-.787A.787.787,0,0,1,16.1,6.314ZM13.734,8.676a.787.787,0,1,0-.787-.787A.787.787,0,0,0,13.734,8.676Zm3.936-.787a.787.787,0,1,1-.787-.787A.787.787,0,0,1,17.671,7.889Zm-2.362,2.362a.787.787,0,1,0-.787-.787A.787.787,0,0,0,15.309,10.251ZM5.075,5.527H6.649V7.1H8.224V8.676H6.649v1.575H5.075V8.676H3.5V7.1H5.075Z" transform="translate(1.794 1.962)" fill="#f5c02f"/>
      <path id="Path_9906" data-name="Path 9906" d="M4.587,3.92a.787.787,0,0,1,.557-.965l3.042-.816a.787.787,0,0,1,.976.614,28.179,28.179,0,0,1,6.555.014.787.787,0,0,1,.979-.628l3.042.816A.787.787,0,0,1,20.22,4.1a3.532,3.532,0,0,1,.587.468,9.342,9.342,0,0,1,1.724,2.79A23.2,23.2,0,0,1,23.8,11.243a20.99,20.99,0,0,1,.573,4.1,8.249,8.249,0,0,1-.516,3.48,2.236,2.236,0,0,1-2.275,1.307A4.034,4.034,0,0,1,19.2,18.915c-.386-.365-.781-.828-1.164-1.272-.2-.233-.394-.46-.579-.666a6.285,6.285,0,0,0-5.076-2.4,6.285,6.285,0,0,0-5.076,2.4c-.187.206-.381.433-.579.666-.383.444-.778.905-1.164,1.272a4.026,4.026,0,0,1-2.382,1.217A2.236,2.236,0,0,1,.9,18.826a8.252,8.252,0,0,1-.518-3.48,21.132,21.132,0,0,1,.575-4.1A23.339,23.339,0,0,1,2.226,7.36,9.372,9.372,0,0,1,3.949,4.57a3.684,3.684,0,0,1,.682-.527.794.794,0,0,1-.044-.124Zm3.206.649A5.833,5.833,0,0,0,5.064,5.683,7.906,7.906,0,0,0,3.67,7.99,21.647,21.647,0,0,0,2.492,11.6a19.447,19.447,0,0,0-.534,3.788,6.753,6.753,0,0,0,.383,2.8.661.661,0,0,0,.671.378,2.488,2.488,0,0,0,1.463-.79,14.176,14.176,0,0,0,.968-1.061c.209-.246.435-.509.693-.794A7.838,7.838,0,0,1,12.379,13a7.838,7.838,0,0,1,6.243,2.921c.258.285.483.548.693.794a13.845,13.845,0,0,0,.968,1.061,2.491,2.491,0,0,0,1.463.792.661.661,0,0,0,.671-.379,6.775,6.775,0,0,0,.383-2.8,19.452,19.452,0,0,0-.534-3.788A21.655,21.655,0,0,0,21.088,7.99a7.918,7.918,0,0,0-1.393-2.307,5.833,5.833,0,0,0-2.729-1.115,23,23,0,0,0-4.587-.425,23,23,0,0,0-4.587.425Z" transform="translate(0)" fill="#f5c02f"/>
    </g>
  </g>
</svg>

  </>
);
export default gameIcon;