import React from 'react';
const telegramIcon = ({
  style = {},
  fill = '#f5c02f',
  fillOpacity = '1',
  fillNone = 'none',
  width = '35',
  className = '',
  viewBox = '0 0 35 35'
}) => (
  <>
   <svg xmlns="http://www.w3.org/2000/svg" width="16.8" height="16.8" viewBox="0 0 16.8 16.8">
  <path id="telegram" d="M16.8,8.4A8.4,8.4,0,1,1,8.4,0,8.4,8.4,0,0,1,16.8,8.4ZM8.7,6.2q-1.225.51-4.9,2.11c-.4.158-.606.313-.625.464-.032.255.289.356.725.494l.184.058a5.966,5.966,0,0,0,1.305.309A1.583,1.583,0,0,0,6.3,9.3Q9.734,6.983,9.845,6.958a.19.19,0,0,1,.174.017.169.169,0,0,1,.039.148A23.723,23.723,0,0,1,8.12,9.031c-.2.189-.346.322-.376.353q-.1.1-.2.2c-.4.384-.7.672.016,1.142.343.227.618.413.893.6.3.2.6.406.983.66.1.063.192.131.284.2a1.6,1.6,0,0,0,1.047.435c.225-.021.457-.231.574-.861.278-1.488.825-4.71.951-6.039a1.5,1.5,0,0,0-.014-.331.354.354,0,0,0-.12-.228.552.552,0,0,0-.325-.1A13.039,13.039,0,0,0,8.7,6.2Z" fill="#fff"/>
</svg>

  </>
);
export default telegramIcon;