import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./home";
import Drawermenu from "../../components/drawermenu";
import Header from '../../components/header'
import Roadmap from "./roadmap";
import MintStartPunks from "./mint-starpunks";
import BuyPunks from "./buy-punks";
import ClaimPunks from "./claim-punks";
import Footer from "../../components/footer";
import Games from "./games";
import Comicbook from "./comicbook";
import PunkBank from "./punkbank";
import GetTshirt from "./gettshirt";
import Admin from "./admin";
import Share from "./share";
import AdminVoucher from "./admin-vouchers";
import ShareAffiliate from "./share-affiliate";
import NftVouchers from "./nft-vouchers";
const Main = () => {
  return (
    <>
      <Drawermenu />
      <div className="sp-main">
        <Header />
        <div className="container">
          <Routes >
            <Route path='/' element={<Home />} />
            <Route path='/mint-starpunks' element={<MintStartPunks />} />
            <Route path='/buy-punks' element={<BuyPunks />} />
            <Route path='/roadmap' element={<Roadmap />} />
            <Route path='/claim-punks' element={<ClaimPunks />} />
            <Route path='/game' element={<Games />} />
            <Route path='/comicbook' element={<Comicbook />} />
            <Route path="/punkbank" element={<PunkBank />} />
            <Route path="/gettshirt" element={<GetTshirt />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/share" element={<Share />} />
            <Route path="/admin-vouchers" element={<AdminVoucher />} />
            <Route path="/share-affiliate" element={<ShareAffiliate />} />
            <Route path="/nft-vouchers" element={<NftVouchers />} />
          </Routes >
        </div>
        <Footer />
      </div>
    </>
  );
}
export default Main;