import {ethers} from "ethers";
import {Config} from "../config";
import Punk$ from "../artifacts/contracts/Punk$.sol/Punk$.json";
import {toast} from "react-hot-toast";

export class PunksMethods {

    static async requestAccount() {
        if (typeof window.ethereum !== 'undefined') {
            return await window.ethereum.request({method: 'eth_requestAccounts'});
        }
    }

    static listAccounts = async () => {
        if (typeof window.ethereum === 'undefined') {
            return [];
        }
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const accounts = await provider.listAccounts();
        return accounts;
    }


    static async getPaused() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(Config.PunksContract, Punk$.abi, provider);
            return await contract.paused();
        }
    }

    static async updatePaused(value) {
        if (typeof window.ethereum !== 'undefined') {
            const [account] = await PunksMethods.requestAccount();
            if (account) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(Config.PunksContract, Punk$.abi, signer);
                if (value === true) {
                    const tx = await contract.pause();
                    const id = toast.loading("processing...");
                    await tx.wait();
                    toast.dismiss(id);
                }
                if (value === false) {
                    const tx = await contract.unpause();
                    const id = toast.loading("processing...");
                    await tx.wait();
                    toast.dismiss(id);
                }
            }
        }
    }


    static async getEtherBalance() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(Config.PunksContract, Punk$.abi, provider);
            return await contract.getEtherBalance();
        }
    }


    static async withdrawEtherAmount(amount) {
        if (typeof window.ethereum !== 'undefined') {
            const [account] = await PunksMethods.requestAccount();
            if (account) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(Config.PunksContract, Punk$.abi, signer);
                const tx = await contract.withdrawEtherAmount(ethers.utils.parseEther(amount + ''));
                const id = toast.loading("processing...");
                await tx.wait();
                toast.dismiss(id);
            }
        }
    }

    static async getStarPunksAddress() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(Config.PunksContract, Punk$.abi, provider);
            return await contract.getStarPunksAddress();
        }
    }

    static async setStarPunksAddress(value) {
        if (typeof window.ethereum !== 'undefined') {
            const [account] = await PunksMethods.requestAccount();
            if (account) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(Config.PunksContract, Punk$.abi, signer);
                const tx = await contract.setStarPunksAddress(value);
                const id = toast.loading("processing...");
                await tx.wait();
                toast.dismiss(id);
            }
        }
    }

    /**
     * Price in ethereum
     * @returns {Promise<*>}
     */
    static async getPrice() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(Config.PunksContract, Punk$.abi, provider);
            return await contract.getPrice();
        }
    }


    static async setPrice(price) {
        if (typeof window.ethereum !== 'undefined') {
            const [account] = await PunksMethods.requestAccount();
            if (account) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(Config.PunksContract, Punk$.abi, signer);
                const tx = await contract.setPrice(price);
                const id = toast.loading("processing...");
                await tx.wait();
                toast.dismiss(id);
            }
        }
    }

    // How much Punk$ to mint an NFT(StarPunk)
    static async getMintPrice() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(Config.PunksContract, Punk$.abi, provider);
            return await contract.getMintPrice();
        }
    }

    static async setMintPrice(price) {
        if (typeof window.ethereum !== 'undefined') {
            const [account] = await PunksMethods.requestAccount();
            if (account) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(Config.PunksContract, Punk$.abi, signer);
                const tx = await contract.setMintPrice(price);
                const id = toast.loading("processing...");
                await tx.wait();
                toast.dismiss(id);
            }
        }
    }

    static async getMaxDate() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(Config.PunksContract, Punk$.abi, provider);
            return await contract.getMaxDate();
        }
    }

    static async getMaxMints() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(Config.PunksContract, Punk$.abi, provider);
            return await contract.getMaxMints();
        }
    }

    static async updateMintSettings(limit, date) {
        if (typeof window.ethereum !== 'undefined') {
            const [account] = await PunksMethods.requestAccount();
            if (account) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(Config.PunksContract, Punk$.abi, signer);
                const tx = await contract.updateMintSettings(limit, date);
                const id = toast.loading("processing...");
                await tx.wait();
                toast.dismiss(id);
            }
        }
    }

    static async getBalanceOf(address) {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(Config.PunksContract, Punk$.abi, provider);
            return await contract.balanceOf(address);
        }
    }

    static async claim(tokens, nonce, signature) {
        if (typeof window.ethereum !== 'undefined') {
            const [account] = await PunksMethods.requestAccount();
            if (account) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(Config.PunksContract, Punk$.abi, signer);
                const tx = await contract.claim(tokens, nonce, signature);
                const id = toast.loading("processing...");
                await tx.wait();
                toast.dismiss(id);
            }
        }
    }

    static async mintNFT() {
        if (typeof window.ethereum !== 'undefined') {
            const [account] = await PunksMethods.requestAccount();
            if (account) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(Config.PunksContract, Punk$.abi, signer);
                const tx = await contract.mintNFT();
                const id = toast.loading("processing...");
                await tx.wait();
                toast.dismiss(id);
            }
        }
    }

    static async mintNFTVoucher(price, nonce, signature) {
        if (typeof window.ethereum !== 'undefined') {
            const [account] = await PunksMethods.requestAccount();
            if (account) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(Config.PunksContract, Punk$.abi, signer);
                const bigPrice = ethers.utils.parseEther(price);
                const tx = await contract.mintNFTVoucher(bigPrice, nonce, signature);
                const id = toast.loading("processing...");
                await tx.wait();
                toast.dismiss(id);
            }
        }
    }


    static async buy(amount, price) {
        if (typeof window.ethereum !== 'undefined') {
            const [account] = await PunksMethods.requestAccount();
            if (account) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(Config.PunksContract, Punk$.abi, signer);
                const tx = await contract.buy(amount, {
                    value: ethers.utils.parseEther(price)
                });
                const id = toast.loading("processing...");
                await tx.wait();
                toast.dismiss(id);
            }
        }
    }

    static async buyWithVoucher(amount, unitPrice, nonce, signature, price) {
        if (typeof window.ethereum !== 'undefined') {
            const [account] = await PunksMethods.requestAccount();
            if (account) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(Config.PunksContract, Punk$.abi, signer);

                const bigUnitPrice = ethers.utils.parseEther(unitPrice + '');
                const tx = await contract.buyWithVoucher(amount, bigUnitPrice, nonce, signature, {
                    value: ethers.utils.parseEther(price)
                });
                const id = toast.loading("processing...");
                await tx.wait();
                toast.dismiss(id);
            }
        }
    }
}