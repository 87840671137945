import React from 'react';
const claimPunkIcon = ({
  style = {},
  fill = '#f5c02f',
  fillOpacity = '1',
  fillNone = 'none',
  width = '35',
  className = '',
  viewBox = '0 0 35 35'
}) => (
  <>    
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
  <g id="Group_2217" data-name="Group 2217" transform="translate(-20 -323)">
    <rect id="Rectangle_118" data-name="Rectangle 118" width="35" height="35" transform="translate(20 323)" fill="rgba(0,0,0,0)" opacity="0"/>
    <g id="Group_2216" data-name="Group 2216" transform="translate(2)">
      <g id="Ellipse_98" data-name="Ellipse 98" transform="translate(24 329)" fill="rgba(255,255,255,0)" stroke="#f5c02f" strokeWidth="1.5">
        <circle cx="12" cy="12" r="12" stroke="none"/>
        <circle cx="12" cy="12" r="11.25" fill="none"/>
      </g>
      <text id="_" data-name="$" transform="translate(32 346)" fill="#f5c02f" fontSize="14" fontFamily="SegoeUI-Bold, Segoe UI" fontWeight="700"><tspan x="0" y="0">$</tspan></text>
    </g>
  </g>
</svg>


  </>
);
export default claimPunkIcon;