import React from 'react';
const CartIcon = ({
  style = {},
  fill = '#131217',
  fillOpacity = '0.5  ',
  fillNone = 'none',
  width = '15.867',
  className = '',
  viewBox = '0 0 15.867 19.17'
}) => (
  <>

    <svg xmlns="http://www.w3.org/2000/svg" width={width} height="19.17" viewBox={viewBox}>
      <path id="shopping-bag" d="M33.807,19,32.819,5c-.032-.358-.633,0-.988,0H29.856V4a4.214,4.214,0,0,0-3.951-4,4.214,4.214,0,0,0-3.951,4V5H19.979c-.355,0-.956-.358-.988,0L18,19c-.017.194-.13-.144,0,0s.8,0,.988,0H32.819c.379,0,.988.383.988,0C33.806,18.961,33.813,19.037,33.807,19ZM23.93,4c0-1.274.717-3,1.975-3s1.975,1.726,1.975,3V5H23.93ZM18.992,18,20.967,6h.988V7a1.324,1.324,0,0,0,.988,1,1.324,1.324,0,0,0,.988-1V6h3.951V7a1.324,1.324,0,0,0,.988,1,1.324,1.324,0,0,0,.988-1V6h.988l1.975,12Z" transform="translate(-17.942)" fill={fill} />
    </svg>
  </>
);
export default CartIcon;