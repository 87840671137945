import React from 'react';
const MenuIcon = ({
  style = {},
  fill = '#c8c8d5',
  fillOpacity = '1',
  fillNone = 'none',
  width = '20',
  className = '',
  viewBox = '0 0 20 16.364'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <path id="list_1_" data-name="list (1)" d="M2.5,18.955a.909.909,0,0,1,.909-.909H21.591a.909.909,0,1,1,0,1.818H3.409A.909.909,0,0,1,2.5,18.955Zm0-7.273a.909.909,0,0,1,.909-.909H21.591a.909.909,0,1,1,0,1.818H3.409A.909.909,0,0,1,2.5,11.682Zm0-7.273A.909.909,0,0,1,3.409,3.5H21.591a.909.909,0,1,1,0,1.818H3.409A.909.909,0,0,1,2.5,4.409Z" transform="translate(-2.5 -3.5)" fill={fill} fillRule="evenodd" />
    </svg>
  </>
);
export default MenuIcon;