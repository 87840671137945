import React from 'react';
const LeftIcon = ({
  style = {},
  fill = '#fff',
  fillOpacity = '0.5  ',
  fillNone = 'none',
  width = '15.167',
  className = '',
  viewBox = '0 0 15.167 8.667'
}) => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height="8.667" viewBox={viewBox}>
      <path id="pre" d="M1.849,8.349A1.083,1.083,0,0,1,.317,6.817l6.5-6.5a1.083,1.083,0,0,1,1.5-.033l6.5,5.958a1.083,1.083,0,1,1-1.464,1.6L7.616,2.583Z" transform="translate(15.167 8.667) rotate(180)" fill={fill} />
    </svg>
  </>
);
export default LeftIcon;