import React from 'react';
const MinusIcon = ({
	style = {},
	fill = '#fff',
	fillOpacity = '0.5',
	fillNone = 'none',
	width = '16',
	className = '',
	viewBox = '0 0 16 16'
}) => (
	<>
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
			<rect fill={fillNone} width="16" height="16" />
			<path fill={fill} d="M2,8c0-0.3,0.2-0.5,0.5-0.5h11C13.8,7.5,14,7.7,14,8 c0,0.3-0.2,0.5-0.5,0.5h-11C2.2,8.5,2,8.3,2,8z" />
		</svg>
	</>
);
export default MinusIcon;