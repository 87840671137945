import React from 'react';
const CoinIcon = ({
	style = {},
	fill = '#FBE942',
	fill2 = '#FEF948',
	fill3 = '#FCC641',
	fill4 = '#EEAD1B',
	fill5 = '#01B4D7',
	fill6 = '#FEF848',
	fill7 = '#FBE841',
	fill8 = '#FEF747',
	fillOpacity = '0.5',
	fillNone = 'none',
	width = '30',
	className = 'sp-coinIcon',
	viewBox = '0 0 30 30'
}) => (
	<>
		<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={width} height={width} viewBox={viewBox} className={className}>
			<g transform="translate(0.007)">
				<g transform="translate(-0.007)">
					<g transform="translate(0)">
						<path fill={fill} d="M28.8,20.7c-0.6,1.6-1.5,3-2.6,4.2c-2.2,2.6-5.3,4.3-8.6,4.8c-5.3,1-10.6-1-14-5.1
				c-1.6-1.8-2.7-4-3.2-6.3c-0.1-1-0.2-2-0.4-3c0-1.7,0.2-3.5,0.8-5.2l1.6-3.3c0.8-1,1.6-2,2.5-2.9C5.4,3.5,6,3,6.6,2.5h0l0.1-0.1
				l1.4-0.7C8.1,1.9,8,2.1,8,2.3C7.7,3.4,7.5,4.5,7.2,5.6c0,0,0,0.1,0,0.1C6.1,6.6,5.1,7.8,4.4,9.1c-1.4,2.4-1.8,5.2-1.3,7.9
				c0.5,3.3,2.4,6.2,5.2,8c2.5,1.8,5.7,2.5,8.8,1.9c3.3-0.5,6.2-2.3,8-5.1l0.2-0.2c0.1,0,0.3,0.1,0.4,0c1-0.3,1.9-0.7,2.9-1
				C28.5,20.7,28.7,20.7,28.8,20.7L28.8,20.7z"/>
						<path fill={fill2} d="M28.8,20.7c-0.1,0-0.3,0-0.4,0c-1,0.3-1.9,0.7-2.9,1c-0.1,0-0.2,0-0.4,0c0-0.1,0.1-0.2,0.1-0.2
				c1.1-1.7,1.7-3.7,1.8-5.7c0.1-1.7-0.2-3.4-0.8-5c-0.8-2-2.1-3.8-3.8-5.2c-2.5-2-5.6-2.9-8.8-2.6c-1.4,0.1-2.9,0.5-4.1,1.2
				C8.8,4.6,8,5.1,7.2,5.6C7.5,4.5,7.7,3.4,8,2.3c0-0.2,0.1-0.4,0.2-0.6l0.4-0.3c0.1,0,0.1,0,0.2,0c1.8-0.8,3.8-1.3,5.8-1.4l3,0.2
				c1.3,0.3,2.5,0.7,3.8,1.2c4.5,2,7.7,6,8.5,10.8c0.6,2.8,0.3,5.8-0.8,8.4C28.9,20.6,28.9,20.7,28.8,20.7z"/>
						<path fill={fill3} d="M7.2,5.6C8,5.1,8.8,4.6,9.6,4.1c1.3-0.7,2.7-1.1,4.2-1.2c3.1-0.3,6.3,0.6,8.8,2.6
				c3.1,2.5,4.8,6.3,4.6,10.2c-0.1,2-0.8,4-1.8,5.7c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c-1.5,0.3-3,0.3-4.6,0.2
				c-0.4-0.1-0.9-0.2-1.3-0.3c0.2-0.1,0.4-0.3,0.5-0.4c0.3-0.2,0.5-0.7,0.2-1c0,0,0,0,0,0c-0.2-0.3-0.6-0.4-1-0.2c0,0,0,0,0,0
				c-0.6,0.4-1.1,0.7-1.6,1.1l-0.2,0.1c-0.1-0.1-0.3-0.2-0.4-0.2c-1-0.6-2.1-1.1-3.1-1.7c-0.5-0.3-1-0.7-1.5-1.1c0,0,0-0.1,0-0.1
				c0-0.2,0.1-0.3,0.1-0.5c0-1.8,0-3.6,0-5.4c0-0.6-0.2-0.9-0.7-0.9s-0.7,0.3-0.7,0.9c0,0.8,0,1.6,0,2.3c-0.5-0.8-0.9-1.7-1.4-2.5
				c-0.4-0.8-0.8-0.9-1.5-0.3C7.2,9.8,7,7.8,7.2,5.6L7.2,5.6L7.2,5.6z M13.6,15c0,0.9,0,1.8,0,2.8c0,0.5,0.3,0.8,0.7,0.8
				c0.5,0,0.7-0.3,0.7-0.8s0-1,0-1.6c0-0.4,0.1-0.5,0.5-0.5c0.3,0,0.7,0,1,0c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0
				c0-0.4-0.3-0.7-0.6-0.7c-0.1,0-0.3,0-0.4,0c-1.1,0-1.1,0-1.1-1.1c0-0.3,0.1-0.4,0.4-0.3c0.5,0,1.1,0,1.6,0c0.5,0,0.8-0.3,0.8-0.7
				s-0.3-0.7-0.8-0.7c-0.9,0-1.8,0-2.6,0c-0.6,0-0.8,0.3-0.8,0.9C13.6,13.2,13.6,14.1,13.6,15z M20.1,9.3C20,9,19.8,8.8,19.6,8.6
				c-1.3-0.9-2.7-1.8-4.1-2.7c-0.3-0.3-0.8-0.3-1.1,0c-1.3,0.9-2.7,1.8-4,2.7C10,8.9,9.9,9.3,10.1,9.7c0.2,0.3,0.7,0.4,1,0.2
				c0,0,0.1,0,0.1-0.1c1-0.6,1.9-1.3,2.9-1.9c0.5-0.4,1.2-0.4,1.8,0c1,0.6,1.9,1.3,2.9,1.9c0.3,0.1,0.5,0.1,0.8,0.1
				C19.8,9.8,19.9,9.5,20.1,9.3L20.1,9.3z M22.1,12.9c0.3,0,0.5,0,0.7,0c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0c0-0.4-0.3-0.7-0.6-0.7
				c0,0,0,0-0.1,0c-1,0-1.9,0-2.9,0c-0.4,0-0.7,0.3-0.7,0.7c0,0,0,0,0,0.1c0,0.4,0.3,0.7,0.7,0.7c0,0,0,0,0,0c0.2,0,0.5,0,0.7,0v0.6
				c0,1.5,0,2.9,0,4.4c0,0.4,0.2,0.7,0.6,0.8s0.7-0.2,0.8-0.6c0-0.1,0-0.1,0-0.2c0-1,0-1.9,0-2.9L22.1,12.9L22.1,12.9z M23.6,15
				c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7C23.9,14.3,23.6,14.6,23.6,15C23.6,15,23.6,15,23.6,15z"/>
						<path fill={fill4} d="M19.1,21.8c0.4,0.1,0.9,0.2,1.3,0.3c1.5,0.2,3.1,0.1,4.6-0.2c-1.8,2.7-4.8,4.6-8,5.1
				C10.4,28,4.1,23.6,3,17c-0.4-2.7,0-5.5,1.4-7.8c0.7-1.3,1.7-2.5,2.9-3.4c-0.2,2,0,4.1,0.6,6.1c0,0.1,0,0.2,0,0.3
				c0,1.8,0,3.7,0,5.5c0,0.6,0.2,0.9,0.7,0.9s0.7-0.3,0.7-0.9v-2.3c0.5,0.9,1,1.7,1.4,2.6c0.4,0.7,0.7,0.8,1.4,0.3
				c0.5,0.4,1,0.8,1.5,1.1c1,0.6,2.1,1.1,3.1,1.7c0.1,0.1,0.3,0.2,0.4,0.2L15,22.7c-0.1-0.1-0.3-0.2-0.4-0.3
				c-1.1-0.8-2.3-1.5-3.4-2.3c-0.3-0.2-0.8-0.2-1,0.1c0,0,0,0-0.1,0.1c-0.2,0.3-0.1,0.8,0.2,1c0,0,0,0,0,0c1.4,0.9,2.8,1.9,4.2,2.8
				c0.3,0.1,0.6,0.1,0.8,0c1.2-0.8,2.4-1.6,3.5-2.4L19.1,21.8L19.1,21.8z M5,15c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7
				c0-0.4-0.3-0.7-0.7-0.7c0,0,0,0,0,0C5.4,14.3,5,14.6,5,15C5,14.9,5,15,5,15z"/>
						<path fill={fill5} d="M6.6,2.5L6.6,2.5L6.6,2.5z" />
						<path fill={fill2} d="M13.6,15c0-0.9,0-1.8,0-2.7c0-0.6,0.2-0.8,0.8-0.9c0.9,0,1.8,0,2.6,0c0.5,0,0.8,0.3,0.8,0.7
				s-0.3,0.7-0.8,0.7c-0.5,0-1.1,0-1.6,0c-0.3,0-0.4,0.1-0.4,0.3c0,1.1,0,1.1,1.1,1.1c0.1,0,0.3,0,0.4,0c0.4,0,0.7,0.3,0.6,0.7
				c0,0.4-0.3,0.7-0.7,0.7c0,0,0,0,0,0c-0.3,0-0.7,0-1,0c-0.4,0-0.5,0.1-0.5,0.5c0,0.5,0,1,0,1.6s-0.3,0.8-0.7,0.8
				c-0.4,0-0.7-0.3-0.7-0.8C13.6,16.8,13.6,15.9,13.6,15z"/>
						<path fill={fill6} d="M20.1,9.3c-0.2,0.3-0.3,0.5-0.5,0.6c-0.3,0.1-0.6,0.1-0.8-0.1c-1-0.6-2-1.3-2.9-1.9
				c-0.5-0.4-1.2-0.4-1.8,0c-1,0.6-1.9,1.3-2.9,1.9c-0.3,0.3-0.8,0.3-1,0c0,0,0-0.1-0.1-0.1C9.9,9.3,10,8.9,10.4,8.6
				c1.3-0.9,2.7-1.8,4-2.7c0.3-0.3,0.8-0.3,1.1,0c1.3,0.9,2.7,1.8,4.1,2.7C19.8,8.8,20,9,20.1,9.3L20.1,9.3z"/>
						<path fill={fill2} d="M7.9,12.1c0-0.1,0-0.2,0-0.3c0.7-0.6,1.1-0.5,1.5,0.3c0.4,0.9,0.9,1.7,1.4,2.5c0-0.8,0-1.6,0-2.3
				c0-0.6,0.3-0.9,0.7-0.9s0.7,0.3,0.7,0.9c0,1.8,0,3.6,0,5.4c0,0.2,0,0.3-0.1,0.5c-0.5-0.5-1-1-1.4-1.5c-0.9-1-1.6-2.2-2.2-3.4
				C8.3,12.9,8.1,12.5,7.9,12.1L7.9,12.1z"/>
						<path fill={fill7} d="M18.9,21.8c-1.2,0.8-2.3,1.6-3.5,2.4c-0.3,0.1-0.6,0.1-0.8,0c-1.4-0.9-2.8-1.8-4.2-2.8
				c-0.3-0.2-0.4-0.7-0.2-1c0,0,0,0,0,0c0.2-0.3,0.7-0.4,1-0.2c0,0,0,0,0.1,0.1c1.1,0.7,2.3,1.5,3.4,2.3c0.1,0.1,0.3,0.2,0.4,0.3
				l2.1-1.3l0.2-0.1L18.9,21.8z"/>
						<path fill={fill2} d="M22.1,12.9v2c0,1,0,1.9,0,2.9c0,0.4-0.2,0.7-0.6,0.8s-0.7-0.2-0.8-0.6c0-0.1,0-0.1,0-0.2
				c0-1.5,0-2.9,0-4.4v-0.6c-0.3,0-0.5,0-0.7,0c-0.4,0-0.7-0.3-0.7-0.7c0,0,0,0,0,0c0-0.4,0.3-0.7,0.6-0.7c0,0,0,0,0.1,0
				c1,0,1.9,0,2.9,0c0.4,0,0.7,0.3,0.7,0.7c0,0,0,0,0,0.1c0,0.4-0.3,0.7-0.7,0.7c0,0,0,0,0,0C22.7,12.9,22.4,12.9,22.1,12.9
				L22.1,12.9z"/>
						<path fill={fill} d="M7.9,12.1c0.2,0.4,0.4,0.8,0.6,1.2c0.5,1.2,1.3,2.4,2.2,3.4c0.4,0.5,0.9,1,1.4,1.5
				c0,0,0,0.1,0,0.1c-0.6,0.5-1,0.4-1.4-0.3c-0.4-0.9-0.9-1.7-1.4-2.6v2.3c0,0.6-0.2,0.9-0.7,0.9s-0.7-0.3-0.7-0.9
				C7.9,15.8,7.9,14,7.9,12.1L7.9,12.1z"/>
						<path fill={fill6} d="M18.9,21.8l-1.7-0.5c0.5-0.4,1.1-0.8,1.6-1.1c0.3-0.2,0.8-0.2,1,0.2c0,0,0,0,0,0
				c0.2,0.3,0.1,0.8-0.2,1c0,0,0,0,0,0c-0.2,0.1-0.4,0.3-0.5,0.4L18.9,21.8L18.9,21.8z"/>
						<path fill={fill8} d="M23.6,15c0-0.4,0.3-0.7,0.7-0.7S25,14.6,25,15s-0.3,0.7-0.7,0.7c0,0,0,0,0,0
				C23.9,15.7,23.6,15.4,23.6,15z"/>
						<path fill={fill} d="M5,15c0-0.4,0.4-0.7,0.7-0.7c0.4,0,0.7,0.4,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7C5.3,15.7,5,15.4,5,15
				C5,15,5,15,5,15z"/>
					</g>
				</g>
			</g>
		</svg>

	</>
);
export default CoinIcon;