import React from 'react';
const HomeIcon = ({
  style = {},
  fill = '#f5c02f',
  fillOpacity = '1',
  fillNone = 'none',
  width = '35',
  className = '',
  viewBox = '0 0 35 35'
}) => (
  <>
    <svg id="dashboard" xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox={viewBox}>
      <rect id="Rectangle_115" data-name="Rectangle 115" width={width} height={width} fill={fillNone} />
      <path id="house-door-fill" d="M10.346,24.885v-6.2a.95.95,0,0,1,.885-.876h3.538a.951.951,0,0,1,.885.885v6.192a.885.885,0,0,0,.885.885h7.077a.885.885,0,0,0,.885-.885V12.5a.885.885,0,0,0-.258-.626l-2.4-2.394V3.655a.885.885,0,0,0-.885-.885H19.192a.885.885,0,0,0-.885.885V5.942L13.626,1.259a.885.885,0,0,0-1.253,0L1.758,11.874A.885.885,0,0,0,1.5,12.5V24.885a.885.885,0,0,0,.885.885H9.462A.885.885,0,0,0,10.346,24.885Z" transform="translate(4.5 5.001)" fill={fill} />
    </svg>

  </>
);
export default HomeIcon;